.container {
  margin: 0 3px;
  width: 150px;
}

@media screen and (--max-width-sm) {
  .container {
    width: 200px;
  }
}
