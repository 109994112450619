.container {
  display: flex;
  align-items: center;
  gap: var(--spacing1000);
  padding: var(--spacing1000);
  background: var(--gray300);
  & .left,
  & .right {
    flex: 1;
  }

  & .right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: var(--spacing1000);
    & .field {
      white-space: nowrap;
      &:nth-child(1) {
        width: 160px;
      }
      &:nth-child(2) {
        min-width: 56px;
      }
      &:nth-child(3) {
        min-width: 160px;
      }
      & .label,
      & .value {
        overflow: hidden;
        text-overflow: ellipsis;
      }
      & .label {
        margin-bottom: var(--spacing0500);
      }
    }
  }
}

.amount {
  text-align: right;
}

@media screen and (--max-width-md) {
  .container {
    flex-direction: column;
    align-items: stretch;
    & .right {
      & .field {
        flex: 1;
        &:nth-child(1) {
          width: unset;
        }
        &:nth-child(2) {
          display: none;
        }
      }
    }
  }
}

@media screen and (--max-width-sm) {
  .container {
    & .right {
      flex-direction: column;
      align-items: flex-end;
      & .field {
        text-align: right;
        &:nth-child(1),
        &:nth-child(2) {
          display: none;
        }
      }
    }
  }
}
