.editUnit {
  display: flex;
  align-items: flex-end;
  gap: var(--spacing1000);
}

.unitField {
  margin-bottom: 0;
  min-width: 240px;
}
