.emptyPageContent {
  display: flex;
  flex-direction: column;
}

.pageHeader {
  box-shadow: none;
  border-bottom: 1px solid var(--gray300);
}

.pageTab {
  background: var(--white);
}

.pagePrecontent {
  background: var(--white);
  box-shadow: var(--shadow1);
}

.contextInfo {
  flex-grow: 1;
  display: flex;
  gap: var(--spacing1000);

  @media screen and (--max-width-md) {
    align-items: flex-start;
  }

  @media screen and (--max-width-sm) {
    width: 100%;

    & > * {
      flex-basis: 50%;
    }
  }
}

.timelineWrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  @media screen and (--max-width-lg) {
    position: static;
    transform: none;
  }

  @media screen and (--max-width-md) {
    min-width: 240px;
  }

  @media screen and (--max-width-sm) {
    min-width: 160px;
  }
}
