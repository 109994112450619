.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing1000);
}

.insertionForm {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: var(--spacing1000);
}
.recipientName {
  flex-grow: 1;
}
.addBtn {
  margin-top: var(--spacing1500);
}
