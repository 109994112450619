.dropzone {
  height: auto;
  min-height: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing1000);
  border: 1px dashed var(--gray600);
  box-sizing: border-box;
  position: relative;
  background: var(--gray300);
}

.legend {
  color: var(--ink600);
}

.error {
  color: var(--red500);
}

.fileUploaded {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.spacing {
  margin-bottom: var(--spacing1000);
}
