:root {
  --shadow1: 0 1px 4px 1px rgba(0, 0, 0, 0.1);

  --shadow2: 0 2px 6px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.12);

  --shadow3: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 4px 8px 0 rgba(0, 0, 0, 0.08),
    0 1px 8px 0 rgba(0, 0, 0, 0.04);

  --shadow4: 0 3px 6px 0 rgba(0, 0, 0, 0.1), 0 6px 12px 0 rgba(0, 0, 0, 0.08),
    0 1px 12px 0 rgba(0, 0, 0, 0.04);

  --shadow5: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 6px 12px 0 rgba(0, 0, 0, 0.12),
    0 1px 16px 0 rgba(0, 0, 0, 0.04);

  --shadow6: 0 6px 12px 0 rgba(0, 0, 0, 0.12), 0 8px 16px 0 rgba(0, 0, 0, 0.12),
    0 1px 24px 0 rgba(0, 0, 0, 0.12);

  --shadow7: 0 8px 16px 0 rgba(0, 0, 0, 0.12), 0 12px 24px 0 rgba(0, 0, 0, 0.12),
    0 1px 32px 0 rgba(0, 0, 0, 0.12);
}
