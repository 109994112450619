.container {
  margin-top: 0;
  display: flex;
  flex-direction: column;
  gap: var(--spacing1000);
}

.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing0750);
  margin-top: var(--spacing0500);

  @media screen and (--max-width-sm) {
    flex-direction: column;
    gap: 1rem;

    & > * {
      width: 100%;
      max-width: 400px;
      margin: 0 auto;
    }
  }
}
