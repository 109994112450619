.pageContent {
  display: flex;
  flex-direction: column;
}

.reinviteBtnWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: var(--spacing1000);
}

.tableSmallLabel {
  font-size: 12px;
}

.formattedContent {
  white-space: pre-line;
}

.fullwidth {
  width: 100%;
}

.rightSide {
  text-align: right;
}

.displayInline {
  display: inline-flex;
}

.spacing {
  margin-bottom: var(--spacing1000);
}
