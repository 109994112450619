.countrySelector {
  width: 90px;
  transition: all 300ms;
}

.valueWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.option {
  display: flex;
  align-items: center;
  & .flag {
    display: flex;
    align-items: center;
    margin-right: var(--spacing0500);
  }
}
