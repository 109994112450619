.container {
  display: flex;
  align-items: center;
  gap: var(--spacing0500);
  margin-bottom: var(--spacing1000);
  flex-wrap: wrap;
}

.field {
  display: flex;
  align-items: center;
  flex: 1 0;
}

.toggle {
  margin-right: var(--spacing0500);
}

.syncBtn {
  width: 50%;
}

@media screen and (--max-width-sm) {
  .syncBtn {
    width: 100%;
  }
}
