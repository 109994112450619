.pageContent {
  display: flex;
  flex-direction: column;
}

.receiptsWrapper {
  margin-top: 1rem;
}

@media screen and (--max-width-sm) {
  .actions {
    margin: -1rem -1rem 0;
  }
}
