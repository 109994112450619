.halfField {
  width: 60%;
}

.cardFieldContainer {
  position: relative;
}

@media screen and (--max-width-sm) {
  .halfField {
    width: 100%;
  }
}
