.counter {
  margin: var(--spacing1000) 0;
}

.billingAmount {
  font-weight: 700;
}

.validityWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing0250);
}

.detailViewWrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
