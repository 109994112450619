.sideContent {
  width: 260px;
  text-align: left;
  margin: 0 var(--spacing3500);

  & .desc,
  & .subTitle,
  & .title {
    padding-top: 1.8rem;
  }
  & .subTitle {
    font-weight: 600;
  }
  & .desc {
    font-weight: 500;
  }
}
.getStartedAction {
  display: flex;
  justify-content: flex-end;
  padding-top: 50px;
}

.form {
  flex: 2;
  overflow-y: auto;
  width: 600px;
}

.formChoice {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0;
}

.compactFormGroup {
  margin: 0 !important;
  margin-bottom: 0.5em !important;
}

.formMain {
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 1;
  padding: var(--spacing0250);
}

.formHeader {
  width: 100%;
}

.formHeader > h3 {
  font-size: 1rem;
}

.formHeader h4 {
  font-size: 1rem;
}

.formScrollable {
  flex: 1;
  overflow-y: auto;
}

.subHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  margin-bottom: 1em;
  padding: var(--spacing0250);
}

.subHeaderActions {
  display: flex;
  flex-direction: row;
}

.subContainer {
  background-color: var(--gray100);
  padding: 1em;
  overflow-y: auto;
  overflow-x: hidden;
}

.ownerBlock {
  margin: 1em 0;
  height: 70px;
  min-height: 70px;
}

.empty {
  border-color: var(--gray500);
}

.ownerBlock.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-style: dashed;
}

.featureList {
  list-style: none;
  padding-left: 30px;
}

.featureList li {
  position: relative;
  padding: 0.3em;
  font-size: 1rem;
}

.featureList li::before {
  content: '\2022';
  color: var(--primaryBg);
  font-weight: bold;
  font-size: 2.9rem;
  display: block;
  width: 4em;
  top: -16px;
  left: -18px;
  position: absolute;
}

.loader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loader svg {
  fill: var(--primaryBg);
}

.birthDateContainer {
  width: 290px;
  background-color: var(--white);
}

@media screen and (--max-width-sm) {
  .continueBtn,
  .btnStart {
    bottom: 0;
    width: 100%;
  }

  .backBtn {
    bottom: 45px;
    width: 100%;
  }

  .formFooter {
    padding-left: 2em;
    padding-right: 2em;
  }

  .formFooter {
    flex-direction: column-reverse;
    margin-bottom: 2em;
  }

  .btnStart {
    margin-left: 0 !important;
  }

  .featureList li {
    font-size: 1rem;
  }
  .featureList li::before {
    font-size: 2rem;
    display: block;
    width: 4em;
    top: -7px;
    left: -12px;
    position: absolute;
  }

  .formMain,
  .formHeader {
    width: calc(100% - 2rem);
    padding: 1rem;
  }

  .footer {
    padding: unset;
    height: unset;
  }

  .form {
    padding: unset;
  }

  .formHeader > h3,
  .formMain > h3,
  .formHeader > h4 {
    font-size: 1rem;
  }
}
@media (--max-width-md) {
  .form {
    width: unset;
  }
}
