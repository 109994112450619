.tag {
  display: inline-block;
  border-radius: 2px;
  padding: 4px 8px;
  font-weight: 600;
  color: var(--white);
}

.tagContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  text-align: center;
}

.tag i {
  margin-right: 4px;
}

.default {
  background: var(--gray700);
  &.revert {
    background: var(--gray300);
    color: var(--gray700);
  }
}

.active {
  background: var(--brightBlue500);
  &.revert {
    background: var(--brightBlue100);
    color: var(--brightBlue500);
  }
}

.success {
  background: var(--progressGreen);
  &.revert {
    background: var(--progressGreenBg);
    color: var(--progressGreen);
  }
}

.error {
  background: var(--blockingRed);
  &.revert {
    background: var(--blockingRedBg);
    color: var(--blockingRed);
  }
}

.warn {
  background: var(--alertOrange);
  &.revert {
    background: var(--alertOrangeBg);
    color: var(--alertOrangeDark);
  }
}

.default,
.active,
.success,
.error,
.warn {
  &.whiteBg {
    background: var(--white);
  }
}
