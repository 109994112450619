.container {
  border: none;
  outline: none;
  padding: 0 10px;
  height: 36px;
  width: 100%;
  box-sizing: border-box;
  background: none;
  &.alignRight {
    text-align: right;
  }
  &:focus {
    border: none;
    outline: none;
  }
}
.flashing {
  background-color: var(--blockingRedBg);
}
