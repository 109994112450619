.progressCircle {
  position: relative;
  border-radius: 50%;
  height: 50px;
  width: 50px;

  & .firstHalf,
  & .secondHalf {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    display: block;
    height: 100%;
    width: 100%;
  }

  & .center {
    background-color: var(--white);
    border-radius: 50%;
    display: block;
    position: absolute;
    left: var(--spacing0500);
    right: var(--spacing0500);
    top: var(--spacing0500);
    bottom: var(--spacing0500);
    transition: transform 0.2s ease;
  }

  & .counter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
