.hiddenLink {
  visibility: hidden;
}

.skipToContentButton {
  position: absolute;
  z-index: 5;
  left: 50%;
  transform: translate(-50%, -120%);
  transition: transform 0.1875s ease-out;
}

.skipToContentButton:focus {
  transform: translate(-50%, var(--spacing1500));
}
