.tableWrapper {
  margin: var(--spacing1500) 0;

  & .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--spacing0750);
    width: 100%;
    margin-bottom: var(--spacing0500);
  }
}
