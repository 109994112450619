.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--gray900);
  gap: var(--spacing1000);
  height: var(--app-nav-size);
  padding: 0 var(--spacing1000);
}

.logoWrapper {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.profileAvatarWrapper {
  display: flex;
  align-items: stretch;
  justify-content: center;
}
