.label {
  margin-bottom: var(--spacing0250);
}

.inputWrapper {
  position: relative;
  border-radius: 4px;
  outline-width: 1px;
  outline-style: solid;
  outline-color: var(--gray500);
  transition: outline-color 200ms;
  box-sizing: border-box;
  &:hover,
  &:focus,
  &:focus-within {
    outline-color: var(--primaryText);
  }
  &:focus,
  &:focus-within {
    outline-width: 2px;
  }
}

.success {
  & > .inputWrapper {
    outline-color: var(--progressGreen);
  }
}

.invalid {
  & > .inputWrapper {
    outline-color: var(--blockingRed);
  }
}

.noBorder {
  & > .inputWrapper {
    outline-width: 0;
  }
}

.disabled {
  & > .inputWrapper {
    pointer-events: none;
  }
}

:not(.noBorder) {
  & > .inputWrapper {
    background: var(--white);
  }
  &.disabled {
    & > .inputWrapper {
      color: var(--ink700);
      background-color: var(--gray300);
      outline-color: var(--gray500);
      cursor: not-allowed;
      & * {
        cursor: not-allowed;
      }
    }
  }
}

.msg {
  display: flex;
  align-items: center;
  margin-top: var(--spacing0250);
  color: var(--ink500);
  & .msgIcon {
    font-size: var(--iconXS);
    margin-right: var(--spacing0500);
  }
  &.error {
    color: var(--blockingRed);
  }
  &.success {
    color: var(--progressGreen);
  }
  &.placeholder:before {
    content: '\00a0'; /* space character */
  }
}

.readOnlyValue {
  padding: var(--spacing0500) 0;
  &.rightAlign {
    text-align: right;
  }
}
