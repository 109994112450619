.container {
}

.title {
  color: var(--ink500);
  font-weight: 800;
  font-size: 11px;
  padding: 0;
}

.value {
  padding: 0.5rem 0;
}
