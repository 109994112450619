.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 1em;
}

.mapContainer {
  composes: container;
  overflow: hidden !important;
  min-height: 500px;
  display: flex;
}
