.container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: var(--spacing0500);
}

.radio {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  margin: 0;
  border: none;
  position: relative;
  flex-shrink: 0;
  border: 1px solid var(--gray600);
  background-color: var(--white);
  transition-property: border-color, background-color;
  transition-duration: 250ms;
  transition-timing-function: ease-out;
  cursor: pointer;

  &:hover {
    border-color: var(--brightBlue500);
  }

  &:checked {
    border-color: var(--brightBlue500);
    background: var(--brightBlue500);

    &:hover {
      border-color: var(--brightBlue600);
      background: var(--brightBlue600);
    }

    &:after {
      content: '';
      width: 10px;
      height: 10px;
      background-color: var(--white);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 100%;
    }
  }

  &.isInvalid {
    border-color: var(--blockingRed);
    &:hover {
      border-color: var(--red600);
    }
    &:checked {
      background-color: var(--blockingRed);
      &:hover {
        border-color: var(--red600);
        background-color: var(--red600);
      }
    }
  }

  &:disabled {
    border-color: var(--gray400);
    cursor: auto;

    &:hover {
      border-color: var(--gray400);
    }

    &:checked {
      background: var(--gray400);
    }
  }

  &:focus {
    outline: 1px solid var(--brightBlue500);
    outline-offset: 1px;
  }
}

.disabledLabel {
  color: var(--ink400);
}
