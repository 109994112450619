.container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 9999px;
  background: var(--gray500);
  padding: 0 var(--spacing0250);
  color: var(--ink600);
  min-width: 8px;
  height: 16px;

  &.alert {
    background: var(--blockingRed);
    color: var(--darkInk100);
  }
}
