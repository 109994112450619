.row {
  display: flex;
  padding: 12px;
  gap: 12px;
}

.dark {
  background: #333333;
}

.hidden {
  visibility: hidden;
}
