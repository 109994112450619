.datePicker {
  width: 100%;
  height: 36px;

  &:global(.react-date-picker--disabled) {
    background: transparent;
    color: unset;
    pointer-events: none;
  }

  & :global(.react-date-picker__button) {
    display: flex;
    align-items: center;
    padding: 0 var(--spacing0750);
    font-size: var(--iconXS);

    &:global(.react-date-picker__calendar-button) {
      margin: var(--spacing0500) 0;
      border-left: 1px solid var(--gray500);
    }

    & i[role='presentation'] {
      border-radius: 4px;
      color: var(--ink400);
      transition: color 250ms ease-out;
    }

    &:hover i[role='presentation'] {
      color: var(--ink600);
    }

    &:focus i[role='presentation'] {
      outline: 2px solid var(--primaryBg);
      outline-offset: 2px;
    }
  }

  & :global(.react-date-picker__calendar) {
    z-index: 1;
  }

  & :global(.react-date-picker__wrapper) {
    border: none;
    padding-left: 10px;
  }

  & :global(.react-date-picker__inputGroup) {
    display: flex;
    align-items: center;
  }

  & :global(.react-date-picker__inputGroup__divider),
  & :global(.react-date-picker__inputGroup__input::placeholder) {
    color: var(--ink400);
  }

  & :global(.react-date-picker__inputGroup__leadingZero),
  & :global(.react-date-picker__inputGroup__input) {
    color: var(--ink600);
  }

  & :global(.react-date-picker__inputGroup__leadingZero) {
    padding-left: 3px;
    color: inherit;
  }

  & :global(.react-date-picker__inputGroup__input) {
    height: auto;
    padding: 5px 3px;
    color: inherit;
  }

  & :global(.react-date-picker__inputGroup__input--hasLeadingZero) {
    margin-left: -0.54em;
    padding-left: calc(1px + 0.54em);
  }

  & *:focus-visible {
    outline: none;
  }
}
