:root {
  --app-nav-size: 70px;
}

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: var(--gray200);
  overflow: hidden;
}

.main {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  /* Safari workaround to prevent transformed children of overflowing */
  transform: translate(0, 0);
}

.navBar {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  background-color: var(--gray900);
  z-index: 3;
  flex-shrink: 0;
  width: var(--app-nav-size);
}

.logoWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--app-nav-size);
}

.browserNotSupportedBanner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
}

.content {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
}

.pendingBanner {
  bottom: 0;
  &.isHidden {
    display: none;
  }
}

.menuBackground {
  background: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 3;
  visibility: hidden;
  transition: background 300ms ease, visibility 300ms ease 300ms;

  &.isVisible {
    visibility: visible;
    transition: background 300ms ease, visibility 300ms ease;

    @media screen and (--max-width-md) {
      background: var(--black050);
    }
  }
}

.leftPane,
.rightPane {
  display: block;
  width: 240px;
  z-index: 3;
  overflow-y: auto;
  position: absolute;
  background-color: var(--white);
  box-shadow: var(--shadow5);

  @media screen and (--max-width-md) {
    position: absolute;
    bottom: 0;
    width: 300px;
    color: var(--darkInk200);
    background-color: var(--gray900);
  }
}

.leftPane {
  top: 0;
  left: 0;
  visibility: hidden;
  transform: translateX(-100%);
  transition: transform 300ms ease, visibility 0s ease 300ms;

  &.isVisible {
    visibility: visible;
    transform: translateX(0);
    transition: transform 300ms ease, visibility 0s ease;
  }
}

.rightPane {
  top: 62px; /* Display it right below the profile avatar button */
  right: var(--spacing1000);
  visibility: hidden;
  transform: translateX(calc(100% + var(--spacing1000)));

  @media screen and (--min-width-md) and (max-height: 620px) {
    bottom: 80px;
  }

  @media screen and (--max-width-md) {
    top: var(--app-nav-size); /* Display it right below the header */
    right: 0;
    transform: translateX(100%);
    transition: transform 300ms ease, visibility 0s ease 300ms;
  }

  &.isVisible {
    visibility: visible;
    transform: translateX(0);

    @media screen and (--max-width-md) {
      transition: transform 300ms ease, visibility 0s ease;
    }
  }
}

@media screen and (--max-width-md) {
  :root {
    --app-nav-size: 64px;
  }
  .main {
    flex-direction: column;
  }
}
