.tabsContainer {
  height: 100%;
  overflow: hidden;
}

.tabsContent {
  overflow: auto;
  padding: 0 !important;
}

.empty {
  padding: 2em;
  color: var(--ink400);
}

.listWrapper {
  color: var(--ink400);
  & .linkedTxsList {
    display: table;
    width: 100%;

    & .linkedTx {
      display: table-row;
      &:nth-child(odd) {
        background: var(--white);
      }
      &:nth-child(even) {
        background: var(--gray100);
      }

      & .cell {
        display: table-cell;
        vertical-align: middle;
        padding-top: 1em;
        padding-bottom: 1em;
        padding-left: 1em;
      }

      & .date {
        width: 5rem;
        min-width: 5rem;
        padding-left: 0;
      }

      & .merchant {
        padding-left: 0;
      }

      & .amount {
        padding-right: 2em;
      }
    }
  }
}

.merchantName {
  font-weight: bold;
}

.accountName {
  font-size: 12px;
}

.owner {
  font-weight: bold;
}
