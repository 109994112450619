.header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  background-color: var(--gray100);
  width: 100%;
  height: 100%;
}

.headerContent {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.distributionLine {
  padding: var(--spacing1000);
  padding-left: var(--spacing2000);
}

.distributionLineInput {
  font-weight: bold;
}

.arrowContainer {
  background-color: var(--brightBlue100);
  width: 35px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
}

.arrow {
  width: 0;
}

.arrowUp {
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left-style: solid;
  border-left-width: 8px;
  border-left-color: var(--primaryText);
}

.arrowDown {
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top-style: solid;
  border-top-width: 8px;
  border-top-color: var(--primaryText);
}

.amount {
  margin-right: 1rem;
  display: flex;
  align-items: center;
  gap: var(--spacing1000);
}

.mileageWaypointList {
  display: flex;
  flex-direction: column;
  padding: var(--spacing1000);
  padding-left: var(--spacing2000);
  gap: var(--spacing1000);
}

.mileageWaypoint {
  display: flex;
  align-items: center;
  gap: var(--spacing0250);
}

.mileageWaypointIcon {
  color: var(--gray600);
}

.mileageDistance {
  align-items: center;
  background: var(--brightBlue100);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: var(--spacing1000);
  padding-left: var(--spacing2000);
}

.line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--spacing1000);
}
