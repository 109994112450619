.container {
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus {
    outline: 2px solid var(--white);
    outline-offset: 2px;
    border-radius: 4px;
  }
}

.logo {
  &.sm {
    width: 48px;
  }
  &.md {
    width: 56px;
  }
}
