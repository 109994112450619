.formContainer {
  max-width: 500px;
}

.formContainerLoading {
  min-height: 491px;
}

.formTitle,
.formDescription {
  color: var(--ink500);
}

.formTitle {
  margin-bottom: 1rem;
}

.formDescription {
  margin: 1rem 0 2rem;
}

.formLoading {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.formGroup {
  align-items: center;
  width: 60%;
}

.formLoadingIcon {
  font-size: 3rem;
}

.successIcon {
  color: var(--progressGreen);
}

.loadingIcon:before {
  color: var(--ink500);
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
