.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
  background: var(--gray200);
}

.item {
  margin: 1rem;
  padding: 0;
  list-style: none;
  border-radius: 2px;
  width: 250px;
  cursor: pointer;
  position: relative;
  & .flipInner {
    border: 5px solid var(--white);
    transition: border linear 80ms, transform 500ms ease-in-out;
  }
  &:hover {
    & .flipInner {
      border-color: var(--primaryBgLight);
    }
  }
  &.selected {
    & .flipInner {
      border-color: var(--primaryBg);
    }
  }
}
.itemHeader {
  display: flex;
  align-items: center;
}
.icon {
  margin-left: 1em;
}

.title {
  margin: 1em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.imageContainer {
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  width: 100%;
  height: 230px;
  text-align: center;
}

.image {
  max-width: 100%;
  max-height: 100%;
}
.attached {
  background-color: var(--primaryBg);
  padding: 5px;
  color: var(--primaryText);
  bottom: 10px;
  left: 10px;
  justify-content: center;
  text-align: right;
  position: absolute;
}
.noReceipt {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.noReceiptIcon {
  font-size: var(--iconXL);
  color: var(--ink600);
}

.noReceiptText {
  composes: text4 from '~styles/typography/text.module.css';
}

.modalContent {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0;
}
