.pageContent {
  padding: 0;
  display: flex;
  flex-dirction: column;
}

.container {
  flex: 1 0;
  display: flex;
  overflow: hidden;
}

.menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: var(--spacing1000);
  gap: var(--spacing1000);
  box-shadow: var(--shadow2);
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1;
}

.content {
  overflow-y: auto;
  overflow-x: hidden;
  padding: var(--spacing1500);
  flex: 1 0;
}
