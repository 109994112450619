.exportButton {
  margin: 20px 0;
  justify-content: flex-end;
  text-align: right;
  display: flex;
  align-items: center;
}

.builderTable {
  overflow-x: auto;
  position: relative;
  min-height: 280px;
}

.searchTemplatesSelect {
  min-width: 250px;
}

.pageActionsWrapper {
  display: flex;
  align-items: center;
  gap: var(--spacing1000);
  flex: 1 0;

  & .counter {
    flex: 1;
  }

  & .actions {
    display: flex;
    align-items: center;
    gap: var(--spacing1000);
  }
}

@media screen and (--max-width-sm) {
  .optionsModal {
    width: 100%;
  }

  .searchTemplatesSelect {
    background-color: var(--white);
    margin: 0;
  }

  .pageActionsWrapper {
    flex-direction: column;
    align-items: flex-start;
    & .actions {
      width: 100%;

      & .searchTemplatesSelect {
        flex: 1 1;
        min-width: unset;
      }
    }
  }

  .editBtn {
    position: fixed;
    bottom: var(--spacing0750);
    right: var(--spacing0750);
    z-index: 1;
  }
}
