.container {
  min-width: 100%;
  display: flex;

  & .inputContainer {
    flex: 1;
    display: flex;
    margin-right: var(--spacing0500);
    margin-left: var(--spacing0500);

    & :global(.PhoneInputCountry) {
      display: none;
    }

    & .customInput {
      border: 0;
      outline: none;
      padding: 0;
      margin: 0;
      flex: 1;
      &::placeholder {
        color: var(--ink400);
      }
    }
  }
}
