.container {
  padding: var(--spacing1500) var(--spacing1000) var(--spacing1500)
    var(--spacing3000);
  & .spendAmountWrapper {
    margin: var(--spacing1500) 0;
  }
}
.freezeBlock {
  border-top: 1px solid var(--gray500);
  display: flex;
  flex-direction: column;
}
.action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: var(--spacing1500) 0 var(--spacing1500);
}
