.emailSent {
  margin-bottom: var(--spacing1000);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: var(--spacing0500);
}
.pleaseVerify {
  margin-bottom: var(--spacing1000);
}
.description {
  margin-bottom: var(--spacing2000);
}

.link {
  padding-left: var(--spacing0500);
}
