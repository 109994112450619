.container {
  display: flex;
  align-items: center;
  gap: var(--spacing0750);
  padding: 0.5rem 1rem;
  background: var(--white);
  box-shadow: 0 0 5px var(--black020);
  z-index: 1;
}

@media screen and (--max-width-sm) {
  .container.responsive {
    gap: unset;
  }
}
