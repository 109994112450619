.container {
  color: var(--ink500);
  white-space: nowrap;
  display: inline-block;
  padding: 0;
}

.status {
  cursor: help;
  color: var(--blockingRed);
}

.errorsList {
  margin: 0;
  padding: 0.5rem;
  padding-left: 1.5rem;
}
