.buttonWrapper {
  position: relative;
  @media screen and (--max-width-sm) {
    width: 100%;
    & .button {
      width: 100%;
    }
  }
}

.badge {
  position: absolute;
  background: var(--progressGreen);
  right: 0;
  top: 0;
}
