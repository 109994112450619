.container {
  display: grid;
  grid-template-columns:
    calc(50% - var(--spacing1000))
    calc(50% - var(--spacing1000));
  grid-gap: var(--spacing2000);
  align-items: center;
  width: 100%;
}

.chartWrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.title {
  display: block;
  margin-bottom: var(--spacing0500);
}

.list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.listItem {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:not(:last-child) {
    margin-bottom: var(--spacing0500);
  }
}

.bullet {
  display: inline-flex;
  width: 10px;
  height: 10px;
  margin-right: var(--spacing0500);
  border-radius: 50%;
}
