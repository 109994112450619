.container {
  background: var(--gray100);
  padding: var(--spacing1500);
  margin-bottom: var(--spacing1000);

  & .heading {
    margin-bottom: var(--spacing1000);
    display: flex;
    align-items: center;
    gap: var(--spacing1000);
    flex-wrap: wrap;
    & .titleWrapper {
      flex: 1 0;
      overflow: hidden;
      text-overflow: ellipsis;
      & > * {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    & .actionsWrapper {
      display: flex;
      align-items: center;
      gap: var(--spacing1000);
    }
  }
}
