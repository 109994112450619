.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing0750);
  padding: 1rem;

  @media screen and (--max-width-md) {
    padding: var(--spacing1000);
  }
}
