.container {
  position: relative;
  display: flex;
  align-items: center;
  outline: 2px solid transparent;

  &.isInvalid {
    color: var(--blockingRed);
  }

  &.isOver {
    outline-color: var(--primaryText);
  }

  & .dragIndicator {
    /* visibility: hidden; */
    width: 0;
    transition: width 250ms ease-out;
  }

  &:hover .dragIndicator {
    /* visibility: visible; */
    width: 24px;
  }
}

.dragIndicator {
  width: 24px;
  height: 36px;
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  overflow: hidden;
}

.searchInput {
  flex-grow: 1;
  margin-right: var(--spacing0750);
}
