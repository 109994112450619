.wrapper {
  margin: var(--spacing0500) 0;
  & .formGroup {
    margin-top: var(--spacing2000);
  }
  & .chart {
    width: 100%;
    height: auto;
  }
}

@media screen and (--max-width-sm) {
  .wrapper {
    & .formGroup {
      min-width: unset;
    }
  }
}
