.container {
  display: flex;
  flex-direction: row;
  gap: var(--spacing1000);
  justify-content: space-between;
}

.vertical {
  flex-direction: column;
}
