.searchWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing0500);
  & .searchInput {
    width: 250px;
    max-width: 100%;
  }
}

.pagePrecontent {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.pageContent {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

@media screen and (--max-width-sm) {
  .searchWrapper {
    flex-grow: 1;
    & .searchInput {
      flex: 1;
    }
  }

  .pageSubheader {
    flex-direction: row;
    flex-grow: 1;
  }
}

@media screen and (--max-width-xs) {
  .searchWrapper {
    width: 100%;
    flex-direction: column;
    & .searchInput,
    & .searchBtn {
      width: 100%;
    }
  }
}
