.container {
  display: flex;
  flex-direction: column;
  padding: var(--spacing1000);
  flex: 1;
  width: calc(100% - 2 * var(--spacing1000));
  gap: var(--spacing0500);

  & .virtualCardWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--spacing0500);
  }

  & .budgetWrapper {
    flex: 1 0;
    & .budgetInfo {
      display: flex;
      align-items: center;
      gap: var(--spacing1000);
      margin-bottom: var(--spacing0500);
      & .left {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        & > * {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      & .changesLinkWrapper {
        display: flex;
        align-items: center;
        gap: var(--spacing0500);
        & .infoIcon {
          color: var(--primaryText);
        }
      }
    }
    & .budgetAmounts {
      display: flex;
      align-items: center;
      gap: var(--spacing1000);
      & .amexVCLogo {
        width: 80px;
        & .disabled {
          & > * {
            fill: var(--gray400);
            stroke: var(--gray400);
          }
        }
      }
      & .progression {
        flex: 1 0;
      }
    }
    & .learnMoreAboutBalanceWrapper {
      text-align: right;
    }
  }
}
