.counter {
  margin: var(--spacing1000) 0;
}

.billingAmount {
  font-weight: 700;
}

.warningIcon {
  color: var(--alertOrange);
  font-size: var(--iconXS);
  margin-left: var(--spacing0500);
}

.assignee {
  display: flex;
  flex-direction: row;
  align-items: center;
}
