.container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.selectStatus {
  width: 230px;
}

.content {
  color: var(--ink500);
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;

  &.verticallyCentered {
    justify-content: center;
  }
}

.noReceiptMessage {
  text-align: center;
}

.noReceiptIcon {
  font-size: 3rem;
}

.btnSelectReceipt {
  min-width: 250px;
  &:not(:first-child) {
    margin-top: var(--spacing0750);
  }
}

.commentField {
  width: 100%;
}
