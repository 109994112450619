.avatarBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  color: var(--ink500);
  border: 1px solid var(--ink500);
  border-radius: 50%;

  &:focus {
    outline: 2px solid var(--primaryBg);
    outline-offset: 2px;
  }

  &.dark {
    color: var(--white);
    border: 1px solid var(--white);

    &:focus {
      outline-color: var(--white);
    }
  }
}
