.container {
  display: flex;
  flex-warp: wrap;
  gap: var(--spacing1000);
  & .toggleWrapper {
    flex-shrink: 0;
  }

  & .tabsWrapper {
    flex: 1 0;
    & .tabsContainer {
      box-shadow: none;
      & .menuBar {
        background: none;
      }
    }
  }
}
