.container {
  display: flex;
  align-items: center;
  padding-left: 100px;
  cursor: default;
  pointer-events: none;
  gap: var(--spacing0500);
  padding-right: var(--spacing0500);
}

.btnWrapper {
  pointer-events: all;
}
