.container {
  flex: 1 0;
  display: flex;
  flex-direction: column;
  & .title {
    margin-bottom: var(--spacing1500);
    &.titleCentered {
      text-align: center;
    }
  }
  & .main {
    display: flex;
    flex-direction: column;
    flex: 1 0;

    & .spinnerWrapper {
      margin: var(--spacing0750);
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: var(--spacing0750);
    }
  }
  & .actionsWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding-top: var(--spacing1000);
    border-top: 1px solid var(--gray300);
    & .mainActions {
      flex: 1 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: center;
      gap: var(--spacing0500);
    }
  }
}

@media screen and (--max-width-md) {
  .container {
    width: unset;
  }
}
