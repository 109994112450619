.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 2em;
  & .icon {
    color: var(--primaryText);
    flex: 1;
    font-size: 80px;
  }

  & .message {
    margin: 2em 0;
    width: 60%;
    text-align: center;
  }

  & .spinnerWidthCountDown {
    position: relative;
    & .counter {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 10px;
    }
  }

  & .btn {
    margin-top: 2em;
  }
}
