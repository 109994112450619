.formField {
  margin-bottom: 0;
}

.readonlyValue {
  padding: 0;
}

.percentileInput {
  width: 115px;
  min-width: 0;
  flex-shrink: 0;
}
