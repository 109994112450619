.container {
  display: flex;
  gap: var(--spacing1000);
  margin: var(--spacing0750);
  flex-direction: column;
}

.radioButton {
  flex-wrap: nowrap;
  display: flex;
}
