.container {
  padding: var(--spacing0500) 0;
  display: flex;
  flex-direction: column;
  & .item {
    padding: var(--spacing0750) var(--spacing1000);
    text-align: left;
    &:hover {
      background: var(--brightBlue100);
    }
  }
}
