.container {
  display: flex;
  align-items: flex-start;
  gap: var(--spacing1500);
  & .valueTypeWrapper {
    width: 240px;
  }
  & .togglesWrapper {
    & .togglesInner {
      display: flex;
      align-items: center;
      gap: var(--spacing1500);
    }
  }
}

@media (--max-width-md) {
  .container {
    gap: var(--spacing1000);
    & .valueTypeWrapper {
      flex: 1 0;
      width: unset;
    }
    & .togglesWrapper {
      flex: 1 0;
      & .togglesInner {
        flex-direction: column;
        align-items: flex-start;
        gap: var(--spacing1000);
      }
    }
  }
}

@media (--max-width-sm) {
  .container {
    flex-direction: column;
    & .valueTypeWrapper {
      width: 100%;
    }
  }
}
