.container {
  position: relative;
  width: 28px;
  height: 28px;
  animation: rotate 1s infinite linear;

  & > div {
    width: 2px;
    height: 8px;
    background: var(--gray700);
    position: absolute;
    top: 10px;
    left: 13px;
    margin: 0;

    &.bar1 {
      transform: rotate(0deg) translate(0, -10px);
      opacity: 0.12;
    }
    &.bar2 {
      transform: rotate(45deg) translate(0, -10px);
      opacity: 0.25;
    }
    &.bar3 {
      transform: rotate(90deg) translate(0, -10px);
      opacity: 0.37;
    }
    &.bar4 {
      transform: rotate(135deg) translate(0, -10px);
      opacity: 0.5;
    }
    &.bar5 {
      transform: rotate(180deg) translate(0, -10px);
      opacity: 0.62;
    }
    &.bar6 {
      transform: rotate(225deg) translate(0, -10px);
      opacity: 0.75;
    }
    &.bar7 {
      transform: rotate(270deg) translate(0, -10px);
      opacity: 0.87;
    }
    &.bar8 {
      transform: rotate(315deg) translate(0, -10px);
      opacity: 1;
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  0.0001% {
    transform: rotate(45deg);
  }
  12.5% {
    transform: rotate(45deg);
  }
  12.5001% {
    transform: rotate(90deg);
  }
  25% {
    transform: rotate(90deg);
  }
  25.0001% {
    transform: rotate(135deg);
  }
  37.5% {
    transform: rotate(135deg);
  }
  37.5001% {
    transform: rotate(180deg);
  }
  50% {
    transform: rotate(180deg);
  }
  50.0001% {
    transform: rotate(225deg);
  }
  62.5% {
    transform: rotate(225deg);
  }
  62.5001% {
    transform: rotate(270deg);
  }
  85% {
    transform: rotate(270deg);
  }
  85.0001% {
    transform: rotate(315deg);
  }
  97.5% {
    transform: rotate(315deg);
  }
  97.5001% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
