.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  gap: var(--spacing0750);
  height: var(--app-nav-size);
  padding: var(--spacing0500) var(--spacing1000);
  background-color: var(--white);
  box-sizing: border-box;
  box-shadow: var(--shadow1);
  z-index: 2;

  &.withTightLeftPadding {
    padding-left: var(--spacing0500);
  }

  &.isDark {
    color: var(--white);
    background-color: var(--gray800);

    & .subTitle {
      color: var(--white);
    }
  }
}

.left {
  display: flex;
  align-items: center;
  gap: var(--spacing0250);
  overflow: hidden;
}

.right {
  display: flex;
  align-items: center;
  gap: var(--spacing0750);
}

.headerBlock {
  overflow: hidden;
}

.backBtn {
  margin: var(--spacing0500);
}

.title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.subTitle {
  color: var(--ink500);
}
