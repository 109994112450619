.container {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  flex-shrink: 0;

  & > * {
    flex-grow: 1;
  }
}

.table {
  border-spacing: 0;
  width: 100%;
}
