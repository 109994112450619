.banner {
  position: fixed;
  background-color: var(--primaryBg);
  box-shadow: 0 50px 50px var(--black050);
  top: 0;
  left: 0;
  right: 0;
}

.infoWrapper {
  background: var(--primaryBg);
}

.description {
  font-weight: 600;
  color: var(--textOnPrimaryBg);
  margin-right: 6rem;
  width: 95%;
  white-space: normal;
  overflow: visible;
}

.closeBtnContainer {
  position: absolute;
  top: 50%;
  right: var(--spacing0750);
  transform: translateY(-50%);
}

.closeIcon {
  color: var(--textOnPrimaryBg);
  font-size: 20px;
}
