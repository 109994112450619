.segmentLabel {
  composes: text5 from '~styles/typography/text.module.css';
  display: block;
}

.segments {
  margin-bottom: 1.5em;
}

.details {
  cursor: help;
}

.infos {
  margin: 0.2em;
}

.asterix {
  composes: text6 from '~styles/typography/text.module.css';
}

.success {
  color: var(--progressGreen);
}

.alert {
  color: var(--blockingRed);
}

.tooltip {
  pointer-events: auto !important;
  overflow: auto;
  width: 300px;
  max-height: 400px;
}

.tooltip:hover {
  visibility: visible !important;
  opacity: 1 !important;
}
