.modal {
  border-radius: 8px;
  overflow: hidden;
}

.content {
  display: flex;
  overflow: hidden;
  padding: 0;
}

@media screen and (--max-width-md) {
  .modal {
    border-radius: 0;
    overflow: unset;
  }
  .content {
    display: block;
    overflow: auto;
    border-radius: 0;
  }
}
