.actionButton {
  display: block;
  width: 150px;
  height: 40px;
  margin: 0;
  border: none;
  position: relative;
  z-index: 1;
  padding: 0 1em;
  font-size: 1em;
  text-align: left;
  background-color: var(--white);
  color: var(--ink500);

  &:not(:last-of-type) {
    border-bottom: 1px solid var(--gray300);
  }

  &:hover,
  &:active,
  &:focus {
    color: var(--ink500);
    background-color: var(--gray300);
  }
}

.popover {
  padding: 0;
}

.icon {
  font-size: var(--iconS);
}
