.container {
  & p {
    color: var(--ink600);
  }
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  flex: 1;
  gap: 1rem;

  & p {
    margin: 0;
    text-align: center;
  }
}

.icon {
  font-size: 4rem;
}
