.container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dateRangeContainer {
  position: relative;
}

.datePresets {
  display: flex;
  align-items: flex-end;
  gap: var(--spacing0750);
  margin: 0 var(--spacing0750);
}

.errorWarning {
  position: absolute;
  bottom: 11px;
  right: 72px;
}

@media screen and (--max-width-sm) {
  .container {
    align-items: flex-start;
    flex-direction: column;
  }

  .datePresets {
    margin: var(--spacing0750) 0;
    gap: var(--spacing0250);
  }
}
