.container {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--gray300);
  overflow-x: auto;

  --tab-menu-item-padding: var(--spacing0250);

  &.navigation {
    & .tabMenu {
      gap: calc(var(--spacing2000) - 2 * var(--tab-menu-item-padding));
      padding: 0 var(--spacing1000);
      box-sizing: border-box;
    }

    & .tabMenuItem {
      padding: 0 var(--tab-menu-item-padding);
      &.tabSelected {
        border-bottom-color: var(--primaryText);

        &.isDark {
          border-bottom-color: var(--white);
          color: var(--white);
          text-shadow: 1px 0px 0px var(--white);
        }
      }
      &.disabled {
        pointer-events: none;
        color: var(--ink400);
      }
    }
  }

  &.panel {
    & .tabMenuItem {
      padding: 0 var(--spacing1000);

      &.tabSelected {
        background-color: var(--white);
        border-top-color: var(--primaryText);

        &.isDark {
          border-top-color: var(--white);
          text-shadow: 1px 0px 0px var(--white);
        }
      }
    }

    & .tabContent {
      padding: var(--spacing1000);
      background-color: var(--white);
    }
  }
}

.tabMenu {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.tabMenuItem {
  display: flex;
  align-items: center;
  background-color: transparent;
  font-size: inherit;
  box-sizing: border-box;
  height: 48px;
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  color: inherit;

  &:focus {
    outline: 2px solid var(--primaryBg);
    outline-offset: -2px;
    border-radius: 4px;
  }

  &.tabSelected {
    color: var(--primaryText);
    text-shadow: 1px 0px 0px var(--primaryText);
  }
}

.tabContent {
  flex: 1;
  padding: 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
}

.badgeContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing0500);
}

.icon {
  margin-right: var(--spacing0250);
  font-size: var(--iconS);
}
