.formTitle {
  margin-bottom: var(--spacing1000);
}
.radioGroup {
  margin-bottom: var(--spacing1000);
}
@media (--min-width-md) {
  .modalWidth {
    width: 600px;
  }
}
