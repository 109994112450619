.container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  & .step {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: center;
    flex: 1;
    width: 0;
    & .dotBar {
      width: 100%;
      display: flex;
      align-items: center;
      position: relative;
      justify-content: center;
      & .dot {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        border: 3px solid transparent;
        outline: 1px solid transparent;
        z-index: 1;
        &::before {
          content: '';
          display: block;
          width: inherit;
          height: inherit;
          border-radius: inherit;
          background-color: var(--gray600);
        }
      }

      & .bar {
        width: calc(100% - 0.5rem);
        height: 0;
        border-top: 2px solid var(--ink400);
        position: absolute;
        right: calc(50% + 0.5rem);
        top: 50%;
        overflow-x: visible;
        transform: translateY(-50%);
      }
    }

    & .label {
      width: 100%;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: var(--spacing0500) var(--spacing0500) 0 var(--spacing0500);
      color: var(--ink400);
    }

    &.active {
      & .dotBar {
        & .dot {
          &::before {
            background: var(--primaryText);
          }
        }
        & .bar {
          border-top-color: var(--primaryText);
        }
      }
      & .label {
        color: var(--primaryText);
      }
    }

    &.current {
      & .dotBar {
        & .dot {
          border-color: var(--white);
          outline-color: var(--primaryText);
        }
      }
      & .label {
        font-weight: bold;
      }
    }

    &:first-child {
      & .dotBar {
        & .bar {
          display: none;
        }
      }
    }

    &.futureTrack,
    &.pastTrack {
      & .dotBar {
        & .bar {
          border-top-style: dashed;
          border-color: var(--ink400);
        }
      }
    }
    &.futureTrack {
      & .dotBar {
        & .bar {
          border-color: var(--primaryText);
        }
      }
    }
  }
}
