.container {
  width: 640px;
  flex: 1 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  & .mainWrapper {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 var(--spacing2500);
    & .main {
      width: 100%;
    }
    & .amexLogoContainer {
      width: 100%;
      margin-top: var(--spacing1000);
      display: flex;
      align-content: space-around;
      justify-content: center;
      & .amexLogo {
        height: 32px;
      }
    }
    & .disclaimer {
      margin-top: var(--spacing2000);
      margin-bottom: var(--spacing1000);
    }
  }
  & .formFooter {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: var(--spacing1500) var(--spacing2500);
    border-top: 1px solid var(--gray300);
    & .right {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: auto;
      gap: var(--spacing0750);
    }
  }
}

@media screen and (--max-width-md) {
  .container {
    width: unset;
  }
}
