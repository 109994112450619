.container {
  background: var(--white);
  box-shadow: 0 0 5px var(--black020);
  border-top: 3px solid transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  &.disabled {
    & .body {
      & .iconWrapper {
        & .icon {
          color: var(--ink400);
        }
      }

      & .contentsWrapper {
        & .titleWrapper {
          & .title {
            color: var(--ink400);
          }
        }
      }
    }
  }

  &.fullHeight {
    height: 100%;
  }
  &.primaryContainer {
    border-top-color: var(--primaryText);
  }

  & .body {
    display: flex;
    flex-direction: row;
    padding: 20px;
    flex: 1;
    &:not(:last-child) {
      border-bottom: 1px solid var(--gray300);
    }
    & .iconWrapper {
      & .icon {
        font-size: 48px;
        color: var(--primaryText);
      }
    }
    & .contentsWrapper {
      padding-left: 20px;
      flex: 1;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      & .tags {
        display: flex;
        & > div {
          margin: 0 var(--spacing0750) var(--spacing0750) 0;
        }
      }
      & .contents {
        flex: 1;
      }
    }
    & .header {
      display: flex;
      flex-direction: column;
      gap: var(--spacing0250);
      margin-bottom: var(--spacing1500);
    }
    & .subTitle,
    & .description {
      color: var(--ink500);
    }
  }
  & .toggles:last-child {
    margin-bottom: var(--spacing0750);
  }
  & .actions {
    padding: 20px;
  }
}

@media (--max-width-sm) {
  .container {
    padding-bottom: 6px;
    & .body {
      padding: 12px;
      & .contentsWrapper {
        padding-left: 12px;
      }
    }
    & .actions {
      padding: 12px 12px 6px 12px;
    }
  }
}

.tooltipIcon {
  margin: 0 10px;
  font-size: 15px;
  color: var(--ink500);
}
