.leftHeader {
  flex: 1;
  display: flex;
  align-items: center;
  padding-right: 1rem;
  width: 100%;
  box-sizing: border-box;

  & .date {
    width: 5rem;
  }

  & .title {
    font-size: 18px;
    margin: 0 1rem;
    font-weight: bold;
    flex: 1;
  }
}
