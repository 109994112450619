.container {
}

.withBottomBorder {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: var(--gray400) !important;
}

.withTopBorder {
  border-top-width: 1px;
  border-top-style: solid;
  border-color: var(--gray400) !important;
}

.header {
}

.headColumn {
  composes: withBottomBorder;
  padding: 1rem 0;
  padding-left: 10px;
  padding-right: 10px;
  text-align: left;
  white-space: nowrap;
}

.headColumn:not(:first-child) {
  text-align: right;
}

.itemRow {
  padding: 1rem 0;
  &:not(:first-child) {
    border-top: 1px slid var(--ink500);
  }
}

.totalTitle {
  text-align: right;
  font-weight: bold;
}

.shippingTitle {
  composes: totalTitle;
}

.value {
  border-color: var(--gray400) !important;
  text-align: right;
  white-space: nowrap;
}

.value:last-child,
.headColumn:last-child {
  border-left-width: 1px;
  border-left-style: solid;
}

.totalValue {
  composes: value;
}

.shippingValue {
  composes: value;
}

.taxValue {
  composes: value;
}

.subTotalTitle {
  composes: totalTitle withTopBorder;
}

.subTotalValue {
  composes: totalValue withTopBorder;
}

.bigTotalTitle {
  composes: totalTitle withTopBorder;
}

.bigTotalValue {
  composes: totalValue withTopBorder;
  font-weight: bold;
}
