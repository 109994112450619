.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing0500);
  & .pointLine {
    display: flex;
    align-items: center;
    /* margin: var(--spacing0500) 0; */
    &:before {
      content: '';
      display: block;
      width: 3px;
      height: 3px;
      margin: 0 var(--spacing0500);
      background-color: var(--ink600);
      border-radius: 50%;
    }
  }
}
