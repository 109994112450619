.container {
  & .wording {
    margin-bottom: var(--spacing0500);
  }

  & .field {
    flex: 1 0;
    margin-bottom: 0;
  }
}
