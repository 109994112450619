.content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.shortcuts {
  width: 100%;
  margin-bottom: 1rem;
}

.shortcutWrapper {
  box-shadow: 0 0 5px var(--black020);
  height: 100%;
}

.helpCardContent {
  display: flex;
  flex-direction: column;
  height: 100%;
  & .msg {
    flex: 1;
  }
  & .actions {
    display: flex;
    flex-wrap: wrap;
    margin-top: var(--spacing0250);
    margin-bottom: var(--spacing0500);
  }
}

.tripDataInfobox:not(:first-child) {
  margin-top: 0;
}

@media screen and (--max-width-md) {
  .empty {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
