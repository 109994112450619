.container {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  & .subHeader {
    margin-top: var(--spacing2000);
    display: flex;
    align-items: stretch;
    padding: 0 var(--spacing2000);
    box-sizing: border-box;
    overflow: hidden;

    & .info {
      flex: 1;
      display: flex;
      align-items: center;
      overflow: hidden;
      gap: var(--spacing1500);
      & .infoItem {
        white-space: nowrap;
        overflow: hidden;
        & .infoItemTitle {
          font-size: 12px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        & .infoItemValue {
          font-size: 14px;
          font-weight: bold;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    & .status {
      display: flex;
      align-items: center;
      font-size: 12px;
      & .statusTag {
        background: var(--gray700);
      }
    }
  }

  & .content {
    padding: var(--spacing2000);

    & .details {
      margin: var(--spacing1000) 0;
    }
  }
}
