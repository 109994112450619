.container {
  display: flex;
  align-items: flex-start;
  gap: var(--spacing1000);
  flex-wrap: wrap;
  margin-bottom: var(--spacing1000);
  & .field {
    flex-basis: calc(50% - var(--spacing0500));
    margin-bottom: 0;
    @media screen and (--max-width-sm) {
      flex-basis: 100%;
    }
  }
}
