.container {
  display: inline-flex;

  & > span {
    display: inline-flex;
  }
}

.icon {
  color: var(--primaryText);

  &.error {
    color: var(--blockingRed);
  }

  &.warning {
    color: var(--alertOrange);
  }
}
