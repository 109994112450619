.container {
  padding: 5px;
  opacity: 1;
}

.item {
  font-weight: normal;
  text-align: left;
  display: flex;
  margin: 5px 0;
}

.selected {
  font-weight: bold;
  font-style: normal;
}

.itemTitle {
  flex: 1;
}

.title {
  font-weight: bold;
  text-align: center;
  margin-bottom: 15px;
  text-transform: uppercase;
}
