.formattedAddress > span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.formattedAddress > span:first-child {
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.formattedAddress > span:not(:first-child) {
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
