.value {
  overflow: hidden;
}

.label {
  display: inline-block;
  font-weight: bold;
  margin-right: 0.5rem;
}

.subLabel {
  white-space: nowrap;
  text-overflow: ellipsis;
}
