.accordionBtn {
  border: none;
  padding: var(--spacing0500) 0;
  gap: var(--spacing0250);
}

.accordion {
  border: none;
}

.accordionInner {
  padding: var(--spacing0500) var(--spacing1000);
  max-height: 200px;
  overflow-y: auto;

  & .listWrapper {
    & .title {
      margin-bottom: var(--spacing0500);
    }

    & .list {
      display: flex;
      flex-direction: column;
      gap: var(--spacing0500);
      list-style: none;
      margin: 0;
      padding: 0;
      & .line {
        display: flex;
        align-items: center;
        gap: var(--spacing1000);

        & .recipient {
          display: flex;
          gap: var(--spacing0500);
          flex: 1 0;
          align-items: center;

          & .tag {
            display: flex;
            gap: var(--spacing0500);
            color: var(--green500);
            align-items: center;
            & .checkMark {
              font-size: var(--iconS);
            }
          }
        }
      }
    }

    & .divider {
      height: 0;
      width: 100%;
      border-bottom: 1px solid var(--gray500);
      margin: var(--spacing0500) 0;
    }
  }
}
