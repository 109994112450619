.form {
  display: flex;
  align-items: flex-end;
  gap: var(--spacing1000);
  flex-grow: 1;
}

.field {
  width: 240px;
  margin-bottom: 0;
}

.title {
  margin-bottom: var(--spacing1000);
}
