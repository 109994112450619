.actionsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing0750);
  width: 100%;
  & > * {
    flex: 1 0;
  }
}
.distribWrapper {
  display: flex;
  gap: var(--spacing1500);
  flex-direction: column;
}
