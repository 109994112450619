.main {
  display: flex;
  flex-direction: column;
  gap: var(--spacing0500);

  & > * {
    margin: 0;
  }
}

.separator,
.invisibleSeparator {
  width: 100%;
  margin: var(--spacing0250) 0;
  border: none;
}

.separator {
  border-top: 1px solid var(--gray600);
}

.horizontalFormField {
  display: flex;
  align-items: center;
  gap: var(--spacing0500);

  & > *:first-child {
    flex-grow: 1;
  }
}

.newAmountInput {
  margin: 0;
}

.halfFormField {
  width: 50%;
}

.textBlockInput {
  min-height: 100px;
}

.footer {
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing0750);
  margin-top: var(--spacing1000);
}

@media screen and (--max-width-xl) {
  .halfFormField {
    width: 100%;
  }
}
