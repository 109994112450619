.container {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: 70px;
  padding: 0 var(--spacing1000);
  background-color: var(--gray200);
}

.cardDetails {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.iconText {
  font-weight: bold;
}

.pan {
  font-weight: bold;
  color: var(--primaryText);
}
