.container {
  border-bottom-color: var(--gray300);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-radius: 2px;
  position: relative;
  display: flex;
  flex-direction: column;
  background: var(--white);
  width: 100%;
}

.header {
  border-color: var(--gray300);
  padding-bottom: 1rem;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}

.headerTitleIcon {
  margin-right: 4px;
}

.headerTitle {
  font-weight: bold;
}

.content {
  padding: 1rem;
  flex: 1;
}

.actions {
  display: flex;
  align-items: center;
  gap: var(--spacing0750);
}

@media screen and (--max-width-sm) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .actions {
    flex-wrap: wrap;
    width: 100%;

    & > * {
      width: 100%;
      margin: 0.5em 0 0;

      &:not(:first-child) {
        margin-left: 0;
      }
    }
  }
}
