.container {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: -1px;
  transform: translateY(100%);
  padding: var(--spacing0500);
  box-shadow: 0 0 5px var(--black020);
  max-height: 150px;
  overflow-y: scroll;
  z-index: 6;
  box-sizing: border-box;
  background-color: var(--white);
  & .option {
    padding: var(--spacing0500);
    position: relative;
    z-index: 5;
  }

  & .option:hover {
    background-color: var(--primaryBg);
    color: var(--white);
  }
}

.selectMask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
}
