.addButtonWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0;
}

.addButton {
  box-sizing: border-box;
  background-color: var(--gray200);
  box-shadow: 0 0 0 var(--black020);
}

.helperMsg {
  color: var(--ink500);
}
