.img {
  max-width: 100%;
  max-height: 100%;
}

.advancedWrapper {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border: 2px solid var(--gray300);
  & .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0.5rem;
    background: var(--gray300);
  }
  & .zoomContainer {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    & .zoom {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: var(--spacing0500);
      & .advancedImage {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
        height: 100%;
      }
    }
  }
}
