.container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: var(--spacing1000);
  &.horizontal {
    flex-direction: row;
    gap: var(--spacing1500);
  }
}
