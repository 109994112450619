.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: var(--spacing0750);
  margin-bottom: var(--spacing0750);
}
.successIcon {
  color: var(--progressGreen);
  font-size: var(--iconL);
}
