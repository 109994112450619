.row {
  cursor: pointer;
  height: 64px;
}

.row:nth-of-type(even) td:nth-child(2),
.row:nth-of-type(even) td:nth-child(3),
.row:nth-of-type(even) td:nth-child(5),
.row:nth-of-type(even) td:nth-child(7),
.row:nth-of-type(even) td:nth-child(8),
.row:nth-of-type(even) td:nth-child(9),
.row.rowHasState:nth-of-type(even) td:nth-child(5) {
  background: var(--gray100);
}

.row:nth-of-type(odd) td:nth-child(2),
.row:nth-of-type(odd) td:nth-child(3),
.row:nth-of-type(odd) td:nth-child(5),
.row:nth-of-type(odd) td:nth-child(7),
.row:nth-of-type(odd) td:nth-child(8),
.row:nth-of-type(odd) td:nth-child(9),
.row.rowHasState:nth-of-type(odd) td:nth-child(5) {
  background: var(--white);
}

.row:nth-of-type(even) td:nth-child(4),
.row:nth-of-type(even) td:nth-child(6) {
  background: var(--gray300);
}

.row:nth-of-type(odd) td:nth-child(4),
.row:nth-of-type(odd) td:nth-child(6) {
  background: var(--gray100);
}

.row td:nth-child(4),
.row td:nth-child(7) {
  position: relative;
}

.row td:nth-child(4):before {
  position: absolute;
  content: '';
  box-shadow: inset -6px 0 8px -10px var(--ink600);
  height: 100%;
  top: 0;
  left: -6px;
  width: 6px;
}

.row td:nth-child(7):before,
.row.rowHasState td:nth-child(4):after {
  position: absolute;
  content: '';
  box-shadow: inset 6px 0 8px -10px var(--ink600);
  height: 100%;
  top: 0;
  right: -6px;
  width: 6px;
}

.rowAccount {
  background: var(--primaryBg);
  color: var(--textOnPrimaryBg);
  position: relative;
}

.rowAccount:after {
  position: absolute;
  content: ' ';
  top: 0;
  left: 100%;
  width: 0;
  height: 0;
  border-top: 32px solid transparent;
  border-bottom: 32px solid transparent;
  border-left: 16px solid var(--primaryBg);
}

.cell {
  text-align: center;
  &:first-child {
    text-align: left;
    padding-left: var(--spacing1000);
  }
  &.withDate {
    padding: 0 var(--spacing1000);
  }
}

.cellActions {
  width: 170px;
  min-width: 170px;
  max-width: 170px;
  text-align: left;
  font-size: 15px;
  padding-left: var(--spacing1000);
  --btnSpacing: var(--spacing1000);

  & .btnExtract {
    display: none;
    cursor: pointer;
  }

  & .btnAction {
    font-size: var(--iconS);
    margin-left: calc(var(--btnSpacing) + 1px);
  }
}

.rowAccountGroupSeparator {
  height: var(--spacing1000);
  background: var(--gray400);
}

.rowEmpty {
  background: var(--gray400);
}

.isExtracting {
  background: var(--primaryBgLight);
  color: var(--primaryText);
}

.onSuccess {
  background: var(--progressGreenBg);
  color: var(--progressGreen);
}

.onError {
  background: var(--blockingRedBg);
  color: var(--blockingRed);
}

.sum {
  color: var(--primaryText);
}

.amount {
  color: var(--ink600);
}

.extractLineStateContainer {
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.row:hover .btnExtract {
  display: initial;
}

.row:hover .btnAction {
  display: none;
}

.notifySuccessIcon {
  transform: scaleX(-1);
}

.extractLineStateMessage {
  font-weight: 700;
  color: inherit;
}

/* --- Hover styles --- */
.row:not(.rowHasState):hover td:nth-child(2),
.row:not(.rowHasState):hover td:nth-child(3),
.row:not(.rowHasState):hover td:nth-child(8),
.row:not(.rowHasState):hover td:nth-child(9) {
  background: var(--brightBlue100);
}

.row:not(.rowHasState):hover td:nth-child(4),
.row:not(.rowHasState):hover td:nth-child(6),
.row:not(.rowHasState):hover td:nth-child(5),
.row:not(.rowHasState):hover td:nth-child(7) {
  background: var(--brightBlue200);
}

.row:not(.rowHasState):hover td:nth-child(7):after {
  content: '';
  position: absolute;
  top: calc(50% - 9px);
  right: -8px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 9px 0 9px 9px;
  border-color: transparent transparent transparent var(--brightBlue200);
}

.row:hover .btnExtract {
  display: initial;
}

.row:hover .btnAction {
  display: none;
}

/* --- Hover onError styles --- */
.row.rowErrors:hover td:nth-child(2),
.row.rowErrors:hover td:nth-child(3),
.row.rowErrors:hover td:nth-child(5),
.row.rowErrors:hover td:nth-child(6) {
  background: var(--blockingRedBg);
}

.row.rowErrors:hover .onError {
  color: var(--white);
  background: var(--blockingRed);
}

.row.rowErrors:hover td:nth-child(4):after {
  position: absolute;
  top: calc(50% - 9px);
  right: -8px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 9px 0 9px 9px;
  border-color: transparent transparent transparent var(--blockingRed);
}
