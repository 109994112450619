.loadingContent {
  display: flex;
  flex-direction: row;
  padding: 2em 3em 2em;
}

.loadingMessages {
  display: flex;
  flex-direction: column;
  font-size: 1.5em;
  margin-left: 1.7em;
  font-weight: lighter;
  color: grey;
}

.loadingHeading {
  font-weight: 600;
  color: var(--ink600);
  margin-bottom: 0.15em;
}

.loader {
  position: relative;
  top: 0.7em;
  border: 5px solid var(--gray300);
  border-radius: 50%;
  border-top: 5px solid var(--primaryText);
  width: 30px;
  height: 30px;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
