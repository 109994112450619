.arrow {
  height: 2px;
  width: 100%;
  margin: 0 auto;
  background: var(--gray600);
  border-radius: 2px;
  position: relative;
  margin: 0 1rem;

  &:before,
  &:after {
    content: '';
    background: var(--gray600);
    position: absolute;
    height: 2px;
    width: 10px;
    border-radius: 2px;
  }

  &:before {
    right: -3px;
    bottom: -3px;
    transform: rotate(-45deg);
    border-top-right-radius: 0;
  }

  &:after {
    right: -3px;
    top: -3px;
    transform: rotate(45deg);
    border-bottom-right-radius: 0;
  }
}
