.container {
  position: relative;

  & .toggleVisibilityBtn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: var(--spacing0750);
  }

  & .input {
    /* this prevents the .toggleVisibilityBtn from being covered by the input value. */
    padding-right: var(--spacing2000);
  }
}
