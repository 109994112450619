.container {
  text-align: center;
  & .title {
    margin-bottom: var(--spacing2000);
  }
  & .icon {
    color: var(--primaryText);
    font-size: var(--iconXXL);
    margin-bottom: var(--spacing1000);
  }
  & .subTitle {
    margin-bottom: var(--spacing0500);
  }
}
