.actionsWrapper {
  margin-top: var(--spacing0500);
  margin-bottom: var(--spacing0500);
}

.description {
  display: flex;
  margin-bottom: var(--spacing0125);
}

.value {
  margin-left: var(--spacing0250);
}

.ownerInfoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ownerInfoLabel {
  white-space: nowrap;
  color: var(--primaryText);
  margin-right: var(--spacing0250);
}

@media (--max-width-sm) {
  .ownerInfoContainer {
    font-size: var(--textSFontSize);
  }
}
