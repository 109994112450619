.container {
  display: flex;
  flex-direction: row;
  gap: var(--spacing1000);
}

.select {
  flex: 1;
}

.input {
  flex-grow: 1;
  max-width: 50%;
}
