.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--gray200);
  box-shadow: var(--shadow1);
}

.description {
  margin: 0;
}
