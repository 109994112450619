.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--spacing0750);
  border-bottom-color: var(--gray300);
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-width: 3px;
  padding: 1rem;
  background: var(--white);
}

.icon {
  font-size: 3rem;
}

.address {
  flex: 1;
  text-align: right;
}

@media screen and (--max-width-sm) {
  .container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .address {
    margin: 0;
    flex: unset;
    text-align: left;
    border-left: 0;
  }

  .companyName {
    width: unset;
  }
}
