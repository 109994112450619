.toggle {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  padding: 7px 20px;
  border-bottom: 1px solid var(--gray300);
  & .labelWrapper {
    flex: 1;
    overflow: hidden;
    display: flex;
    align-items: center;
    margin-right: 5px;
    & .label {
      margin-right: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    & .tooltipIcon {
      font-size: 15px;
      color: var(--ink500);
    }
  }
}

@media (--max-width-sm) {
  .toggle {
    padding: 7px 12px;
  }
}
