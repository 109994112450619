.container {
  text-align: right;

  & .currency {
    font-size: 16px;
    line-height: 16px;
  }

  & .amount {
    font-size: 18px;
    line-height: 18px;
    font-weight: bold;
  }

  & .nature {
    font-size: 12px;
    font-weight: 600;
  }

  &.small {
    & .currency {
      font-size: 14px;
      line-height: 14px;
    }

    & .amount {
      font-size: 14px;
      line-height: 14px;
    }
  }
}
