.container {
  padding: 0 var(--spacing2000);
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}

.title {
  font-size: 18px;
  margin: 0 1rem 0 0;
  font-weight: bold;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}
