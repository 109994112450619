@charset "UTF-8";

@font-face {
  font-family: 'Open Sans';
  font-display: swap;
  font-style: normal;
  font-weight: 300;
  src: url('./OpenSans-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: url('./OpenSans-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-display: swap;
  font-style: normal;
  font-weight: 600;
  src: url('./OpenSans-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  src: url('./OpenSans-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-display: swap;
  font-style: normal;
  font-weight: 800;
  src: url('./OpenSans-ExtraBold.ttf') format('truetype');
}

/* Italic */
@font-face {
  font-family: 'Open Sans';
  font-display: swap;
  font-style: italic;
  font-weight: 300;
  src: url('./OpenSans-LightItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-display: swap;
  size-adjust: 88%;
  font-style: italic;
  font-weight: 400;
  src: url('./OpenSans-Italic.ttf') format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-display: swap;
  font-style: italic;
  font-weight: 600;
  src: url('./OpenSans-SemiBoldItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-display: swap;
  font-style: italic;
  font-weight: 700;
  src: url('./OpenSans-BoldItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-display: swap;
  font-style: italic;
  font-weight: 800;
  src: url('./OpenSans-ExtraBoldItalic.ttf') format('truetype');
}
