.loaderContainer {
  display: flex;
  flex: 1;
  justify-content: center;
  justify-items: center;
  align-items: center;
}

.tableContainer {
  width: 100%;
}
