.container {
  padding: var(--spacing1500) var(--spacing3000);
  display: flex;
  align-items: flex-start;
  gap: var(--spacing4000);
  & .conditions,
  & .actions {
    display: flex;
    flex-direction: column;
    gap: var(--spacing0500);
  }

  & .pointLine {
    display: flex;
    align-items: center;
    &:before {
      content: '';
      display: block;
      width: 3px;
      height: 3px;
      margin: 0 var(--spacing0500);
      background-color: var(--ink600);
      border-radius: 50%;
    }
  }
}
