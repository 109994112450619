.addressContainer {
  display: flex;
  flex-direction: column;
  & .title {
    margin-bottom: var(--spacing0250);
  }
  & .addressLine {
    padding-top: var(--spacing0125);
    & .addressValue {
      display: inline-block;
      padding-right: var(--spacing1000);
    }
  }
  & .addressLine:first-child {
    padding-top: 0;
  }
}
