.link {
  white-space: nowrap;
  transition: outline 100ms ease-out;
  height: fit-content;
  &:visited {
    text-decoration: underline;
  }
  &:hover {
    text-decoration: underline;
  }
  &:focus {
    outline: var(--spacing0125) solid;
    outline-offset: var(--spacing0250);
    border-radius: 1px;
  }
  &.standAlone {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  &.disabled {
    pointer-events: none;
  }

  &.inherit {
    font-size: inherit;
    line-height: inherit;
  }

  &.bold {
    font-weight: var(--fontWeightSemibold);
  }

  &.primaryTheme {
    color: var(--primaryText);
    &:hover {
      color: var(--primaryText);
      text-decoration-color: var(--primaryText);
    }
    &:focus {
      outline-color: var(--brightBlue500);
    }
    &.disabled {
      color: var(--ink400);
    }
  }
  &.greyTheme {
    color: var(--ink600);
    &:hover {
      color: var(--ink600);
      text-decoration-color: var(--ink600);
    }
    &:focus {
      outline-color: var(--brightBlue500);
    }
    &.disabled {
      color: var(--ink400);
    }
  }
  &.darkTheme {
    color: var(--white);
    &:hover {
      color: var(--white);
      text-decoration-color: var(--white);
    }
    &:focus {
      outline-color: var(--white);
    }
    &.disabled {
      color: var(--darkInk300);
    }
  }
}
