.txSummariesWrapper {
  max-height: 100%;
  width: 100%;
  overflow: auto;
  & .txSummaries {
    display: table;
    width: 100%;
    & .txSummary {
      display: table-row;
      &:nth-child(odd) {
        background: var(--white);
      }
      &:nth-child(even) {
        background: var(--gray200);
      }
      & .titlesWrapper {
        display: table-cell;
        position: relative;
        overflow: hidden;
        margin-right: 1em;
        vertical-align: middle;
        & .titlesInner {
          display: flex;
          align-items: center;
          overflow: hidden;
          padding: 1em 1em 1em 0;
          & .date {
            position: relative;
            width: 4em;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 1em;
          }
          & .titles {
            flex: 1;
            overflow: hidden;
            margin-right: 1em;
            word-break: break-all;
            & .merchant {
              overflow: hidden;
              text-overflow: ellipsis;
            }
            & .accountName {
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          & .ownerName {
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
            font-size: 15px;
          }
        }
      }
      & .amountWrapper {
        justify-content: center;
        padding-right: 2em;
        display: table-cell;
        vertical-align: middle;
      }
      &.obfuscated {
        & .titlesWrapper:after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background: var(--white020);
          backdrop-filter: blur(3px);
        }
      }
    }
  }
}
