.pageContent {
  display: flex;
  flex-direction: column;
  padding: 0;

  & .summaryBlockWrapper {
    padding: 0 var(--spacing1000);
  }

  & .tabsContainer {
    margin-top: var(--spacing1000);
    box-shadow: none;
    flex: 1;
  }

  & .tabsMenu {
    padding: 0 var(--spacing1000);
  }
}
