.section {
  margin-bottom: var(--spacing3000);
  & .title {
    margin-bottom: var(--spacing1000);
    display: flex;
    align-items: center;
    gap: var(--spacing0500);
  }

  & .actions {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: var(--spacing0750);
    margin-top: var(--spacing1000);
  }

  &.bad {
    & .title {
      color: var(--blockingRed);
    }
  }

  &.good {
    & .title {
      color: var(--progressGreen);
    }
  }
}

@media screen and (--max-width-md) {
  .section {
    &.bad {
      display: none;
    }

    &.good {
      display: none;
    }
  }
}
