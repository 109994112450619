.container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: 36px;
  min-width: 5.6em;
  flex-shrink: 1;
}

.container > * {
  white-space: nowrap;
  height: 100%;
}

.value {
  flex-grow: 1;
  flex-shrink: 1;
  border: none;
}

.value > input {
  width: 100%;
  border: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 0 10px;
  box-sizing: border-box;
  &:focus-visible {
    outline: none;
  }
}

.unit {
  background-color: var(--gray100);
  border-color: var(--gray300);
  border-right-style: solid;
  border-right-width: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
}

.selectWrapper {
  background-color: var(--gray100);
  min-width: 92px;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: var(--gray400);
  border-left-width: 0;
  border-top-width: 0;
  border-bottom-width: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  &:hover {
    border-right-color: var(--gray400) !important;
  }
  &:focus-within {
    border-right-color: var(--gray400) !important;
  }
}

.select :global(.Select-control) {
  background: transparent !important;
  border: none;
}

.disabled {
  & .unit,
  & .select {
    background: var(--gray300);
  }
}
