@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.button {
  min-width: 64px;
  border-radius: 4px;
  border: 1px solid;
  font-weight: 600;
  transition: background-color 250ms ease-out, border-color 250ms ease-out,
    color 250ms ease-out;
  white-space: nowrap;
  cursor: pointer;

  & .content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--spacing0500);
    position: relative;
    & > * {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &.left {
      justify-content: flex-start;
    }

    &.right {
      justify-content: flex-end;
    }

    &.reverse {
      flex-direction: row-reverse;
    }
    & i[role='presentation'] {
      overflow: unset;
    }
  }

  &.iconOnly {
    min-width: 0;
  }

  &:focus {
    outline: 2px solid var(--primaryBg);
    outline-offset: 2px;

    &.dark {
      outline-color: var(--white);
    }
  }

  &.loading {
    cursor: wait;
  }

  &.floating {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.08),
      0px 1px 8px rgba(0, 0, 0, 0.04);
    border-radius: 9999px;
  }

  &.sm {
    height: 24px;
    padding: 0 var(--spacing0250);
    font-size: 14px;
    line-height: 20px;

    &.iconOnly {
      width: 24px;
      padding: 0;
    }

    & i[role='presentation'] {
      font-size: 16px;
    }
  }

  &.md {
    height: 36px;
    padding: 0 var(--spacing1000);
    font-size: 14px;
    line-height: 20px;

    &.iconOnly {
      width: 36px;
      padding: 0;
    }

    & i[role='presentation'] {
      font-size: 20px;
    }
  }

  &.lg {
    height: 48px;
    padding: 0 var(--spacing1500);
    font-size: 16px;
    line-height: 24px;

    &.iconOnly {
      width: 48px;
      padding: 0;
    }

    & i[role='presentation'] {
      font-size: 24px;
    }
  }

  &.xl {
    height: 56px;
    padding: 0 var(--spacing1500);
    font-size: 16px;
    line-height: 24px;

    &.iconOnly {
      width: 56px;
      padding: 0;
    }

    & i[role='presentation'] {
      font-size: 24px;
    }
  }

  &.filled {
    border-color: transparent;
    background-color: var(--primaryBg);
    color: var(--textOnPrimaryBg);
    &:hover:not([disabled]) {
      background-color: var(--primaryBgHover);
    }
  }

  &.outlined {
    border-color: var(--primaryText);
    background-color: var(--white);
    color: var(--primaryText);
    &:hover:not([disabled]) {
      border-color: var(--primaryText);
      background-color: var(--primaryBgLight);
    }

    &.dark {
      border-color: var(--primaryOnDark);
      background-color: var(--gray900);
      color: var(--primaryOnDark);
      &:hover:not([disabled]) {
        border-color: var(--primaryOnDark);
        background-color: var(--primaryOnDark);
        color: var(--gray800);
      }
    }
  }

  &.ghost {
    border-color: transparent;
    background-color: transparent;
    color: var(--primaryText);
    &:hover:not([disabled]) {
      border-color: var(--primaryBgLight);
      background-color: var(--primaryBgLight);
    }

    &.dark {
      border-color: transparent;
      background-color: transparent;
      color: var(--primaryOnDark);
      &:hover:not([disabled]) {
        border-color: var(--primaryOnDark);
        background-color: var(--primaryOnDark);
        color: var(--gray800);
      }
    }
  }

  &.discrete {
    border-color: transparent;
    color: var(--primaryText);
    &:hover:not([disabled]) {
      color: var(--primaryTextHover);
    }

    &.dark {
      color: var(--primaryOnDark);
      &:hover:not([disabled]) {
        color: var(--primaryText);
      }
    }
  }

  &.alert {
    &.filled {
      background-color: var(--blockingRed);
      color: var(--white);
      &:hover:not([disabled]) {
        background-color: var(--blockingRedDark);
      }
    }

    &.outlined {
      border-color: var(--blockingRed);
      color: var(--blockingRed);
      &:hover:not([disabled]) {
        border-color: var(--blockingRed);
        background-color: var(--blockingRedBg);
      }

      &.dark {
        border-color: var(--blockingRedLight);
        background-color: var(--gray900);
        color: var(--blockingRedLight);
        &:hover:not([disabled]) {
          border-color: var(--blockingRedLight);
          background-color: var(--blockingRedLight);
          color: var(--gray800);
        }
      }
    }

    &.ghost {
      color: var(--blockingRed);
      &:hover:not([disabled]) {
        border-color: var(--blockingRedBg);
        background-color: var(--blockingRedBg);
      }

      &.dark {
        border-color: transparent;
        background-color: transparent;
        color: var(--blockingRedLight);
        &:hover:not([disabled]) {
          border-color: var(--blockingRedLight);
          background-color: var(--blockingRedLight);
          color: var(--gray800);
        }
      }
    }

    &.discrete {
      color: var(--blockingRed);
      &:hover:not([disabled]) {
        color: var(--blockingRedDark);
      }

      &.dark {
        color: var(--blockingRedLight);
        &:hover:not([disabled]) {
          color: var(--blockingRed);
        }
      }
    }
  }

  &.success {
    &.filled {
      background-color: var(--progressGreen);
      color: var(--white);
      &:hover:not([disabled]) {
        background-color: var(--progressGreenDark);
      }
    }

    &.outlined {
      border-color: var(--progressGreen);
      color: var(--progressGreen);
      &:hover:not([disabled]) {
        border-color: var(--progressGreen);
        background-color: var(--progressGreenBg);
      }

      &.dark {
        border-color: var(--progressGreenLight);
        background-color: var(--gray900);
        color: var(--progressGreenLight);
        &:hover:not([disabled]) {
          border-color: var(--progressGreenLight);
          background-color: var(--progressGreenLight);
          color: var(--gray800);
        }
      }
    }

    &.ghost {
      color: var(--progressGreen);
      &:hover:not([disabled]) {
        border-color: var(--progressGreenBg);
        background-color: var(--progressGreenBg);
      }

      &.dark {
        border-color: transparent;
        background-color: transparent;
        color: var(--progressGreenLight);
        &:hover:not([disabled]) {
          border-color: var(--progressGreenLight);
          background-color: var(--progressGreenLight);
          color: var(--gray800);
        }
      }
    }

    &.discrete {
      color: var(--progressGreen);
      &:hover:not([disabled]) {
        color: var(--progressGreenDark);
      }

      &.dark {
        color: var(--progressGreenLight);
        &:hover:not([disabled]) {
          color: var(--progressGreen);
        }
      }
    }
  }

  &.neutral {
    &.outlined {
      border-color: var(--gray500);
      color: var(--ink600);
      &:hover:not([disabled]) {
        border-color: var(--gray500);
        background-color: var(--gray300);
      }

      &.dark {
        border-color: var(--gray300);
        background-color: var(--gray900);
        color: var(--white);
        &:hover:not([disabled]) {
          border-color: var(--gray300);
          background-color: var(--gray300);
        }
      }
    }

    &.ghost {
      color: var(--ink600);
      &:hover:not([disabled]) {
        border-color: var(--gray300);
        background-color: var(--gray300);
      }

      &.dark {
        background-color: transparent;
        color: var(--white);
        &:hover:not([disabled]) {
          border-color: var(--gray300);
          background-color: var(--gray300);
        }
      }
    }

    &.discrete {
      color: var(--ink600);
      &:hover:not([disabled]) {
        color: var(--black);
      }

      &.dark {
        color: var(--white);
        &:hover:not([disabled]) {
          color: var(--gray400);
        }
      }
    }
  }

  &.disabled {
    cursor: not-allowed;

    &.filled {
      background-color: var(--gray400);
      color: var(--ink400);
      &:hover:not([disabled]) {
        background-color: var(--gray400);
      }
    }

    &.outlined {
      border-color: var(--gray400);
      color: var(--ink400);
      &:hover:not([disabled]) {
        border-color: var(--gray400);
        background-color: var(--white);
      }

      &.dark {
        border-color: var(--gray600);
        background-color: var(--gray900);
        color: var(--gray600);
        &:hover:not([disabled]) {
          background-color: transparent;
          color: var(--gray600);
        }
      }
    }

    &.ghost {
      color: var(--ink400);
      &:hover:not([disabled]) {
        border-color: var(--white);
        background-color: var(--white);
      }

      &.dark {
        color: var(--gray600);
        &:hover:not([disabled]) {
          border-color: transparent;
          background-color: transparent;
          color: var(--gray600);
        }
      }
    }

    &.discrete {
      color: var(--ink400);
      &:hover:not([disabled]) {
        color: var(--ink400);
        border-color: transparent;
        background-color: transparent;
      }

      &.dark {
        color: var(--gray600);
        &:hover:not([disabled]) {
          border-color: transparent;
          background-color: transparent;
          color: var(--gray600);
        }
      }
    }
  }

  & .centered {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  & .spinning {
    animation: spin 1000ms infinite linear;
  }

  & .invisible {
    opacity: 0;
    visibility: hidden;
  }
}
