.label {
  margin-bottom: var(--spacing0500);
}

.value {
  display: flex;
  align-items: center;
  gap: var(--spacing0500);
}

.icon {
  width: var(--iconS);
  height: var(--iconS);
}
