.errorContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: var(--spacing1000);
}

.errorIcon {
  color: var(--blockingRed);
  font-size: var(--iconL);
}

& .sychroResultContainer {
  padding: var(--spacing1000) 0;
  & .account {
    display: flex;
    align-items: center;
    gap: var(--spacing1000);
    margin-bottom: var(--spacing0750);
    & .icon {
      font-size: var(--iconM);
      color: var(--progressGreen);
    }
  }
}
