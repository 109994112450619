.container {
  display: inline-block;
  position: relative;
}

.dropdown {
  position: absolute;
  transition: all 250ms ease-in-out;
  background-color: var(--white);
  transform: translateY(-2.5em);
  z-index: 3;
  right: 0;
  left: 0;
  margin-top: 0.5rem;
  box-shadow: 0 0 5px 0 var(--black020);

  &.slideUp {
    visibility: hidden;
    opacity: 0;
  }

  &.slideDown {
    visibility: visible;
    display: block;
    transform: translateY(0%);
  }
}

.toggle {
  width: 100%;
  text-align: left;
}

.icon {
  font-size: var(--iconXS);
}

.separator {
  min-width: 1rem;
  flex-grow: 1;
}

.dropdownCaption {
  text-align: left;
  margin-right: 5px;
}

.chevronIcon {
  margin-left: 50px;
}

@media screen and (--max-width-sm) {
  .container {
    width: 100%;
  }

  .toggle {
    width: 100%;

    & i[role='presentation'] {
      margin-right: 0;
    }
  }

  .dropdown {
    & button:last-child {
      border-bottom: 0;
    }
  }
}
