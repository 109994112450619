.page {
  background: var(--white);
  padding: 2em 4em;
  display: flex;
  flex-direction: column;
  height: 100%;
  display: block;
  height: 100%;
  max-height: 93vh;
  flex: 1;
  overflow: auto;
}
