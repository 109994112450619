.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  & .day {
    font-size: 18px;
    line-height: 16px;
  }

  & .month {
    font-weight: bold;
    font-size: 14px;
  }

  & .year {
    font-size: 11px;
  }

  &.small {
    & .day {
      font-size: 16px;
      line-height: 14px;
    }

    & .month {
      font-weight: bold;
      font-size: 12px;
    }

    & .year {
      font-size: 10px;
    }
  }
}
