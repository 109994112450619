.checkboxesWrapper {
  margin-top: var(--spacing1500);
  margin-bottom: var(--spacing2500);
}

.checkboxWithInfo {
  display: flex;
  align-items: center;
  gap: var(--spacing0500);
  & .icon {
    color: var(--primaryText);
    font-size: var(--iconXS);
  }
}

.contentInfoWrapper {
  padding: 0 0 0 var(--spacing1500);
  margin: 0;
}

.infoBox.errorBox {
  margin: 0 0 var(--spacing1500) 0;
  & .errorTitle {
    margin-bottom: var(--spacing0250);
  }
  & .error:not(:last-child) {
    margin-bottom: var(--spacing0250);
  }
}
