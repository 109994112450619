.container {
  display: flex;
  flex-direction: column;
  animation-name: appear;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  border-width: 1px;
  border-style: solid;
  margin-bottom: 1em;
}

.success {
  background-color: var(--progressGreenBg);
  border-color: var(--progressGreen);
  color: var(--progressGreen);
}

.warning {
  color: var(--alertOrange);
  border-color: var(--alertOrange);
  background-color: var(--alertOrangeBg);
}

.progress {
  composes: warning;
}

.main {
  display: flex;
  align-items: center;
  height: 100%;
  flex: 1;
}

.actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: var(--spacing0750);
  gap: var(--spacing0750);
}

.msg {
  position: relative;
  min-height: 75px;
  cursor: pointer;
  margin-bottom: 1em;
}

.iconContainer {
  height: inherit;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  composes: text2 from '~styles/typography/text.module.css';
  margin-left: 10px;
  margin-right: 10px;
}

.message {
  max-width: 400px;
  word-break: break-word;
  padding: 0;
  flex: 1;
  composes: text6 from '~styles/typography/text.module.css';
  color: var(--ink600);
}

.btnQuit {
  position: absolute;
  top: var(--spacing0750);
  right: var(--spacing0750);
}

.messageContainer {
  padding: 1em;
}

.errorTitle::first-letter {
  text-transform: uppercase;
}

.error {
  border-color: var(--blockingRed);
  background-color: var(--blockingRedBg);
  color: var(--blockingRed);
}

@keyframes appear {
  0% {
    transform: translateY(0px);
    opacity: 0;
  }
  100% {
    transform: translateY(100px);
    opacity: 1;
  }
}
