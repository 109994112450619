.leftHeader {
  padding: 0 1rem 0 0;
  flex: 1;
  display: flex;
  align-items: center;
}

.dateDetails {
  width: 5rem;
}

.titleDetails {
  composes: text3 from '~styles/typography/text.module.css';
  font-weight: bold;
  flex-grow: 1;
}

.amountDetails {
  justify-self: flex-end;
  text-align: right;
}
