.timeline {
  display: flex;
  flex: 1;
  height: 100%;
}

.timeline .block {
  width: 25%;
  height: 100%;
}

@media screen and (--max-width-md) {
  .timeline {
    flex-direction: column;
  }

  .timeline .block {
    width: 100%;
    height: 25%;
  }
}
