.modalContent {
  display: flex;
  justify-content: center;
  padding: 0 var(--spacing1500);
}

.invisible {
  visibility: hidden;
  width: 0;
  height: 0;
}

.visible {
  visibility: visible;
  min-height: 530px;
  width: 100%;
  display: flex;
  padding: var(--spacing2000) 0;
  box-sizing: border-box;
}

.spinnerWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 180px;
}

.errorWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--spacing2000) 0;
}

.errorImg {
  height: 180px;
  min-height: 180px;
}

.errorMsg {
  color: var(--ink600);
  margin: var(--spacing1000) 0;
}

@media screen and (--max-width-md) {
  .errorImg {
    height: 120px;
    min-height: 120px;
  }
}
@media screen and (--max-width-sm) {
  .errorImg {
    height: 100px;
    min-height: 100px;
  }
}
