.container {
  display: flex;
  align-items: center;
  padding: 0 var(--spacing0750);

  & .icon {
    color: var(--ink500);
    padding: 0 var(--spacing0250);
    margin-right: var(--spacing0500);
    font-size: var(--iconXS);
  }

  & .input {
    box-sizing: border-box;
    height: 36px;
    padding: 0;
    font-size: 14px;
    width: 100%;
    border: 0;
    background-color: transparent;
    color: inherit;
    transform: all 400ms;
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: var(--ink400);
    }
  }

  & .btnClear {
    margin-left: var(--spacing0500);
    display: none;
  }

  &:focus,
  &:focus-within,
  &:hover {
    & .btnClear {
      display: block;
    }
  }

  &.disabled {
    & .icon {
      color: var(--gray500);
    }
  }
}
