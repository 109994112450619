@keyframes fadeInOut {
  from {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0 0.5rem;
  background-color: var(--primaryBg);
  color: var(--white);
  font-weight: 600;
  animation: fadeInOut 2000ms ease-out forwards;

  &.success {
    background-color: var(--progressGreen);
  }

  &.warning {
    background-color: var(--alertOrange);
  }

  &.alert {
    background-color: var(--blockingRed);
  }
}

.icon {
  font-size: 1.5rem;
}
