.container {
  padding: 0;
  background: none;
  border: 1px solid transparent;
  border-radius: var(--radiusXS);
  text-align: left;
  cursor: pointer;
  white-space: nowrap;
}

.container:focus {
  outline: 1px solid var(--brightBlue500);
  outline-offset: var(--spacing0500);
}

.text {
  &.isHighlighted {
    font-weight: bold;
    text-decoration: underline;
  }
}

.icon {
  margin-left: 0.5em;
  font-size: var(--iconXS);
}
