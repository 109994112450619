.centeredSpinner {
  align-self: center;
}

.captchaContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 2rem;
  height: 78px;
}
