.tag {
  position: absolute;
  top: 15px;
  right: -19px;
  width: 170px;
  height: 30px;
  transform: rotate(30deg);
  white-space: nowrap;
  padding: 0;
  border-radius: 0;
  & > span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

.accountingTag > span {
  left: 46px;
  font-size: 10px;
}

.comingSoonTag {
  background: var(--white) !important;
  & > span {
    font-size: 12px;
    left: 60px;
    color: var(--ink400);
  }
}

.extraBtnWrapper {
  position: absolute;
  top: 0;
  right: 0;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    border-style: solid;
    border-width: 0 88px 51px 0;
    border-color: transparent var(--white) transparent transparent;
  }
}

.extraBtn {
  margin: var(--spacing0500);
  z-index: 1;
}

.connectBtn {
  &.quickbooks:not(:disabled) {
    color: var(--quickbooksGrey1);
    border-color: var(--quickbooksGrey2);
  }

  &.sage:not(:disabled) {
    color: var(--sagePrimary);
    border-color: var(--sagePrimary);
  }
}

.qboBtnWrapper {
  display: flex;
  flex-direction: row;
  gap: var(--spacing0750);
  width: 100%;
  & > * {
    flex-grow: 1;
  }
}
