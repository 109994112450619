.container {
  display: flex;
  margin: 0 var(--spacing0750);
  gap: var(--spacing0500);
}

.errorIcon {
  width: 36px;
  height: 36px;
  align-items: center;
  justify-content: center;
  color: var(--blockingRed);
  font-size: var(--iconS);
}
