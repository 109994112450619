.container {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.figures {
  composes: text6 from '~styles/typography/text.module.css';
}

.amount {
  font-weight: bold;
}

.spent {
  composes: figures;
}

.remaining {
  composes: figures;
  text-align: right;
}

.spentAmount {
  composes: amount;
}

.remainingAmount {
  composes: amount;
}

.progressBar {
  background-color: var(--gray300);
  border-radius: 5px;
  margin: 5px 0;
}
