.accordionWrapper {
  margin-bottom: var(--spacing1000);

  & .automationRulesWrapper {
    padding: var(--spacing1000);
    background: var(--white);

    & .automationRuleWrapper {
      margin-top: 0;
    }

    & .automationRuleWrapper:not(:first-child) {
      margin-top: var(--spacing1000);
    }
  }
}

.label {
  margin-bottom: var(--spacing1000);
}

@media screen and (--min-width-lg) {
  .modal {
    width: 650px;
  }
}
