.subHeader {
  position: relative;
}

.conditionTag {
  background-color: var(--progressGreen);
  color: var(--white);
  border-width: 1px;
  border-style: solid;
  border-color: var(--white);
  padding: 0.3em 0.5em;
  border-radius: 2px;
}

.conditionTag:not(:last-child) {
  margin: 0 0.5em 0 0;
}

.actionConditionalFilters {
  display: flex;
  align-items: center;
  flex-direction: column;
}
