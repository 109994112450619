.icon {
  composes: text5 from '~styles/typography/text.module.css';
}

.iconStatus {
  position: relative;
  left: 5px;
  composes: text2 from '~styles/typography/text.module.css';
}

.iconReceipt {
  composes: text5 from '~styles/typography/text.module.css';
}

.warn {
  color: var(--alertOrange);
}

.success {
  color: var(--progressGreen);
}

.alert {
  color: var(--blockingRed);
}

.default {
  color: var(--ink500);
}

.amountText {
  padding: 0;
}
