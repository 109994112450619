.rowDetailWrapper {
  padding: var(--spacing1500) var(--spacing3500);
  & .titleWrapper {
    display: flex;
    align-items: center;
    margin-bottom: var(--spacing1500);
    gap: var(--spacing0500);
    & .title {
      color: var(--ink500);
    }
  }
  & .counters {
    display: flex;
    align-items: flex-start;
    gap: var(--spacing1000);
    & .counter {
      flex: 1;
      & .label,
      & .value {
        overflow: hidden;
        text-overflow: ellipsis;
      }
      & .label {
        margin-bottom: var(--spacing0500);
      }
    }
  }
}

.failedCounter {
  display: flex;
  align-items: center;
  gap: var(--spacing0500);
  & .warningIcon {
    color: var(--alertOrange);
    font-size: var(--iconXS);
  }
}

@media screen and (--max-width-md) {
  .rowDetailWrapper {
    & .counters {
      flex-wrap: wrap;
      & .counter {
        flex: 0 0 calc(50% - var(--spacing1000));
      }
    }
  }
}

@media screen and (--max-width-sm) {
  .rowDetailWrapper {
    padding: var(--spacing0750);
    & .titleWrapper {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
