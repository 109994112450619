/* general custom style for react-calendar */
:global(.react-calendar) {
  background: var(--white);
  border-color: var(--gray400);
}

:global(.react-calendar__month-view__weekdays) {
  padding-top: var(--spacing0500);
}

:global(.react-calendar__month-view__weekdays__weekday) {
  color: var(--ink600);
  & abbr {
    text-decoration: none;
  }
}

:global(.react-calendar__month-view__days__day--weekend) {
  color: var(--ink600);
}

:global(.react-calendar__month-view__days__day--neighboringMonth) {
  color: var(--ink400);
}

:global(.react-calendar__navigation) {
  border-bottom: 1px solid var(--gray400);
  margin-bottom: 0 !important;
}

:global(.react-calendar__navigation button:disabled) {
  background: transparent;
  color: var(--gray500);
}

:global(.react-calendar__navigation__arrow:enabled) {
  color: var(--primaryText);
}

:global(.react-calendar__navigation button:enabled:hover) {
  background: transparent;
}

:global(.react-calendar__navigation__arrow) {
  font-size: var(--iconS);
}

:global(button.react-calendar__tile--now) {
  background: var(--white);
  color: var(--deepBlue500);
  font-weight: bold;
  box-shadow: inset 0px 0px 0px 1px var(--deepBlue500);
}

:global(button.react-calendar__tile:enabled:hover) {
  background: var(--brightBlue300);
  color: var(--deepBlue500);
}

:global(button.react-calendar__tile:disabled) {
  background: var(--gray300);
  color: var(--ink400);
}

:global(button.react-calendar__tile--range),
:global(.react-calendar--selectRange button.react-calendar__tile--hover) {
  background: var(--brightBlue100);
  color: var(--ink600);
}

:global(button.react-calendar__tile--rangeStart),
:global(button.react-calendar__tile--rangeEnd),
:global(button.react-calendar__tile:enabled:focus) {
  background: var(--primaryBg);
  color: var(--textOnPrimaryBg);
}
