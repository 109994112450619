.container {
  display: flex;
  flex-direction: column;
  background-color: var(--gray200);
  padding-top: var(--spacing0500);
  .suggestionsTitle {
    padding-bottom: var(--spacing0500);
    color: var(--ink500);
  }
  .categorySuggestions {
    display: flex;
    gap: var(--spacing0500);
  }
}
