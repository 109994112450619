.container {
  display: flex;
  align-items: stretch;
  box-sizing: border-box;
  justify-content: space-between;
  overflow: hidden;
  gap: var(--spacing1500);

  & .infoList {
    display: flex;
    gap: var(--spacing1500);
  }

  & .infoTitle {
    margin-bottom: var(--spacing0250);
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & .infoValue {
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & .status {
    display: flex;
    align-items: center;
    font-size: 12px;
  }
}
