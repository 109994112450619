.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing1000);
  & .left {
    flex: 1 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  & .right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: var(--spacing1000);
    &.alone {
      flex: 1 0;
    }
  }
}

@media screen and (--max-width-sm) {
  .container {
    flex-direction: column;
    & .divider {
      display: none;
    }

    & .left,
    & .right {
      flex-wrap: wrap;
      width: 100%;
    }
  }
}
