.segmentTypesContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.segmentTypesIcon {
  margin-right: 4px;
  font-size: var(--iconS);
}
