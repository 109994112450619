.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;

  &.dark {
    & .header {
      background-color: var(--gray800);
      color: var(--white);
    }
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--black);
  opacity: 0.5;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  min-width: 650px;
  max-width: calc(100% - 40px);
  max-height: calc(100% - 40px);
  background-color: var(--white);
  transform: translate(-50%, -50%);

  &.canExpand {
    max-width: 100%;

    & .content {
      max-height: none;
    }
  }
}

.header {
  display: flex;
  padding: 15px 20px;
  background-color: var(--primaryBg);
  color: var(--textOnPrimaryBg);

  &::before {
    content: '';
    width: 24px;
  }

  &.withoutTitle::before {
    flex-grow: 1;
  }

  &.withCloseBtnOnly {
    position: absolute;
    top: 0;
    right: 0;
    padding: var(--spacing0750);
    background-color: transparent;
    color: inherit;
    z-index: 1;
  }
}

.title {
  flex-grow: 1;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
}

.content {
  flex: 1;
  padding: 20px;
  overflow: auto;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: var(--spacing0750);
  padding: 20px;
  border: 1px solid var(--gray300);

  &:empty {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

@media screen and (--max-width-md) {
  .container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    flex-grow: 1;
    width: 100%;
    & .content {
      height: 100%;
    }
  }

  .modal {
    height: 100%;
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    transform: unset;
    min-width: unset;
    max-height: unset;
  }
}
