.container {
  position: relative;
  text-align: center;
  width: 100%;
}

.svg {
  color: var(--white);
  background-color: var(--white);
}

.svg rect {
  cursor: pointer;
}

.svg tspan {
  color: var(--white);
  font-weight: bold;
  text-shadow: 0 2px 4px var(--black020);
}

.container:hover .controls {
  opacity: 1;
}

.footer {
  display: flex;
  align-items: center;
}

.hint {
  flex: 1;
  text-align: left;
  font-style: italic;
}

.hintField {
  margin: 0 4px 0 4px;
  color: var(--ink500);
  font-weight: 600;
  font-size: var(--textSFontSize);
}

.hintIcon {
  margin-right: 4px;
}

.controls {
  width: 250px;
  padding: 5px;
}

.chart {
  height: 300px;

  & canvas {
    display: block;
  }
}

.legend {
  padding: 1rem 0;
  text-align: right;
}

.legendItem {
  display: inline-block;
  margin: 0 4px 0 4px;
}

.legendCube {
  display: inline-block;
  margin-right: 5px;
  width: 7px;
  height: 7px;
}

@media screen and (--max-width-sm) {
  .footer {
    flex-direction: column;
    margin-top: 1em;
  }

  .controls {
    width: 100%;
  }
}
