.container {
  background-color: var(--white);
  border: 1px solid var(--gray300);
  border-radius: 2px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing1000);
  gap: var(--spacing0500);
  border-bottom: 1px solid var(--gray300);
  max-height: 68px;
  box-sizing: border-box;

  & button {
    flex-shrink: 0;
  }
}

.title {
  font-weight: bold;
}

.main {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  min-height: 200px;
}
