.container {
  width: auto;
  min-width: 15px;
  height: 15px;
  border-radius: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 1px 3px;
  composes: text6 from '~styles/typography/text.module.css';
}

.text {
  display: block;
  text-align: center;
  font-weight: bold;
  text-shadow: none;
}

.active {
  background-color: var(--blockingRed);
  color: var(--white);
}

.inactive {
  background-color: var(--gray500);
  color: var(--ink600);
}
