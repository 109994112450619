.container {
  padding: var(--spacing1000) var(--spacing2500);
  margin-bottom: var(--spacing1500);
  display: flex;
  gap: var(--spacing0500);
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  &.hidden {
    visibility: hidden;
  }
}
