.dropdownWrapper {
  margin: 0;
}

.dropdownContainer {
  width: 100%;
  top: 100%;
}

.dropdownElement {
  width: 100%;
  justify-content: flex-start;

  &:not(:last-child) {
    border-bottom: 1px solid var(--gray300);
    padding-right: unset;
  }
}
