.modalContent {
  overflow: hidden;
  padding: 0;
  & .container {
    display: flex;
    flex-wrap: nowrap;
  }
  & .formWrapper {
    overflow-y: auto;
    overflow-x: visible;
    padding: 20px;
    & .label {
      font-weight: bold;
      margin-bottom: 2em;
    }
  }
  & .imgWrapper {
    box-sizing: border-box;
    flex: 1 0;
    overflow: hidden;
    cursor: grab;
    min-height: 50vh;
    min-width: 23vw;
    padding: 20px;
  }
}

@media screen and (--max-width-xl) {
  .modalContent {
    & .imgWrapper {
      min-width: 25vw;
    }
  }
}

@media screen and (--max-width-lg) {
  .modalContent {
    & .imgWrapper {
      min-width: 32vw;
    }
  }
}

@media screen and (--max-width-md) {
  .modalContent {
    & .imgWrapper {
      min-width: 42vw;
    }
  }
}

@media screen and (--max-width-sm) {
  .modalContent {
    display: block;
    overflow: auto;
    padding: 20px;
    & .container {
      display: block;
    }
    & .formWrapper {
      overflow: visible;
      padding: 0;
    }
    & .imgWrapper {
      padding: 0;
      height: 500px;
      min-height: unset;
    }
  }
}
