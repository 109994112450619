.content {
  overflow-y: auto;
}

.iconArchived {
  margin-right: 0.5em;
}

.generalInfos,
.items {
  padding: 0 1rem 0 5rem;
}

.generalInfos {
  padding-left: 5rem;
  padding-top: 1rem;
  margin-bottom: 1rem;
}

.field {
  margin-bottom: var(--spacing1000);
}

.paymentDetails,
.usersDetails {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  height: 60px;
}

.usersDetails {
  color: var(--white);
  justify-content: flex-start;
  background-color: var(--gray600);
  padding: 0 1rem 0 5rem;
}

.paymentDetails {
  justify-content: space-between;
  background-color: var(--gray200);
  padding: 0 1rem 0 5rem;
}

.iconText {
  font-weight: bold;
}

.pan {
  font-weight: bold;
  color: var(--primaryText);
}

.users {
  font-weight: 300;
  margin-left: 2em;
}

.username {
  font-weight: 700;
}
