.wrapper {
  margin: var(--spacing1500) 0;
  & .result {
    margin-bottom: var(--spacing1500);
    & .title {
      position: relative;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: var(--spacing0500);
      padding-bottom: var(--spacing0750);
      border-bottom: 1px solid var(--gray300);
      & .main {
        flex: 1 1;
      }
      & .counterWrapper {
        position: absolute;
        right: 0;
        bottom: var(--spacing0250);
      }
    }
    & .content {
      padding: var(--spacing1000) 0;
      & .record {
        display: flex;
        align-items: center;
        gap: var(--spacing1000);
        &:not(:last-child) {
          margin-bottom: var(--spacing0750);
        }
        & .icon {
          font-size: var(--iconS);
          color: var(--gray600);
          &.completed {
            color: var(--progressGreen);
          }
          &.warning {
            color: var(--alertOrange);
          }
        }
      }
    }
  }
}
