.container {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  width: 100%;
  flex: 1;
  border-radius: 2px;
}

.titleContainer {
  margin-bottom: 2rem;
}

.title {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.warning {
  margin: 0 0 1rem 0;
  font-weight: 600;
  border: 1px solid;
  color: var(--alertOrange);
  padding: 0.5rem;
  font-size: 12px;
  & .message {
    text-align: left;
  }
}

.imageContainer {
  border-color: var(--gray500);
  border-width: 1px;
  flex: 1;
  flex-direction: column;
  align-items: center;
  position: relative;
  justify-content: center;
  background: var(--white);
  border-radius: 2px;
  box-shadow: 0 0 3px var(--black020);
}

.btnsWrapper {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
  gap: var(--spacing0500);
}
