.container {
  padding: var(--spacing1000);
  flex: 1 0;
  display: flex;
  flex-direction: column;
  gap: var(--spacing1000);
  width: calc(100% - 2 * var(--spacing1000));
  & .content {
    display: flex;
    align-items: center;
    gap: var(--spacing1000);
    flex: 1 0;
    & .amexVC {
      display: none;
      @media (--min-width-sm) {
        display: block;
        width: 150px;
      }
      @media (--min-width-md) {
        width: 500px;
      }
      @media (--min-width-lg) {
        width: 600px;
      }
      @media (--min-width-xl) {
        width: 250px;
      }
    }
    & .description {
      margin-top: var(--spacing0250);
    }
  }
  & .actionsWrapper {
    display: flex;
    align-items: center;
    gap: var(--spacing1000);
    & > * {
      flex: 1 0;
    }
  }
}
