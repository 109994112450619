.statusContainer {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.complianceWarning {
  visibility: hidden;
  margin-right: 0.5rem;
}

.visible {
  visibility: visible;
}
