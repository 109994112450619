.container {
  display: flex;
  gap: var(--spacing1000);
  flex-direction: column;

  @media (--min-width-sm) {
    flex-direction: row;
  }

  & > * {
    flex-grow: 1;
    flex-basis: 100%;
  }
}
