.container {
  display: inline-block;
  width: 100%;
}

.input {
  padding: 10px;
  width: 100%;
  min-height: 200px;
  resize: none;
  box-sizing: border-box;
  border: none;
  outline: none;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: var(--ink400);
  }
}
