.section {
  margin-bottom: var(--spacing3000);
  & .title {
    margin-bottom: var(--spacing1500);
  }
  & .icons {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: var(--spacing1000);

    & .icon {
      padding: var(--spacing0500);
      border-radius: 4px;
      cursor: pointer;
      transition: background 200ms ease-out;
      &:hover {
        background: var(--gray300);
      }
      &:active {
        background: var(--gray500);
      }
    }
  }
}
