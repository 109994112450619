.actionButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  box-sizing: border-box;
  padding-right: var(--spacing0750);
  gap: var(--spacing1000);
}

.actions {
  display: flex;
  flex-direction: row;
}

.status {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
