.container {
  background-color: var(--white);
  position: relative;
}

.selectBox {
  width: 230px;
  height: 40px;
  margin-right: 3px;
}

.card {
  margin: 1rem 0;
}

@media screen and (--max-width-sm) {
  .buttonGroup {
    display: flex;
    flex-direction: column;

    & button {
      width: 200px;
      margin-bottom: 0.5em;
    }
  }
}
