.tooltip {
  max-width: 320px;
  padding: 15px 20px;
  color: var(--white);
  &.normal {
    background-color: var(--gray700);
  }

  &.error {
    background-color: var(--blockingRed);
  }

  &.warning {
    background-color: var(--alertOrange);
  }
}
