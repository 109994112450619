.container {
  overflow: auto;
}

.fieldList {
  list-style: none;
  padding: 10px 20px;
  margin: 0;
}

.fieldListItem {
  padding: 10px 0;
  border-bottom: 1px solid var(--gray300);

  &:last-of-type {
    border-bottom: 0;
  }
}
