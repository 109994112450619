.text {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon {
  font-size: var(--iconM);
}

.importantValue {
  font-weight: bold;
}

.beforeAfterValue {
  display: flex;
  gap: var(--spacing0250);
  align-items: center;
}
