.container {
  position: absolute;
  z-index: 4;
  width: 500px;
  font-weight: bold;
  top: -100px;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 1em;
  margin-top: 1em;
}

@media screen and (--max-width-sm) {
  .container {
    width: 100%;
    bottom: 100px;
    top: unset;
    position: fixed;
    margin: 0;
    right: 0;
  }
}
