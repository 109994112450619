.container {
  width: auto;
  min-width: 250px;

  @media screen and (--max-width-sm) {
    min-width: 0;
    width: 100%;
  }
}

.option {
  composes: text5 from '~styles/typography/text.module.css';
  margin: 0.75rem;
  position: relative;
  display: flex !important;
  width: auto;
  flex-direction: column;
  overflow: hidden;
  white-space: nowrap;
}

.name {
  flex: 1;
  text-overflow: ellipsis;
  font-size: 16px;
}

.amount {
  color: var(--ink500);
  font-size: 16px;
}

.badge {
  position: absolute;
  top: 50%;
  right: 1em;
  transform: translate(0, -50%);
}

.option {
  margin: 0;
}

.value {
  color: var(--primaryText);
}

.selectedOptionCount {
  color: var(--white);
}
