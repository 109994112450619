.container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: transparent;
  border-radius: 50%;
  box-sizing: border-box;
  font-weight: 600;
  margin: 2px;
}

.text {
  line-height: 1;
}

.light {
  border: 1px solid var(--white);
}

.light .text {
  color: var(--white);
}

.dark {
  background-color: var(--white);
  border: 2px solid var(--ink500);
  box-shadow: 0 0 0 2px var(--white);
}

.dark .text {
  color: var(--ink500);
}

.success {
  background-color: var(--progressGreenBg);
  border: 2px solid var(--progressGreen);
}

.success .text {
  color: var(--progressGreen);
}

.transparent-primary {
  color: var(--primaryText);
  background-color: transparent;
  box-shadow: 0 0 0 2px var(--primaryText);
}
