.tabsContainer {
  height: 100%;
}

.tabsContent {
  overflow-y: auto;
}

.addressWrapper {
  margin-bottom: 2em;

  & .title {
    color: var(--ink500);
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
  }

  & .address {
    display: flex;
    align-items: stretch;
    background: var(--white);
    box-shadow: 0 0 3px var(--black020);

    & .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 60px;
      width: 120px;
      height: 120px;
      color: var(--primaryText);
      background: var(--gray100);
    }

    & .addressInner {
      padding: 1em;
      display: flex;
      align-items: center;

      & .missing {
        color: var(--blockingRed);
      }
    }
  }
}
