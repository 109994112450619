.container {
  flex-grow: 1;
  overflow-y: auto;
  color: var(--darkInk200);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  order: 2;

  &::-webkit-scrollbar-thumb {
    background-color: var(--gray700);
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: var(--gray600);
  }
}

@media screen and (--max-width-md) {
  .container {
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    top: unset;
    left: unset;
    transform: unset;
  }
}
