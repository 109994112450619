.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  & .wrapper {
    display: flex;
    margin: auto;
    background: var(--white);
    & .main {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: var(--spacing2000);
      max-width: 480px;
      & .img {
        width: auto;
        height: 180px;
        margin-bottom: var(--spacing1500);
        &.fatImg {
          width: 200px;
          height: auto;
        }
      }
      & .title {
        color: var(--ink500);
        text-align: center;
      }
    }

    & .additional {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: var(--spacing2000);
      padding-left: var(--spacing2500);
      max-width: 480px;
      & .content {
        &:not(:last-child) {
          margin-bottom: var(--spacing1500);
        }
        &.centeredContent {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    &.withShadowWrapper {
      box-shadow: var(--shadow2);
    }

    &.transparentBg {
      background: transparent;
    }

    &.hasAdditional {
      & .main {
        padding-right: var(--spacing2500);
      }
    }

    &.columnDirectionWrapper {
      flex-direction: column;

      & .main {
        & .img {
          height: 160px;
        }
      }

      & .additional {
        padding-top: 0;
        padding-left: var(--spacing2000);
      }

      &.hasAdditional {
        & .main {
          padding-right: var(--spacing2000);
          padding-bottom: 0;
          margin-bottom: var(--spacing2000);
        }
      }
    }
  }
}

@media screen and (--max-width-xl) {
  .container {
    & .wrapper {
      & .main {
        max-width: 340px;
      }

      & .additional {
        max-width: 400px;
      }
    }
  }
}

@media screen and (--max-width-md) {
  .container {
    & .wrapper {
      flex-direction: column;
      padding: var(--spacing2000);
      max-width: 400px;
      & .main {
        padding: 0;
        max-width: unset;
        & .img {
          height: 120px;
          &.fatImg {
            width: 140px;
          }
        }
      }

      & .additional {
        padding: 0;
        max-width: unset;
        & .content.mobileCenteredContent {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      &.hasAdditional {
        & .main {
          padding-right: 0;
          margin-bottom: var(--spacing2000);
        }
      }

      &.columnDirectionWrapper {
        & .main {
          & .img {
            height: 100px;
          }
        }
        & .additional {
          padding-left: 0;
        }
        &.hasAdditional {
          & .main {
            padding-right: 0;
          }
        }
      }
    }
  }
}

@media screen and (--max-width-sm) {
  .container {
    & .wrapper {
      padding: var(--spacing1500) var(--spacing1000);
      max-width: unset;
      box-sizing: border-box;
      width: 100%;

      & .main {
        & .img {
          height: 100px;
          margin-bottom: var(--spacing1000);
          &.fatImg {
            width: 120px;
          }
        }
      }

      & .additional {
        & .content {
          &:not(:last-child) {
            margin-bottom: var(--spacing1000);
          }
        }
      }

      &.hasAdditional {
        & .main {
          margin-bottom: var(--spacing1500);
        }
      }

      &.columnDirectionWrapper {
        &.hasAdditional {
          & .main {
            margin-bottom: var(--spacing1500);
          }
        }
      }
    }
  }
}
