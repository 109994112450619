.list {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.item {
  position: relative;
  background: var(--white);
  overflow-y: hidden;
  cursor: pointer;

  &:nth-child(even) .itemContent {
    background: var(--gray100);
  }

  &.active .itemContent {
    background: var(--primaryBg);
    color: var(--textOnPrimaryBg);
    border-left-color: var(--primaryBg);
  }

  &:hover:not(.active) .itemContent {
    border-left-color: var(--primaryBg);
    border-top-color: var(--gray300);
    border-bottom-color: var(--gray300);
  }

  &:focus {
    outline: 1px dashed var(--ink600);
  }
}

.itemContent {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 70px;
  padding: 0 var(--spacing1000);
  border-left: 7px solid var(--white);
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  &.autoHeight {
    height: auto;
  }
}

.title {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 1em;
  font-size: 12px;
  font-weight: bold;
}

.subTitle {
  display: flex;
  align-items: center;
  gap: var(--spacing0250);
  font-weight: normal;
}

.flashBanner {
  height: 70px;
}

.loaderBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  background-color: var(--white);

  &:nth-child(even) {
    background-color: var(--gray100);
  }
}
