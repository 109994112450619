.container {
  box-sizing: border-box;
  min-width: 375px;
  overflow-y: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-grow: 1;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: var(--spacing3500);
  margin-bottom: var(--spacing3500);
}

.banner {
  height: auto;
}

.headline {
  margin-top: var(--spacing1000);
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: var(--spacing3500) var(--spacing1500);
  box-sizing: border-box;
  background-color: var(--gray200);
}

.main.withFlourish {
  position: relative;
}

.flourish {
  position: absolute;
  width: 100%;
  height: auto;
  left: 50%;
  bottom: 0;
  max-width: 1280px;
  transform: translateX(-50%);
}

.mainInner {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: 1.25rem;
  margin-bottom: var(--spacing1000);
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--spacing2000) var(--spacing2000) var(--spacing3500)
    var(--spacing2000);
}

.amexLogo {
  height: 40px;
}

.version {
  margin-top: var(--spacing2000);
  min-height: 20px;
}

.versionTooltip {
  text-align: center;

  & > p {
    margin: 0;
  }
}

.disclaimer,
.copyright {
  width: 100%;
  max-width: 800px;
}

.disclaimer {
  margin: var(--spacing2000) auto 0;
}

.copyright {
  margin: var(--spacing1000) auto 0;
}

@media screen and (--max-width-xl) {
  .flourish {
    max-width: 1024px;
  }
  .main {
    padding: var(--spacing1500);
  }
}

@media screen and (--max-width-lg) {
  .flourish {
    max-width: unset;
  }
  .disclaimer,
  .copyright {
    max-width: 768px;
  }
}

@media screen and (--max-width-md) {
  .disclaimer,
  .copyright {
    max-width: 450px;
  }
}

@media screen and (--max-width-sm) {
  .header {
    margin-top: var(--spacing2000);
    margin-bottom: var(--spacing2000);
  }

  .footer {
    padding: var(--spacing1500) var(--spacing1500) var(--spacing3500)
      var(--spacing1500);
  }

  .version {
    margin-top: var(--spacing1500);
  }

  .disclaimer,
  .copyright {
    max-width: 343px;
    margin-top: var(--spacing1500);
  }
}
