.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.separator {
  height: 0;
  margin: 0;
  border-top: none;
  border-bottom: 1px solid var(--gray300);
}
