.container {
  display: flex;
  align-items: center;
  gap: var(--spacing1000);
  padding: var(--spacing0750) var(--spacing1000);
  min-height: 60px;
  box-sizing: border-box;
  background-color: var(--brightBlue100);

  @media screen and (--max-width-sm) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.content {
  display: flex;
  gap: var(--spacing0500);
  flex-wrap: wrap;
}

.divider {
  width: 1px;
  height: 20px;
  margin: 0;
  border: none;
  background: var(--gray500);

  @media screen and (--max-width-sm) {
    display: none;
  }
}

.input {
  width: 240px;

  @media screen and (--max-width-sm) {
    width: 100%;
  }
}
