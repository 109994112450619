.header {
  height: 60px;
  display: flex;
  align-items: stretch;
  background: var(--gray100);
  padding-right: 1rem;
  border-bottom: 1px solid var(--gray300);
  box-sizing: border-box;

  & .date {
    color: var(--primaryText);
    background: var(--primaryBgLight);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 5rem;

    & .dateLabel {
      background: var(--primaryBg);
      color: var(--textOnPrimaryBg);
      text-align: center;
      width: 100%;
      font-size: 11px;
      font-weight: bold;
      margin-bottom: 2px;
      padding: 1px 0;
    }
  }

  & .label {
    flex: 1;
    margin: 0 1em;
    display: flex;
    align-items: center;
    & .labelText {
      font-weight: bold;
    }
  }
  & .status {
    display: flex;
    align-items: center;
    font-size: 12px;
  }
}

.rejectInfo:not(:last-child) {
  margin-bottom: 0.5em;
}

.infoBoxWrapper {
  & .infoBox {
    margin-top: 0 !important;
    margin-bottom: 0;
    padding-left: 2em;
    padding-right: 2em;
    padding-top: 1em;
    padding-bottom: 1em;
    &:not(:last-child) {
      margin-bottom: 0.5em;
    }
  }
}
