.modeBtns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: var(--spacing1000);
  gap: var(--spacing0500);
}

.button {
  &.selected {
    background: var(--gray300);
  }

  & [role='presentation'] {
    color: var(--ink400);
  }
}
