.container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--black050);
}
.box {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 340px;
  padding: var(--spacing2000);
  background-color: var(--white);
  color: var(--ink500);
  text-align: center;
  font-size: 1.075rem;
}

.title {
  margin-bottom: var(--spacing1500);
}

.content {
  white-space: pre-line;

  & > *:first-child {
    margin-top: 0;
  }

  & > *:last-child {
    margin-bottom: 0;
  }
}

.confirmButton {
  margin-top: var(--spacing2000);
}

.cancelButton {
  margin-top: var(--spacing0750);
}
