.wrapper {
  display: flex;
  height: 100%;
  overflow: hidden;

  &.grey {
    & .branding {
      background: var(--gray300);
      color: var(--ink600);
      & .header {
        & .logo {
          & * {
            fill: var(--primaryBg);
          }
        }
      }
      & .flourishWrapper {
        & .flourish {
          & * {
            fill: var(--gray400);
          }
        }
      }
    }
  }
  &.green {
    & .branding {
      background: green;

      & .header {
        & .logo {
          & * {
            fill: var(--primaryBg);
          }
        }
      }
      & .flourishWrapper {
        & .flourish {
          & * {
            fill: var(--gray400);
            display: none;
          }
        }
      }
    }
  }

  & .branding {
    background: var(--primaryBg);
    color: var(--textOnPrimaryBg);
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    text-align: center;
    width: 240px;

    & .header {
      padding: var(--spacing2000) var(--spacing3000) 0 var(--spacing3000);
      & .logo {
        margin: var(--spacing1500) 0;
        & * {
          fill: var(--textOnPrimaryBg);
        }
      }
    }

    & .sideContent {
      flex: 1 0;
      overflow-y: auto;
      overflow-x: hidden;
      box-sizing: border-box;
    }

    & .flourishWrapper {
      position: relative;
      width: 100%;
      height: 85px;
      overflow: hidden;
      & .flourish {
        position: absolute;
        bottom: 0;
        right: 0;
        height: 100%;
        width: auto;
        & * {
          fill: var(--textOnPrimaryBg);
        }
      }
    }

    &.dark {
      background: var(--gray800);
      color: var(--white);
      & .header {
        & .logo {
          & * {
            fill: var(--white);
          }
        }
      }
      & .flourish {
        & * {
          fill: var(--white);
        }
      }
    }

    &.withSideContent {
      width: auto;
    }
  }

  & .form {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    background: var(--white);
    padding: var(--spacing2500);
    overflow-x: hidden;
    &.noPadding {
      padding: 0;
    }
    & .headerWrapper {
      margin-bottom: var(--spacing2000);
      & .header {
        margin-bottom: var(--spacing1500);
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        & .title {
          text-align: center;
        }
      }
    }
  }
}

@media (--min-width-md) {
  .wrapper {
    & .branding {
      &.withSideContent {
        & .flourishWrapper {
          height: 100px;
        }
      }
    }
  }
}

@media (--max-width-md) {
  .wrapper {
    height: unset;
    flex-direction: column;
    overflow-y: auto;

    & .branding {
      padding-bottom: var(--spacing4000);
      width: auto;
      & .header {
        & .logo {
          margin: var(--spacing2000) 0 var(--spacing1000) 0;
        }
      }
      & .sideContent {
        display: none;
      }
      & .flourishWrapper {
        height: 140px;
        position: absolute;
        bottom: 0;
        & .flourish {
          right: var(--spacing2000);
        }
      }
    }
    & .form {
      overflow-y: scroll;
      width: 100%;
      box-sizing: border-box;
      padding: var(--spacing1500) var(--spacing2000);
      & .headerWrapper {
        margin-bottom: var(--spacing1500);
        & .header {
          margin-bottom: var(--spacing1000);
        }
      }
    }
  }
}

@media (--max-width-sm) {
  .wrapper {
    & .branding {
      padding-bottom: var(--spacing3000);
      & .header {
        & .logo {
          margin: var(--spacing1500) 0 var(--spacing1000) 0;
        }
      }
      & .flourishWrapper {
        height: 100px;
        & .flourish {
          right: var(--spacing1000);
        }
      }
    }

    & .form {
      padding: var(--spacing1500) var(--spacing2000);
    }
  }
}

@media screen and (max-width: 425px) {
  .wrapper {
    & .branding {
      padding-bottom: var(--spacing2000);
      & .flourishWrapper {
        height: 70px;
        & .flourish {
          right: 0;
        }
      }
    }
  }
}
