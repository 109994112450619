.tabContainer {
  flex: 1 0;
  overflow: hidden;
  box-shadow: none;
}

.tabContent {
  overflow-x: hidden;
  overflow-y: auto;
}
