.container {
  display: flex;
  align-items: center;
  justify-content: left;
  margin: var(--spacing0750) 0;
  gap: var(--spacing0750);
}

.predicate {
  padding: var(--spacing0500);
  width: 40px;
}
@media screen and (--max-width-sm) {
  .container {
    flex-direction: column;
    & button {
      width: 200px;
    }
  }
}
