.container {
  flex-grow: 1;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing1500) var(--spacing1000);
  max-height: 100%;
  box-sizing: border-box;
}

.text {
  width: 100%;
  text-align: center;
  color: var(--ink600);
}

.image {
  margin-bottom: var(--spacing1000);
  flex: 1;
}
.customSizedImage {
  width: 180px;
}

@media screen and (--max-width-xl) {
  .customSizedImage {
    width: 120px;
  }
}

@media screen and (--max-width-md) {
  .customSizedImage {
    width: 180px;
  }
  .container {
    padding: var(--spacing1000) 0;
  }
}
@media screen and (--max-width-sm) {
  .customSizedImage {
    width: 100px;
  }
}
