.modal {
  height: 350px;
  min-width: 400px;
  width: 400px;
}

.banner {
  position: fixed;
  top: 0px;
  width: 100%;
  height: var(--app-nav-size);
  background-color: var(--primaryBg);
  text-align: center;
  z-index: 6;
}

.infoWrapper {
  background-color: var(--primaryBg);
  color: var(--textOnPrimaryBg);
}

.iconWrapper {
  display: flex;
  justify-content: center;
  margin: 20px;
  & .icon {
    font-size: 30px;
  }
}
@media screen and (--max-width-md) {
  .modal {
    height: 100%;
    min-width: 100%;
    width: 100%;
  }
}
@media screen and (--max-width-sm) {
  .banner {
    height: 250px;
  }
}
