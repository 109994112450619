.container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 2em;
  border-radius: 2px;
  background: var(--gray300);
  margin-bottom: 3em;
}

.title {
  margin-top: 1em;
  margin-bottom: 0.4em;
  margin-left: 0.2em;
}

.image {
  font-size: 5em;
  margin-right: 0.5em;
}

.descriptionContainer {
  display: flex;
  flex-direction: column;
}

.descriptionList {
  margin: 0 0 1em;
  padding: 0;
}

.descriptionTitle {
  margin-bottom: 0.2em;
  display: list-item;
  list-style-type: disc;
}

.descriptionValue,
.lineDetailsListItem {
  color: var(--ink500);
  margin: 0;
}

.lineDetailsList {
  padding: 0;
  margin: 0;
}

.lineDetailsListItem {
  list-style: none;
}
