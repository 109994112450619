.container {
  text-align: center;
  & .icon {
    display: block;
    font-size: 4rem;
  }

  & .message {
    margin: 2rem 0;
  }
}
