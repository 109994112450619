.container {
  margin: 0 3px;
  width: 300px;
}

.inputValue {
  width: 200px;
  flex: none;
}

@media screen and (--max-width-sm) {
  .container {
    width: 200px;
  }
}
