.container {
  padding: 3.5rem 0 3em;
  display: flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
}

.radioGroupsContainer {
  padding-right: 1.5rem;
}

@media screen and (--max-width-md) {
  .container {
    padding: 1rem 0 1.5rem;
  }
}

@media screen and (height < 600px) {
  .container {
    padding: 0.9rem 0 1.1rem;
  }
}
