.container {
  flex: 1;
  position: relative;
  transition: all 200ms;
  justify-content: stretch;
  display: flex;
  flex-direction: column;
}

.container h3 {
  margin-top: 0;
}

.list {
  display: flex;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  min-width: 720px;
  padding-top: 1.5em;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: var(--gray300);
  background-color: var(--gray300);
}

.item {
  border-bottom: 4px solid transparent;
  display: flex;
  align-items: center;
  margin: 0 10px;
  padding: 1em;
  cursor: pointer;
  border-radius: 2px;
  transition: background-color 200ms;
  text-align: left;
}

.item:first-child {
  margin-left: 20px;
}

.selected {
  color: var(--primaryText);
  border-color: var(--primaryText);
}

.item.selected,
.item:hover {
  font-weight: bold;
}

.itemTitle {
  flex: 1;
}

.hide {
  display: none;
}

.content {
  transition: all 200ms;
  overflow-y: auto;
  opacity: 0;
  width: 100%;
  box-sizing: border-box;
  opacity: 1;
  flex: 1;
}

.withFieldSelected .content {
  padding: 20px;
}

@media screen and (--max-width-sm) {
  .container {
    height: 100%;
  }

  .list {
    min-width: 0;
    padding: 0;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .item {
    flex: 1 1 0;
    margin: 0;
    border-bottom: none;
    border-top: 4px solid transparent;
    text-align: center;

    &:first-child {
      margin: 0;
    }
  }
}
