.container {
  display: flex;
  gap: var(--spacing1000);
  margin: var(--spacing0750);
  flex-direction: column;
}

.radioButton {
  flex-wrap: nowrap;
  display: flex;
}
.select {
  width: 240px;
}

.failedRecordMessage {
  padding-bottom: var(--spacing1000);
  color: var(--blockingRed);
}
