:global(#freshworks-frame-wrapper) {
  z-index: 1 !important;
  bottom: 60px !important;
}

:global(#launcher-frame) {
  z-index: 1 !important;
  bottom: 0 !important;
  right: 15px !important;
}

@media screen and (--max-width-sm) {
  :global(#launcher-frame) {
    left: 10px !important;
    right: auto !important;
  }
}
