.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: hidden;
  margin-top: 1rem;
  padding: var(--spacing0250);
}

.btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  justify-content: space-between;
}

.startBtn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.ownerViewWrapper {
  flex: 1 0;
  overflow-y: auto;
}
