.container {
  width: 100%;
}

.homeAddressPlaceholer {
  white-space: normal;
  display: flex;
  align-items: center;
  height: 100%;
}

.emptyContentTitle {
  font-weight: bold;
}
