.advancedWrapper {
  display: flex;
  flex-direction: column;
  border: 2px solid var(--gray300);
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  & .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0.5rem;
    background: var(--gray300);
    flex-wrap: wrap;
    flex-shrink: 0;
    & .pagination {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: var(--spacing0750);
    }
    & .zoom {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: var(--spacing0500);
    }
  }
  & .page {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex: 1 0;
    & .zoom {
      height: 100%;
    }
  }
}
