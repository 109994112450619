.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing0250);
}

.amountsWrapper {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  overflow: hidden;
}

.amountItem {
  font-size: 0.875rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  & .amount {
    font-weight: bold;
  }
}

.spentAmount {
  flex: 1;
  margin-right: 1em;
}

.requestedAmount {
  flex: 1;
  text-align: right;
}

.remainingAmount {
  text-align: right;
}

.progressBar {
  background-color: var(--gray300);
  border-radius: 5px;
}
