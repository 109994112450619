.description {
  display: flex;
  flex-direction: row;
  &:not(:last-child) {
    margin-bottom: var(--spacing0125);
  }
}

.value {
  margin-left: var(--spacing0250);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.actionsWrapper {
  margin-top: var(--spacing0500);
  margin-bottom: var(--spacing0500);
}
