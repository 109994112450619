.stepsWrapper {
  & .step {
    margin: var(--spacing1500) 0;
    & .title {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      padding: var(--spacing0500) 0;

      border-bottom: 1px solid var(--gray300);
      & .label {
        flex: 1 0;
        white-space: nowrap;
        overflow: hidden;
        display: flex;
        margin-right: var(--spacing1000);
        & .text {
          overflow: hidden;
          text-overflow: ellipsis;
          margin-right: var(--spacing0250);
        }
      }
      & .btnWrapper {
        margin-left: var(--spacing0500);
      }
    }
    & .content {
      padding-top: var(--spacing1000);
    }
  }
}
