.warning {
  display: flex;
  align-items: center;
  gap: var(--spacing0500);
  flex-grow: 1;
}

.warningIcon {
  color: var(--alertOrange);
  font-size: var(--iconS);
}

.scopeSelectorWrapper {
  @media screen and (--max-width-md) {
    min-width: 240px;
  }
  @media screen and (--max-width-sm) {
    min-width: 160px;
  }
}
