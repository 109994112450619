/* workaround fix for pdfjs hiddenCanvasElement cause black screen issue */
/* to be trashed once a newer version of react-pdf is released */
/* https://github.com/wojtekmaj/react-pdf/issues/1815 */
:global(.hiddenCanvasElement) {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  display: none;
}

.container {
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
  & :global(.react-pdf__Document) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: 100%;
    & :global(.react-pdf__Page) {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      & :global(.react-pdf__Page__textContent),
      & :global(.react-pdf__Page__annotations) {
        display: none;
      }
      & :global(.react-pdf__Page__canvas) {
        width: auto !important;
        height: auto !important;
        max-width: 100%;
        max-height: 100%;
      }
      &:not(:last-child) {
        & :global(.react-pdf__Page__canvas) {
          margin-bottom: 1em;
        }
      }
    }
  }
}
