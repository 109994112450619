.container {
  margin-bottom: var(--spacing1000);
  display: flex;
  justify-content: flex-end;
  & .inner {
    width: 240px;
  }
}
@media screen and (--max-width-sm) {
  .container {
    & .inner {
      width: 100%;
    }
  }
}
