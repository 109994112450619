.container {
  &:not(:last-child) {
    margin-bottom: var(--spacing1000);
    padding-bottom: var(--spacing1000);
    border-bottom: 1px solid var(--gray300);
  }
  & .title {
    margin-bottom: var(--spacing1000);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
