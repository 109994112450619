.container {
  padding: var(--spacing1000);
  border: 1px solid var(--gray300);
  background-color: var(--gray200);
  margin-top: var(--spacing0750);
  margin-bottom: var(--spacing0750);
  display: flex;
  gap: var(--spacing1000);
  @media (--max-width-sm) {
    flex-direction: column;
  }
  & .infoTitle {
    margin-bottom: var(--spacing1000);
  }
  & .infoDescription {
    margin-bottom: var(--spacing0500);
  }
  & .infoExtra {
    color: var(--ink500);
  }
  & .exampleImg {
    flex-shrink: 0;
  }
}
