.formTitle,
.formDescription {
  color: var(--ink500);
}

.formTitle {
  margin-bottom: var(--spacing1000);
}

.formDescription {
  margin: var(--spacing1000) 0 var(--spacing2000);
}

.noTaxesMsg {
  text-align: center;
  margin-bottom: var(--spacing2000);
}
