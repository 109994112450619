.list {
  margin: 0;
  padding: 1em;
  list-style: none;
  text-align: left;

  & > li {
    line-height: 1.5;
  }
}

.successIcon {
  color: var(--progressGreen);
  margin-right: 0.5em;
}

.alertIcon {
  color: var(--blockingRed);
  margin-right: 0.5em;
}

.warningIcon {
  color: var(--alertOrange);
  margin-right: 0.5em;
}

.invalid {
  color: var(--blockingRed);
}

.valid {
  color: var(--progressGreen);
}
