.container {
  color: var(--white);
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: var(--black050);
  display: flex;
  justify-content: center;
  align-items: center;
}

.inner {
  max-width: 290px;
}

.title {
  font-size: 2.3em;
  margin-bottom: 1em;
  font-weight: bold;
}

.bold {
  font-weight: bold;
}

.text {
  font-size: 1em;
  margin-bottom: 2em;
}
