/* TODO, very old typography styles, to be trashed */

.normal {
  font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

h1,
.text1 {
  font-size: 2.375rem;
}

h2,
.text2 {
  font-size: 1.875rem;
}

h3,
.text3 {
  font-size: 1.25rem;
}

h4,
.text4 {
  font-size: 1rem;
}

h5,
.text5 {
  font-size: 0.9375rem;
}

h6,
.text6 {
  font-size: 0.813rem;
}
