.predefinedBtn {
  text-align: left;
  height: 40px;
  cursor: pointer;
  width: 100%;
  margin: 0;
  color: var(--primaryText);
  padding: 0 20px;
  background: none;

  &:hover {
    text-decoration: underline;
  }
}

.predefinedBtn span {
  margin-left: 4px !important;
}

.reportBuilderLink {
  width: 100%;
  margin-bottom: 1rem;
}

.icon {
  font-size: var(--iconXS);
}

@media screen and (--max-width-sm) {
  .buttonWrapper {
    padding-right: 18px;
    padding-left: var(--spacing0750);
  }
}
