:root {
  --fontWeightNormal: 400;
  --fontWeightSemibold: 600;
  --fontWeightBold: 700;

  --header1FontSize: 24px;
  --header1LineHeight: 34px;
  --header2FontSize: 24px;
  --header2LineHeight: 34px;
  --header3FontSize: 18px;
  --header3LineHeight: 26px;
  --header4FontSize: 16px;
  --header4LineHeight: 24px;

  --textLFontSize: 16px;
  --textLLineHeight: 24px;
  --textMFontSize: 14px;
  --textMLineHeight: 20px;
  --textSFontSize: 12px;
  --textSLineHeight: 18px;
  --textXSFontSize: 11px;
  --textXSLineHeight: 16px;

  --amountLFontSize: 24px;
  --amountLLineHeight: 34px;
  --amountMFontSize: 18px;
  --amountMLineHeight: 26px;
  --amountSFontSize: 14px;
  --amountSLineHeight: 20px;
}

:global(.header1) {
  font-size: var(--header1FontSize);
  line-height: var(--header1LineHeight);
  font-weight: var(--fontWeightSemibold);
}
:global(.header2) {
  font-size: var(--header2FontSize);
  line-height: var(--header2LineHeight);
  font-weight: var(--fontWeightBold);
}
:global(.header3) {
  font-size: var(--header3FontSize);
  line-height: var(--header3LineHeight);
  font-weight: var(--fontWeightBold);
}
:global(.header4) {
  font-size: var(--header4FontSize);
  line-height: var(--header4LineHeight);
  font-weight: var(--fontWeightBold);
}

:global(.textL) {
  font-size: var(--textLFontSize);
  line-height: var(--textLLineHeight);
  font-weight: var(--fontWeightNormal);
}
:global(.textLSemibold) {
  font-size: var(--textLFontSize);
  line-height: var(--textLLineHeight);
  font-weight: var(--fontWeightSemibold);
}
:global(.textM) {
  font-size: var(--textMFontSize);
  line-height: var(--textMLineHeight);
  font-weight: var(--fontWeightNormal);
}
:global(.textMSemibold) {
  font-size: var(--textMFontSize);
  line-height: var(--textMLineHeight);
  font-weight: var(--fontWeightSemibold);
}
:global(.textMBold) {
  font-size: var(--textMFontSize);
  line-height: var(--textMLineHeight);
  font-weight: var(--fontWeightBold);
}
:global(.textMCapSemibold) {
  font-size: var(--textMFontSize);
  line-height: var(--textMLineHeight);
  font-weight: var(--fontWeightSemibold);
  text-transform: uppercase;
}
:global(.textS) {
  font-size: var(--textSFontSize);
  line-height: var(--textSLineHeight);
  font-weight: var(--fontWeightNormal);
}
:global(.textSSemibold) {
  font-size: var(--textSFontSize);
  line-height: var(--textSLineHeight);
  font-weight: var(--fontWeightSemibold);
}
:global(.textSBold) {
  font-size: var(--textSFontSize);
  line-height: var(--textSLineHeight);
  font-weight: var(--fontWeightBold);
}
:global(.textSCapSemibold) {
  font-size: var(--textSFontSize);
  line-height: var(--textSLineHeight);
  font-weight: var(--fontWeightSemibold);
  text-transform: uppercase;
}
:global(.textXS) {
  font-size: var(--textXSFontSize);
  line-height: var(--textXSLineHeight);
  font-weight: var(--fontWeightNormal);
}
:global(.textXSSemibold) {
  font-size: var(--textXSFontSize);
  line-height: var(--textXSLineHeight);
  font-weight: var(--fontWeightSemibold);
}

:global(.amountL) {
  font-size: var(--amountLFontSize);
  line-height: var(--amountLLineHeight);
  font-weight: var(--fontWeightBold);
}
:global(.amountM) {
  font-size: var(--amountMFontSize);
  line-height: var(--amountMLineHeight);
  font-weight: var(--fontWeightBold);
}
:global(.amountS) {
  font-size: var(--amountSFontSize);
  line-height: var(--amountSLineHeight);
  font-weight: var(--fontWeightBold);
}
