.transition {
  transition: all 200ms;
}

.overflowEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.clickable {
  cursor: pointer;
}

.block {
  composes: transition;
  position: relative;
  height: 100%;
  align-self: center;
  text-align: center;
  width: 100%;
}

.blockInner {
  composes: transition;
  height: 100%;
  background-color: var(--white);
  width: 100%;
  display: flex;
  flex-direction: column;
}

.badge {
  position: absolute;
  top: -6px;
  right: -12px;
  font-style: normal;
}

.header {
  composes: transition;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: var(--gray300);
  position: relative;
  padding: var(--spacing0500) 0;
}

.icon {
  background-color: transparent;
  position: relative;
  font-size: 24px;
  margin-bottom: var(--spacing0125);
}

.arrowWrapper {
  width: 40%;
  position: absolute;
  top: 50%;
  left: -20%;
  transform: translate(0%, -50%);
  z-index: 1;
}

.contents {
  position: relative;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin: var(--spacing0750);
}

.defaultContentsInner {
  flex: 1;
  overflow: hidden;
  display: flex;
}

.generalLabel {
  padding: 0 VAR(--spacing0750);
}

.content {
  flex: 1;
  overflow: hidden;
  padding: 0 var(--spacing0750);
  padding-top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.content:not(:first-child) {
  border-left: 1px solid var(--gray400);
}

.subTitle {
  composes: overflowEllipsis;
  composes: transition;
  color: var(--ink500);
  margin: var(--spacing0500) 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.valueWrapper {
  composes: overflowEllipsis;
  composes: transition;
  margin: 0;
  white-space: nowrap;
}

.label {
  composes: overflowEllipsis;
  display: block;
}

.actionOverlay {
  composes: transition;
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: 0;
  left: 0;
  right: 0;
  padding: var(--spacing0250);
  height: 36px;
  transform: translate(0, 40px);
}

.btnGo {
  width: 100%;
}

.block:first-child .arrowWrapper {
  display: none;
}

/* clickable block hover */
.clickable.block:hover .subTitle {
  transform: translate(0, 20px);
  opacity: 0;
}

.clickable.block:hover .amount {
  opacity: 1;
}

.clickable.block:hover .smallContent .subTitle {
  transform: translate(0, 15px);
}

.clickable.block:hover .actionOverlay {
  transform: translate(0, 0);
}

.block.disabled {
  & .header {
    background: var(--gray300);
    & .icon,
    & .title {
      color: var(--ink400);
    }
  }
}

@media screen and (--max-width-md) {
  .arrowWrapper {
    display: none;
  }
}
