.txsToBeSubmitted {
  font-weight: bold;
}

.modalContent {
  display: flex;
  flex-direction: column;
}

.informationMissing {
  font-weight: bold;
}
