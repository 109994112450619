.modalContainer {
  width: 70%;
  height: 80%;
}

.contentContainer {
  background: var(--gray200);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
}

.errorInfosSubtitle {
  margin-top: 0;
  font-weight: 700;
}

.errorInfosSeparator {
  margin: 2.5em 0;
}

.errorInfosList {
  padding: 1em;
  list-style: none;
}

.errorInfosListItem {
  margin: 1em 0;
}

.errorInfosListItem::before {
  content: '\2022';
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.extractSuccess.errorInfosListItem::before {
  color: var(--primaryText);
}

.extractError.errorInfosListItem::before {
  color: var(--blockingRed);
}

.extractSuccess {
  color: var(--primaryText);
}

.extractError {
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--blockingRed);
}

.extractCount {
  font-weight: 700;
  color: inherit;
}

.extraSum {
  color: inherit;
}

.resetColor {
  color: var(--ink600);
}
