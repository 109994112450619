:global(.category-host-list-handler),
:global(#accept-recommended-btn-handler),
:global(#filter-apply-handler),
:global(#filter-cancel-handler),
:global(.ot-pc-refuse-all-handler),
:global(#onetrust-accept-btn-handler),
:global(.save-preference-btn-handler),
:global(#filter-btn-handler),
:global(#onetrust-pc-btn-handler),
:global(#onetrust-reject-all-handler) {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  min-width: 64px !important;
  max-width: none !important;
  height: 48px !important;
  min-height: 0 !important;
  margin: 0 !important;
  padding: 0 var(--spacing0750) !important;
  line-height: var(--spacing1500) !important;
  font-size: 16px !important;
  letter-spacing: normal !important;
  font-weight: 600 !important;
  border: 1px solid !important;
  border-radius: 4px !important;
  transition: background-color 0.25s ease-out !important;

  &:focus {
    outline: 2px solid var(--primaryText) !important;
    outline-offset: 2px !important;
    opacity: 1 !important;
  }

  &:hover {
    opacity: 1 !important;
    text-decoration: none !important;
  }
}

:global(#accept-recommended-btn-handler),
:global(#filter-apply-handler),
:global(#onetrust-accept-btn-handler) {
  background-color: var(--primaryBg) !important;
  border-color: var(--primaryBg) !important;
  color: var(--white) !important;

  &:hover {
    background-color: var(--primaryBgHover) !important;
    border-color: var(--primaryBgHover) !important;
  }
}

:global(.save-preference-btn-handler),
:global(#filter-btn-handler),
:global(#onetrust-pc-btn-handler),
:global(#filter-cancel-handler),
:global(.ot-pc-refuse-all-handler),
:global(#onetrust-reject-all-handler) {
  background-color: var(--white) !important;
  border-color: var(--primaryBg) !important;
  color: var(--primaryBg) !important;

  &:hover {
    background-color: var(--primaryBgLight) !important;
  }
}

:global(button.ot-link-btn.category-host-list-handler),
:global(a.privacy-notice-link) {
  display: inline !important;
  border: unset !important;
  padding: unset !important;
  height: fit-content !important;
  white-space: nowrap !important;
  color: var(--primaryText) !important;
  font-size: var(--textMFontSize) !important;
  line-height: var(--textMLineHeight) !important;
  font-weight: var(--fontWeightNormal) !important;
  &:hover {
    text-decoration: underline !important;
  }
  &:focus {
    outline: var(--spacing0125) solid !important;
    outline-offset: var(--spacing0250) !important;
    border-radius: 1px !important;
    outline-color: var(--brightBlue500) !important;
  }
}

:global(#filter-btn-handler) {
  padding: 0 !important;
  min-width: 0 !important;
  width: 32px !important;
  height: 32px !important;
  border-color: transparent !important;
}

:global(#onetrust-button-group-parent) {
  padding-top: var(--spacing0750) !important;
  padding-bottom: var(--spacing0750) !important;
}

:global(#filter-btn-handler svg path) {
  fill: var(--primaryBg) !important;
}

:global(#onetrust-button-group) {
  display: block !important;
}

:global(.banner-actions-container) {
  display: flex !important;
  gap: var(--spacing0750) !important;
  margin-left: var(--spacing0750) !important;
}

:global(.ot-btn-container) {
  display: flex !important;
  justify-content: space-between !important;
  gap: var(--spacing0750) !important;
  padding: var(--spacing0750) !important;
}

:global(.ot-pc-logo) {
  width: auto !important;
  height: auto !important;
  margin: 0 20px !important;
  & img {
    width: 64px !important;
    height: 64px !important;
  }
}

:global(.ot-title-cntr) {
  width: calc(100% - 105px) !important;
  padding-left: 0 !important;
}

:global(#ot-pc-title) {
  max-width: none !important;
  margin-left: 0 !important;
  padding-right: 40px !important;
}

:global(.ot-btn-subcntr) {
  display: flex !important;
  gap: var(--spacing0750) !important;
}

:global(.ot-floating-button) {
  display: none !important;
}

:global(.ot-close-icon) {
  outline: none;
  transition: background-color 0.25s ease-out !important;

  &:focus {
    outline: 2px solid var(--primaryText) !important;
    opacity: 1 !important;
  }

  &:hover {
    background-color: var(--primaryBgLight) !important;
  }
}

:global(.category-menu-switch-handler) {
  & h3  {
    font-size: var(--textMFontSize) !important;
  }

  &:focus {
    outline: 2px solid var(--primaryText) !important;
    outline-offset: -2px !important;
  }
}

:global(.ot-close-cntr) {
  right: 0 !important;
  & button {
    display: block !important;
  }
}

:global(.ot-pc-footer-logo) {
  width: auto !important;
  height: auto !important;
  padding: var(--spacing0750) !important;

  & a {
    margin: 0 !important;
    width: auto !important;
    height: auto !important;

    &:focus {
      outline: 2px solid var(--primaryText) !important;
      outline-offset: 2px !important;
    }

    & img {
      display: block !important;
    }
  }
}

:global(.ot-desc-cntr:focus) {
  outline: 2px solid var(--primaryText) !important;
  outline-offset: -2px !important;
}

:global(.ot-fltr-btns) {
  display: flex !important;
  flex-direction: row-reverse !important;
  gap: var(--spacing0750) !important;
  margin: var(--spacing0750) 0 !important;
}

:global(.ot-fltr-opt) {
  margin-left: var(--spacing0750) !important;
}

@media (--max-width-sm) {
  :global(.ot-btn-container) {
    flex-direction: column-reverse !important;
  }
  :global(.ot-btn-subcntr) {
    max-width: unset !important;
    width: 100% !important;
  }
  :global(.save-preference-btn-handler) {
    width: 100% !important;
  }
  :global(#accept-recommended-btn-handler) {
    width: 100% !important;
  }
  :global(#onetrust-pc-btn-handler) {
    width: 100% !important;
  }
  :global(.banner-actions-container) {
    width: 100% !important;
    max-width: unset !important;
    flex-direction: column-reverse;
    margin-bottom: var(--spacing0750) !important;
  }
}
