.centeredSpinner {
  align-self: center;
}

.form {
  padding: 3em 2em;
}

.actionWrapper {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}
