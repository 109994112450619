.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing0500);
  margin-bottom: var(--spacing1000);
}

.footer {
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing0750);
  margin-top: var(--spacing1000);
}

.label {
  display: block;
  margin-bottom: var(--spacing0250);
}

.value {
  display: flex;
  align-items: center;
  gap: var(--spacing0250);
  font-weight: bold;
}

.newTag {
  display: flex;
  align-items: center;
  height: 16px;
  padding: 0 var(--spacing0250);
  border-radius: 9999px;
  background-color: var(--primaryBg);
  color: var(--textOnPrimaryBg);
  font-size: 0.785rem;
  font-weight: 600;
  text-transform: uppercase;
}
