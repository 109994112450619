.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  & .header {
    display: flex;
    align-items: center;
    margin-bottom: var(--spacing1000);
    gap: var(--spacing1000);
    & .title {
      flex: 1 0;
    }
  }

  & .content {
    flex: 1 0;
    & .freeTextEmpty {
      color: var(--ink500);
    }
  }
}
