.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.contentLeft {
  padding: var(--spacing1000) var(--spacing2000);
}

.tabsContainer {
  height: 100%;
}

.headerLeft {
  padding: 0 var(--spacing2000);
  display: flex;
  align-items: center;
  gap: var(--spacing1000);
}

.actions {
  display: flex;
  align-items: center;
  padding: 0 var(--spacing1500);
  gap: var(--spacing0750);
}

@media screen and (--max-width-md) {
  .tabsContent {
    min-height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
