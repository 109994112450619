.item {
  margin-bottom: 1em;
}

.expenseCategory {
  background-color: var(--brightBlue100);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
}

.largerMargin {
  margin-left: 2rem;
}

.smallerMargin {
  margin-left: 1rem;
}

.itemNumber {
  color: var(--primaryText);
  font-size: 1.5rem;
  font-weight: bold;
  background-color: var(--brightBlue200);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
}

.distribution {
  border-left: 15px solid var(--brightBlue100);
}

.tax,
.amount {
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.tax {
  background-color: var(--gray200);
}

.amount {
  background-color: var(--brightBlue200);
}

.amountDescription {
  font-weight: normal;
  color: var(--ink400);
}

.taxTitle {
  font-weight: normal;
}

.arrow {
  width: 0;
  position: absolute;
  left: -2em;
  top: 50%;
  transform: translateY(-50%);
}

.arrowUp {
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left-style: solid;
  border-left-width: 8px;
  border-left-color: var(--primaryText);
}

.arrowDown {
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top-style: solid;
  border-top-width: 8px;
  border-top-color: var(--primaryText);
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
}

.numberOfRecipients,
.parenthese {
  display: inline-block;
  vertical-align: middle;
}

.recipientsList {
  display: inline-block;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
}

.expenseTitle {
  align-items: flex-start;
  display: flex;
}

.mileageInput,
.attendeesInput,
.expenseInput {
  width: 50%;
}

.tooltipAttendees {
  max-width: 350px;
}

.rightWrapper {
  display: flex;
  align-items: center;
  gap: var(--spacing1000);
}
