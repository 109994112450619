.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  transition: opacity 300ms ease-in-out;
  z-index: 5;
}

.overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  background: var(--black);
  opacity: 0.7;
  width: inherit;
  height: inherit;
}
