.container {
  background-color: var(--white);

  & td {
    text-align: center;
    font-weight: normal;

    &.emptyCell {
      background-color: var(--gray200);
    }
  }
}

.headerLine {
  height: 64px;
  background-color: var(--gray700);
  color: var(--white);

  & th {
    padding: var(--spacing0250) var(--spacing0500);
    color: var(--white);
    font-weight: inherit;

    &.statusCell {
      position: relative;
      min-width: 150px;
      background-color: var(--primaryBg);
      white-space: nowrap;

      @media screen and (--max-width-md) {
        min-width: 90px;
      }
    }

    &.withLeftBorder::after {
      content: '';
      position: absolute;
      left: 0;
      width: 1px;
      top: 0;
      bottom: 0;
      background-color: var(--white);

      @media screen and (--max-width-md) {
        top: var(--spacing0500);
        bottom: var(--spacing0500);
      }
    }
  }
}

.titleLine {
  height: 32px;

  & td {
    padding: var(--spacing0250) var(--spacing0500);
  }
}

.amountLine {
  height: 64px;

  & td {
    padding: var(--spacing0250) var(--spacing0500);
  }
}

.withShadowBefore,
.withShadowAfter {
  position: relative;

  &.withShadowBefore::before,
  &.withShadowAfter::after {
    position: absolute;
    content: '';
    height: 100%;
    top: 0;
    width: 6px;
  }

  &.withShadowBefore::before {
    left: -6px;
    box-shadow: inset -6px 0 8px -10px var(--ink600);
  }

  &.withShadowAfter::after {
    right: -6px;
    box-shadow: inset 6px 0 8px -10px var(--ink600);
  }
}

.headerAmount {
  color: var(--ink600);
}

.headerSum {
  color: var(--primaryText);
}
