.inputReadOnly {
  display: flex;
  flex-direction: column;
}

.inputLabel {
  margin: 0;
}

.inputValue {
  margin: 0.5rem 0;
  font-weight: 700;
}

.inputValueMissing {
  font-weight: 400;
  color: var(--ink400);
}
