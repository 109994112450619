.container {
  position: relative;
  & .badge {
    position: absolute;
    top: 0;
    right: 0;
    background: var(--progressGreen);
  }
}
@media screen and (--max-width-sm) {
  .container {
    & .floating {
      position: fixed;
      bottom: var(--spacing0750);
      right: var(--spacing0750);
    }
  }
}
