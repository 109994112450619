.infoMarginBottom {
  margin-bottom: var(--spacing1500);
}

.toggle {
  margin: var(--spacing1500) 0;
}

.separator {
  border-bottom: 1px solid var(--gray300);
  margin-bottom: var(--spacing1000);
}

.legend {
  margin-bottom: var(--spacing1000);
}

@media screen and (--min-width-md) {
  .modalContent {
    width: 650px;
    box-sizing: border-box;
  }
}
