.container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.icon {
  width: 5px;
  height: 5px;
  border: 1px solid var(--primaryText);
  border-radius: 50%;
  animation: beat 0.25s infinite alternate;
  transform-origin: center;
}

.label {
  margin: 0;
  font-weight: 600;
}

@keyframes beat {
  to {
    transform: scale(2.5);
  }
}
