:global(.chartTooltip) {
  position: absolute;
  padding: 0.5rem 1rem;
  opacity: 0;
  border-radius: 0.25rem;
  background: var(--gray700);
  color: var(--white);
  font-size: 0.9rem;
  transition: all 300ms ease-out;
  z-index: 3;
  pointer-events: none;
}

:global(.chartTooltipTitle) {
  margin: 0.5em 0;
  font-weight: 700;
}

:global(.chartTooltipContent) {
  margin: 0.5em 0;
}
