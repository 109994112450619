.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: var(--spacing1000);
  white-space: nowrap;
  & > * {
    flex: 1 0;
    margin-bottom: 0;
  }
}
