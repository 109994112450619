.groupContainer {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: var(--spacing1000);
  gap: var(--spacing1000);
}

div.groupContainer > * {
  flex: 1;
  margin-bottom: 0;
  max-width: 100%;
}

@media only screen and (--max-width-sm) {
  .groupContainer {
    flex-direction: column;
  }

  div.groupContainer > * {
    width: 100%;
  }
}
