.container {
  padding-bottom: var(--spacing1000);
  & .group {
    padding: var(--spacing1000) var(--spacing1500);
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing1000);
    &:not(:last-child) {
      border-bottom: 1px solid var(--gray300);
    }
    & .detail {
      flex: 0 0 100%;
      &.halfWidth {
        flex: 0 0 calc(50% - var(--spacing1000));
      }
    }
  }
}

@media screen and (--max-width-md) {
  .container {
    padding-bottom: 0;
    & .group {
      padding: var(--spacing0750);
      gap: var(--spacing0750);
    }
  }
}
