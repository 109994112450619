.container {
  display: flex;
  align-items: center;
  gap: var(--spacing1000);
  width: 100%;
  padding: var(--spacing1000) 0;
  & .info {
    flex: 1 0;
  }
}
