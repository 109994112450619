.container {
  display: flex;
  flex-direction: column;

  & .operatorLine {
    display: flex;
    align-items: flex-start;
    gap: var(--spacing0500);
    margin-bottom: var(--spacing0500);

    & .wording {
      /* dirty hack to better align the wordings with the other fields */
      margin-top: var(--spacing0500);
    }

    & .operatorField {
      width: 200px;
      margin-bottom: 0;
    }

    & .scopeField {
      flex: 1 0;
      margin-bottom: 0;
    }
  }

  & .divider {
    border-bottom: 1px solid var(--gray500);
    margin: var(--spacing0500) 0 var(--spacing1000) 0;
  }

  & .countryLinesWrapper {
    display: flex;
    flex-direction: column;
    gap: var(--spacing1000);
    margin-bottom: var(--spacing0500);
    padding-left: var(--spacing1500);

    & .countryLine {
      display: flex;
      align-items: flex-start;
      gap: var(--spacing0500);

      & .wording {
        /* dirty hack to better align the wordings with the other fields */
        margin-top: var(--spacing2000);
      }

      & .countryField {
        width: 170px;
        margin-bottom: 0;
        flex-shrink: 0;
      }

      & .stateField {
        width: 160px;
        margin-bottom: 0;
        flex-shrink: 0;
      }

      & .amountField {
        flex: 1 0;
        margin-bottom: 0;
      }

      & .deleteBtnWrapper {
        /* dirty hack to better align the delete button with the other fields */
        margin-top: calc(var(--spacing2000) - var(--spacing0125));
      }
    }
  }

  & .addLocationBtnWrapper {
    text-align: right;
    margin-bottom: var(--spacing0500);
  }
}
