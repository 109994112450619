.container {
  display: inline-block;
  position: relative;
  width: 100%;
}

.progress {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  transition: all 300ms;
}

.default {
  background: var(--primaryBg);
}

.warning {
  background: var(--alertOrange);
}

.error {
  background: var(--blockingRed);
}

.success {
  background: var(--progressGreen);
}
