.container {
  display: flex;
  flex: 1 0 0;
  gap: var(--spacing1000);
  padding: var(--spacing1000);
  background-color: var(--white);
  border: 1px solid var(--gray500);
  border-radius: var(--radiusS);
  /* keeps size consistent when there's a 2px border for the selected state */
  margin: 1px;

  &:hover {
    box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.1);
  }

  &:focus {
    outline: 1px solid var(--brightBlue500);
    outline-offset: 2px;
  }
}

.contentWrapper {
  display: flex;
  flex: 1 0 0;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  gap: var(--spacing1000);
}

.contentWrapperCentered {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing1000);
}

.subtitleWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing0500);
}

.iconWrapper {
  font-size: var(--iconL);
}

.textCentered {
  text-align: center;
}

.disabled {
  pointer-events: none;
  color: var(--ink400);

  &:focus {
    outline-width: 0px;
  }
}

.selected {
  border: 2px solid var(--gray800);
  border-radius: var(--radiusS);
  /* keeps size consistent when there's a 2px border for the selected state */
  margin: 0px;
}

.error {
  border: 1px solid var(--red300);
  border-radius: var(--radiusS);
  /* keeps size consistent when there's a 2px border for the selected state */
  margin: 1px;

  &.selected {
    border: 2px solid var(--red300);
    /* keeps size consistent when there's a 2px border for the selected state */
    margin: 0px;
  }
}
