:global(.grecaptcha-badge) {
  visibility: hidden;
}

.container {
  display: flex;
  justify-content: center;
  min-height: 95px;
  &.invisibleRecaptcha {
    justify-content: flex-start;
    height: unset;
  }
}

.recaptchaProtected {
  font-size: 11px;
  color: var(--ink500);
}
