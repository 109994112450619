.contianer {
  padding: 0 var(--spacing1000);
  flex: 1;
  display: flex;
  align-items: center;
  overflow: hidden;
  & .title {
    margin-right: var(--spacing1000);
    flex: 1;
    overflow: hidden;
    & > * {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    & .panDigits {
      color: var(--primaryText);
      margin-top: var(--spacing0125);
    }
  }
  & .statusWrapper {
    margin-right: var(--spacing1000);
  }
}
