.tabsContainer {
  height: 100%;
}

.tabsContent {
  min-height: 400px;
  & .txHistoryContainer {
    flex: auto;
    width: 100%;
    overflow-y: auto;
    align-items: center;
    justify-content: center;
  }
}

.receipt {
  height: 90%;
  width: 90%;
}

.header {
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 1em;
}
