.container {
  padding: var(--spacing0500);
  border: 1px solid var(--primaryText);
  background: var(--primaryBgLight);
  border-radius: 4px;
  display: flex;
  align-items: flex-start;
  gap: var(--spacing1000);
  & .content {
    flex: 1 0;
  }
}
