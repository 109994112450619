.tooltip {
  line-height: 1.5;
  padding: 15px 20px;
  margin: 0;
  background-color: var(--gray700);
  color: var(--white);
  border-radius: 0.25rem;
  pointer-events: none;
  z-index: 1;
  position: absolute;
  text-align: center;
  width: 285px;
  box-shadow: 0 0 3px var(--black020);
  transition: opacity 0.15s ease-out;
}

/* Creates a small triangle extender for the tooltip */

.tooltip:after {
  content: '';
  position: absolute;
  display: block;
  width: 10px;
  height: 10px;
  background-color: inherit;
  transform: translate(-50%, -50%) rotate(45deg);
}

/* Northward tooltips */

.tooltip[class~='n']:after {
  top: 100%;
  left: 50%;
}

/* Eastward tooltips */

.tooltip[class~='e']:after {
  top: 50%;
  left: 0;
}

/* Southward tooltips */

.tooltip[class~='s']:after {
  top: 0;
  left: 50%;
}

/* Westward tooltips */

.tooltip[class~='w']:after {
  top: 50%;
  left: 100%;
}

.tooltipValue {
  font-weight: bold;
}

.container {
  cursor: pointer;
}
