.container {
  padding: 1rem;
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
  & p {
    font-size: 13px !important;
  }
  & h3 {
    width: 100px;
  }
}

.blackBg {
  background-color: var(--black);
  & h3,
  h2 {
    color: var(--white);
  }
  & p {
    color: var(--white);
  }
}
