.rowGroupEnd {
  height: 15px;
  background: var(--gray400);
}

.cell {
  text-align: center;
}

.cell:first-child {
  text-align: left;
  padding-left: 1em;
}
