.message {
  font-weight: normal !important;
}

.infoBox {
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.infoBoxActions {
  margin-top: 1em;
}

@media screen and (--min-width-sm) {
  .infoBox {
    min-height: 80px;
    flex-direction: row;
    justify-content: space-between;
  }

  .infoBoxActions {
    margin-top: 0;
  }
}
