.container {
  display: inline-flex;
  align-items: center;
}

.orientationTop {
  flex-direction: column-reverse;
  justify-content: center;
}

.orientationBottom {
  flex-direction: column;
}

.orientationLeft {
  flex-direction: row;
}

.orientationRight {
  flex-direction: row-reverse;
  justify-content: center;
}

.icon {
  margin-right: 5px;
  font-size: var(--iconS);
}

.text::first-letter {
  text-transform: uppercase;
}
