.item {
  background-color: var(--white);
  margin-bottom: var(--spacing2000);
  box-shadow: var(--shadow1);
}

.header {
  display: flex;
  flex-direction: column;
  padding: var(--spacing1000);
  background-color: var(--gray200);
}

.headerMainElements {
  display: flex;
  align-items: center;
  gap: var(--spacing0750);
}

.expandIcon {
  transition: transform 0.25s ease-out;

  &.isRotated {
    transform: rotate(-90deg);
  }
}

.itemIndex {
  font-size: 1.5rem;
  color: var(--ink600);
  font-weight: 600;
}

.content {
  padding: var(--spacing1000);
  & .section {
    &:not(:last-child) {
      margin-bottom: var(--spacing1500);
    }
    & .title {
      margin-bottom: var(--spacing1000);
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.expenseCategoryField {
  flex-grow: 1;
}

.specificForm {
  margin-bottom: var(--spacing2000);
}

@media screen and (--max-width-md) {
  .header {
    z-index: unset;
  }
}
