.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.item {
  margin: 0;
  box-sizing: border-box;
}
