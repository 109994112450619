.container {
  padding: 1rem;
}

.container:hover {
  border: none;
}

.info {
  padding: 0.5rem;
}
