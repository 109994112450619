.container {
  flex: 1;
  display: flex;
  align-items: center;
  color: var(--ink600);

  &.alignRight {
    justify-content: flex-end;
  }

  & .optional {
    color: var(--ink500);
    margin-left: var(--spacing0125);
  }

  & .infoTooltip {
    margin-left: var(--spacing0500);
    font-size: var(--iconXS);
  }
}
