.container {
  display: flex;
  justify-content: center;
  height: 100%;
  & .img {
    height: 160px;
    width: auto;
  }

  & .fatImg {
    width: 180px;
    height: auto;
  }
}

.innerContainer {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.message {
  padding-bottom: var(--spacing1000);
  color: var(--ink600);
}
.comment {
  padding-top: var(--spacing1000);
  max-width: 400px;
  text-align: left;
}

@media screen and (--max-width-md) {
  .container {
    & .img {
      height: 100px;
    }
    & .fatImg {
      width: 120px;
    }
  }
}

@media screen and (--max-width-sm) {
  .container {
    & .fatImg {
      width: 100px;
    }
    & .comment {
      max-width: unset;
    }
  }
}
