.line {
  margin: var(--spacing0500) var(--spacing0500) var(--spacing0500) 0;
}

.rowDetail {
  margin-left: 85px;
  padding: var(--spacing0500) 0;

  & .dataTable {
    margin-bottom: var(--spacing0500);
    & table {
      & tr {
        background-color: transparent;
      }
      & td {
        --bgColor: transparent;
        &:last-child {
          padding-right: 90px;
        }
      }
    }
  }
}

.selectorColumn {
  width: 20px;
}

.groupIdsColumn {
  width: 300px;
  @media (--min-width-md) {
    width: 400px;
  }
  @media (--min-width-lg) {
    width: 450px;
  }
}

.taxCodeColumn {
  width: 100px;
}

.defaultCodingColumn {
  width: 150px;
  @media (--min-width-md) {
    width: 250px;
  }
  @media (--min-width-lg) {
    width: 350px;
  }
}

.addRuleBtnWrapper {
  text-align: right;
  margin-right: var(--spacing0500);
}
