@import './fonts/fonts.css';
@import './layout/breakpoints.module.css';
@import './typography.module.css';
@import './radius.module.css';
@import './shadow.module.css';
@import './onetrust.module.css';

body {
  color: var(--ink600);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus {
  background-color: var(--white) !important;
  color: var(--ink600) !important;
  -webkit-box-shadow: 0 0 0 1000px var(--white) inset !important;
  -webkit-text-fill-color: var(--ink600) !important;

  &:disabled {
    background-color: var(--gray300) !important;
    -webkit-box-shadow: 0 0 0 1000px var(--gray300) inset !important;
  }
}

:global(#root) {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

:global(.neo1AppMain) {
  border: 2px solid transparent;
}

:global(.neo1AppMain):focus-visible {
  border: 2px solid var(--brightBlue500);
  /* Removes the additional outline that the browser may add by default,
  since we are using the border as the focus indicator. */
  outline: none;
}
