.dropzone {
  height: auto;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border: 3px dashed var(--gray400);
  box-sizing: border-box;
  margin-bottom: 1.5rem;
  position: relative;
  background: var(--gray300);
}

.dropzoneHasImage {
  background: var(--gray700);
  border: none;
}

.editor {
  align-items: center;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: var(--spacing0750);
}

.img {
  flex: 1;
  overflow: hidden;
  text-align: center;
}

.pdfPreview {
  overflow-y: auto;
  overflow-x: hidden;
  max-width: 100%;
  height: 100%;
  flex: 1 1 0%;
}

.icon {
  font-size: 3rem;
  border-color: var(--primaryText);
  color: var(--primaryText);
}

.legend {
  color: var(--ink600);
  padding: 0.5em 1em;
}

.title {
  margin-bottom: 1em;
  font-weight: bold;
}

.hiddenTitle {
  color: transparent;
}

.description {
  font-weight: normal;
}
