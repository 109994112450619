.container {
  position: relative;
  background: var(--white);
  box-shadow: 0 0 5px var(--black020);
  overflow: hidden;
}

.disabledContainer {
  filter: grayscale(100%);
}

.statusTag {
  position: absolute;
  top: 10px;
  left: 10px;
}

.logo {
  background: var(--gray300);
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.disabledLogo {
  background: var(--gray500);
  opacity: 0.4;
}

.content {
  height: 100px;
  margin: var(--spacing1500) auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 var(--spacing0750);
}

.title {
  margin-bottom: var(--spacing1500);
  text-align: center;
}

.title.titleWithExtraContent {
  margin-bottom: var(--spacing1000);
}

.extraContent {
  margin-top: var(--spacing0500);
}
