.option {
  display: flex;
  align-items: center;
  gap: var(--spacing0500);
  overflow: hidden;
  text-overflow: ellipsis;

  & .icon {
    font-size: var(--iconS);
    color: var(--ink500);
    flex-shrink: 0;
  }

  & .label {
    flex: 1 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:after {
      display: block;
      content: attr(title);
      font-weight: var(--fontWeightSemibold);
      height: 0;
      color: transparent;
      overflow: hidden;
      visibility: hidden;
    }
  }

  &.selected {
    & .icon {
      color: var(--white);
    }
  }
}
