.container {
  display: none;
  visibility: hidden;
  transition: all 250ms ease-in-out;
  text-align: left;
  z-index: 1;
  width: 100%;
}

.show {
  display: block;
  visibility: visible;
  opacity: 1;
}

.sticked {
  margin-bottom: 1em;
  position: relative;
}

.fadeIn {
  visibility: visible;
  opacity: 1;
}

.floating {
  position: absolute;
}

.up {
  composes: floating fadeIn;
  top: 0;
  transform: translateY(-100%);
}

.down {
  composes: floating fadeIn;
  bottom: calc(-1 * var(--spacing0250));
  transform: translateY(100%);
}

.fadeOut {
  visibility: hidden;
  opacity: 0;
  bottom: 0px;
}
