.page {
  overflow: auto;
}

.content {
  display: flex;
  flex-direction: column;
  overflow: visible;
}

.table {
  width: 100%;
  border: none;
  border-collapse: collapse;
}
