.container {
  display: inline-flex;
  align-items: stretch;
  justify-content: center;
  border: 1px solid var(--gray500);
  border-radius: 4px;
  background: var(--gray200);
  color: var(--ink500);
  height: 36px;
  box-sizing: border-box;

  & .segmentWrapper {
    padding: 0 var(--spacing0250);
    border-radius: 4px;

    & .segment {
      border-radius: 4px;
      transition: background ease-out 200ms;

      & .segmentInner {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: var(--spacing0500);
        padding: var(--spacing0125) var(--spacing0750);

        & .icon {
          font-size: var(--iconS);
          color: var(--ink500);
          flex-shrink: 0;
        }

        & .label {
          white-space: nowrap;
          text-overflow: ellipsis;
          &:after {
            display: block;
            content: attr(title);
            font-weight: var(--fontWeightSemibold);
            height: 0;
            color: transparent;
            overflow: hidden;
            visibility: hidden;
          }
        }
      }
    }

    &.selected {
      background: var(--white);
      color: var(--ink600);
      outline: 1px solid var(--gray600);
      outline-offset: 0;
    }

    &:hover {
      & .segment {
        background: var(--gray300);
      }
    }

    &:active {
      & .segment {
        background: var(--gray400);
      }
    }

    &:focus {
      outline: 2px solid var(--brightBlue500);
      outline-offset: -1px;
      z-index: 1;
    }

    &:disabled {
      pointer-events: none;
      color: var(--ink400);
    }
  }

  &.iconOnly {
    & .segmentWrapper {
      & .segment {
        & .segmentInner {
          padding: var(--spacing0125) var(--spacing0250);
        }
      }
    }
  }

  & .divider {
    height: 20px;
    width: 1px;
    background: var(--gray500);
    align-self: center;
    &:last-child {
      display: none;
    }
  }
}
