.container {
  background-color: var(--brightBlue100);
  padding: var(--spacing1000);
}
.frozen {
  background-color: var(--orange100);
}

.questionBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing2000);
}

.question {
  line-height: 1.5;
}
