.container {
  border-color: var(--gray100);
  border-bottom-width: 1px;
  border-bottom-style: solid;
  padding: 1rem;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 400ms all;
  &:not(:first-child) {
    padding-top: 2rem;
  }
  &:not(:last-child) {
    padding-bottom: 2rem;
  }
  &:hover {
    background-color: var(--gray100);
  }
}

.disabled {
  color: var(--ink500);
  background-color: var(--gray100);
}

.disabled .logo {
  filter: none;
  -webkit-filter: grayscale(1);
}

.container:last-child {
  border-bottom: none;
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
}

.details {
  composes: text5 from '~styles/typography/text.module.css';
  color: var(--ink500);
  font-style: italic;
}

.logo {
  margin-right: 1em;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

@media screen and (--max-width-sm) {
  .container,
  .titleContainer {
    flex-direction: column;
  }

  .container {
    border-bottom: 1px solid var(--gray400);
  }

  .titleContainer {
    margin-bottom: 1em;
  }

  .logo {
    margin-right: 0;
    margin-bottom: 0.5em;
  }
}
