.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: var(--gray200);
}

.card {
  max-width: 626px;
  width: 100%;
  background: var(--white);
}
.innerCardContainer {
  padding: var(--spacing4000);
  display: flex;
  box-shadow: var(--shadow2);
  border-radius: var(--radiusS);
  justify-content: center;
  flex-direction: column;
  align-content: center;
  align-items: center;
}
.errorManImage {
  height: 180px;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.title {
  color: var(--ink600);
  margin: var(--spacing1000) 0 0;
}
.message {
  color: var(--ink600);
  margin: var(--spacing1500) 0 var(--spacing1500);
}

@media screen and (--max-width-xl) {
  .card {
    max-width: 562px;
  }
  .innerCardContainer {
    padding: var(--spacing2000);
  }
}

@media screen and (--max-width-md) {
  .errorManImage {
    height: 120px;
  }
  .card {
    max-width: 514px;
  }

  .message {
    text-align: left;
  }
}
@media screen and (--max-width-sm) {
  .errorManImage {
    height: 100px;
  }
  .card {
    max-width: 343px;
    margin: 0 var(--spacing1000) 0 var(--spacing1000);
  }
}
