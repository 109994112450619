.container {
  display: flex;
  flex-direction: column;
  max-width: 650px;
  & .paragraph {
    display: flex;
    flex-direction: column;
    gap: var(--spacing1000);
    padding: var(--spacing1000) 0 var(--spacing1000);
  }
  & .optional {
    color: var(--ink500);
    margin-left: var(--spacing0125);
  }
}

@media screen and (--max-width-md) {
  .container {
    max-width: unset;
  }
}
