.container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: var(--red300);
  padding: 1em 2em;
  gap: var(--spacing0750);

  &.withBackground {
    background-color: var(--red300);
    color: var(--white);
  }
}
.title {
  font-weight: bold;
  font-size: 18px;
  display: flex;
  align-items: center;
}
.icon {
  margin-right: 5px;
}
.info {
  flex: 1;
}
.value {
  font-weight: bold;
}
.modalContent {
  padding: 0;
}
.commentWrapper {
  padding: 2em;
}
.commentTitle {
  font-size: 16px;
}
.commentValue {
  padding: 2em 1em 1em 1em;
  white-space: pre-line;
  overflow: auto;
}
