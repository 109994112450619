.container {
  margin-bottom: var(--spacing1500);
  &:last-child {
    margin-bottom: 0;
  }
}

.fields {
  margin-bottom: var(--spacing0500);
  &:last-child {
    margin-bottom: 0;
  }
}

.groupFieldsFromTo {
  gap: var(--spacing0500);
}

.tierTitle {
  margin-bottom: var(--spacing0500);
}
