.container {
  text-align: right;
}

.amount {
  font-weight: bold;
  margin: 0;
}

.currency {
  margin: 0;
}
