.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: var(--spacing1000);
  border-top: 1px solid var(--gray300);
  gap: var(--spacing1000);

  & .label {
    flex: 1 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.field {
  margin-bottom: 0;
  flex: 1 0;
}

.readonlyValue {
  font-weight: bold;
}
