.wrapper {
  display: flex;
  align-items: center;
  gap: var(--spacing0500);

  &.reversed {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  & .container {
    display: inline-flex;
    vertical-align: middle;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 48px;
    height: 24px;
    border-radius: 12px;
    margin: 0;
    border: none;
    position: relative;
    background: var(--gray600);
    transition: background-color 0.15s ease-in-out;
    cursor: pointer;
    flex-shrink: 0;

    &::before {
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      left: 2px;
      top: 2px;
      border-radius: 50%;
      background: var(--white);
      transition: left 0.15s ease-in-out;
      cursor: grab;
    }

    &:checked {
      background: var(--primaryBg);

      &::before {
        left: 26px;
      }
    }

    &:focus {
      outline: 2px solid var(--brightBlue500);
      outline-offset: 2px;
    }

    &:disabled {
      background: var(--gray400);
      cursor: not-allowed;
      pointer-events: none;
      &::before {
        cursor: not-allowed;
      }

      &.disabled:checked {
        background: var(--brightBlue300);
      }
    }

    &:hover {
      background: var(--gray700);
      &:checked {
        background: var(--brightBlue600);
      }
    }
  }

  & .disabledLabel {
    color: var(--ink400);
  }
}
