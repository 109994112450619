.list {
  display: block;
  text-align: left;
  max-height: 200px;
  overflow-y: auto;
}

.item {
  text-align: left;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing0750);
}
