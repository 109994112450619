.header {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  width: 100%;
  height: 100%;
  padding: 0 var(--spacing1000);
  box-sizing: border-box;
}

.date {
  flex-shrink: 0;
  text-align: center;
}

.merchant {
  flex-grow: 1;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 1.25rem;
  font-weight: bold;
  overflow: hidden;

  &.placeholder {
    color: var(--ink400);
  }
}

.amount {
  flex-shrink: 0;
}
