.filters {
  background: var(--white);
  padding: var(--spacing1000);
  & .title {
    margin-bottom: var(--spacing1000);
  }
  & .filter {
    margin-bottom: var(--spacing1000);
    & .label {
      color: var(--ink500);
      margin-bottom: var(--spacing0250);
    }
  }
}
