.container {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 1rem;
  height: 45px;
}

.line {
  display: flex;
  justify-content: space-between;
  background: var(--gray400);
  height: 2px;
  flex-grow: 1;
}

.radio {
  margin: 0;
  position: relative;
  top: -11px;
}

.radio input {
  margin: 0;
}

.radio:last-child {
  margin-right: 0;
}
