.container {
  display: inline-flex;
  flex-direction: row-reverse;
  align-items: flex-start;
}

.item {
  position: relative;

  &:not(:last-child) {
    margin-left: -10px;
  }

  &:hover {
    z-index: 1;
  }
}

.list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.checkIcon {
  color: var(--white);
  margin-left: var(--spacing0500);
}

.restPlaceholder {
  border: none;
  background-color: var(--gray400);
}

.avatarTooltipName {
  display: flex;
  align-items: center;
  margin: 0.3em 0.7em;
}

.avatarText {
  white-space: nowrap;
}
