.fixedSize {
  margin-bottom: 4rem;
  margin-right: 4rem;
}

.fixedSize .header {
  height: 4rem;
}

.fixedSize .main {
  height: 22rem;
  display: flex;
  flex-direction: row;
}

.fixedSize .iconContainer {
  width: 150px;
}

.header {
  padding-bottom: 1em;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerTitle {
  color: var(--ink500);
  font-weight: 700;
  padding-left: 0.5em;
}

.actionsContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.main {
  box-shadow: 0 1px 5px var(--black020);
  border-radius: 2px;
}

.defaultShadows {
  border-bottom-color: var(--gray300);
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-width: 3px;
}

.blurryShadows {
  box-shadow: 0 1px 5px var(--black020);
}

.simpleMain {
  composes: main;
  display: grid;
  grid-template-columns: 30% 70%;
}

.simpleMain .contentContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
}

.fixedSize .contentContainer {
  width: 45rem;
  display: flex;
  align-items: center;
}

.fixedSize .contentContainer.withActions {
  width: 40rem;
}

.simpleMain .title {
  padding: 0 1em;
  font-size: 1rem;
}

.iconContainer {
  background-color: var(--gray300);
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.actions {
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  padding: 1rem;
  gap: var(--spacing0750);
}

.icon {
  font-size: 8rem;
  color: var(--primaryText);
}

.image {
  width: 80px;
}

.contentContainer {
  background: var(--white);
  padding: 1em;
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
}

.contentContainer .title {
  flex: 1;
}

.listContainer {
  display: grid;
  grid-column-gap: 1em;
  grid-row-gap: 2em;
  margin: 0;
  padding: 0;
}

.listItem {
  list-style: none;
  padding-left: 1em;
}

.listTitle {
  color: var(--ink500);
  font-weight: 300;
}

.listValue {
  color: var(--black);
  font-weight: 700;
  margin: 0.2em 0 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.editBtn {
  margin: 0 !important;
}

.fourColLayout {
  grid-template-columns: 200px 200px;
  grid-template-rows: 40px 40px;
}

.threeColLayout {
  grid-template-columns: 140px 140px 140px;
  grid-template-rows: 40px 40px;
}

.twoColLayout {
  grid-template-columns: 100%;
  grid-template-rows: 40px 40px;
}

.item1 {
  grid-area: header;
}

.item2 {
  grid-area: footer;
}

.item3 {
  grid-area: footer2;
}

.customLayout {
  grid-template-areas:
    'header .'
    'footer footer2';
}

@media screen and (--max-width-sm) {
  .simpleMain .title {
    font-size: 1rem;
  }
}
