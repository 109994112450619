.optionWrapper {
  display: flex;
  &:hover {
    background-color: var(--gray300);
  }
}

.option {
  display: flex;
  align-items: center;
  align-self: stretch;
  cursor: default;
  padding: 0 0.5rem;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  & * {
    cursor: default;
  }
  & .optionLabel {
    margin-left: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.noOptions {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--ink400);
}

.valueContainer {
  display: flex;
  align-items: center;
  overflow: hidden;
  & .value {
    margin-left: -3px;
  }
}
