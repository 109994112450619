.wrapper {
  position: fixed;
  z-index: 4;

  & .backdrop {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }

  &.opened {
    & .backdrop {
      display: block;
    }
  }
}
