.fileSelected {
  text-align: center;
}

.btnContainer {
  display: flex;
  align-items: center;
  gap: var(--spacing1000);
}

@media screen and (--max-width-sm) {
  .btnContainer {
    flex-direction: column;
  }
}
