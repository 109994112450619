.container {
  text-align: center;
  & .icon {
    display: block;
    font-size: 4rem;
    color: var(--primaryText);
  }

  & .message {
    margin: var(--spacing1000) 0 var(--spacing2000);
  }
}
