.comingSoon {
  color: var(--ink400);
  font-size: 1.2rem;
  font-weight: bold;
  text-transform: uppercase;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.content {
  flex: 1 1;
  overflow: hidden;
  margin: 0.5rem 0;
  padding: 0 1rem;
  padding-top: 0;
  display: flex;
  flex-direction: column;
}

.counterInfoWrapper {
  display: flex;
  display: flex;
  flex: 1;
  justify-content: space-around;
}

.counter {
  display: flex;
}

.withBorderLeft {
  border-left: 1px solid var(--gray400);
}

.counterInfo {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.counter {
  transition: all 200ms;
  flex: 1 1;
  overflow: hidden;
  padding: 0 1rem;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  height: 100%;
}

.block:hover .counter {
  transform: translate(0, -5px);
}

.syncMessage {
  margin: 0.5rem 0;
  font-size: 11px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
