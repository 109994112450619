.container {
  position: relative;
  text-align: center;
  box-sizing: border-box;
  padding: var(--spacing0750) var(--spacing1000);

  @media screen and (--max-width-md) {
    padding: var(--spacing1000);
  }
}

.closeBtn {
  display: block;
  position: absolute;
  top: var(--spacing0750);
  right: var(--spacing0750);
}

.name,
.email {
  overflow: hidden;
  text-overflow: ellipsis;
}

.name {
  font-weight: var(--fontWeightSemibold);
  color: inherit;

  @media screen and (--max-width-md) {
    color: var(--white);
  }
}

.email {
  color: var(--ink500);

  @media screen and (--max-width-md) {
    color: inherit;
  }
}

.logAsLabel {
  margin-bottom: var(--spacing0500);
}

.button {
  margin-top: var(--spacing0250);
}
