.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing1000);
  padding: var(--spacing2000);
}

.virtualToggle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing0750);
  margin-bottom: var(--spacing1000);
  padding: var(--spacing0500) 0;
}
