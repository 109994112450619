.container {
  width: 100%;
  max-height: 100%;
  overflow: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;

  & tr {
    &:nth-child(odd) {
      background: var(--white);
    }
    &:nth-child(even) {
      background: var(--gray200);
    }
  }

  & td {
    padding: var(--spacing0500) var(--spacing1000);
    &.fullWidth {
      width: 100%;
    }
  }
}
