.contianer {
  padding-right: 1rem;
  flex: 1;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.date {
  width: 5rem;
}

.title {
  font-size: 18px;
  margin: 0 1em 0 0;
  font-weight: bold;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}
