.container {
  width: 100%;
  height: 100%;
}

.loaderWrapper {
  padding: var(--spacing0750);
  display: flex;
  justify-content: center;
}
