.container {
  width: 100%;

  & .backdrop {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--black050);
  }
  & .content {
    position: relative;
    background: var(--white);
    display: flex;
    align-items: stretch;
    height: 70px;
    box-shadow: 0 -3px 5px var(--black020);
  }
  & .iconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--primaryBg);
    width: var(--app-nav-size);
    & .icon {
      color: var(--textOnPrimaryBg);
      font-size: 30px;
    }
  }
  & .infoWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    overflow: hidden;
    padding: 0 1em;
    & .labelsWrapper {
      text-align: center;
      margin-right: 1em;
      overflow: hidden;
      & .label {
        font-weight: bold;
        font-size: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      & .description {
        margin-top: 2px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    & .actionsWrapper {
      display: flex;
      align-items: center;
      gap: var(--spacing0750);
    }
  }
  &.expanded {
    position: fixed;

    & .content {
      height: 250px;
    }
    & .backdrop {
      display: block;
    }
    & .infoWrapper {
      flex-direction: column;
      & .labelsWrapper {
        & .label {
          white-space: normal;
        }
        & .description {
          margin-top: 5px;
          white-space: normal;
        }
      }
      & .actionsWrapper {
        margin-top: 2rem;
      }
    }
  }
}

@media screen and (--max-width-sm) {
  .container {
    & .content {
      flex-direction: column;
      height: unset;
    }

    &.expanded {
      & .content {
        height: unset;
      }
    }

    & .iconWrapper {
      width: 100%;
      padding: 0.5rem 0;
    }

    & .infoWrapper {
      flex-direction: column;
      padding: 0 1em 1em;
    }

    & .infoWrapper {
      & .labelsWrapper {
        overflow: unset !important;

        & .label {
          white-space: normal;
          margin-top: 0.5rem;
        }

        & .description {
          white-space: normal !important;
          margin: 0 1rem;
          overflow: unset !important;
          text-overflow: unset !important;
          margin-top: 0.5rem;
        }
      }
    }

    & .actionsWrapper {
      margin-top: 0.5rem;
    }
  }
}
