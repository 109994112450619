.container {
  display: flex;
  flex-direction: column;
  flex: 1 0;
  justify-content: center;
  align-items: center;
  & .text {
    max-width: 180px;
    text-align: center;
    margin-top: var(--spacing1000);
  }
}
