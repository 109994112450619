.selectMask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

.label {
  composes: text5 from '~styles/typography/text.module.css';
}

.clearIcon {
  color: var(--gray500) !important;
  &:hover {
    color: var(--ink500) !important;
  }
  & i {
    display: flex;
    font-size: var(--iconXS);
    cursor: pointer;
  }
}

.dropDownIcon {
  font-size: var(--iconXS);
}
