.container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--gray200);
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100% auto;
  z-index: -1;
  & .fakeLayoutWrapper {
    display: none;
  }
}

@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .container {
    display: flex;
    & .fakeLayoutWrapper {
      display: flex;
      flex: 1;
      & .mask {
        z-index: 3;
        &:before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background: var(--gray800);
          opacity: 0.7;
        }
        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          backdrop-filter: blur(5px);
        }
      }
    }
  }
}
@media (--max-width-sm) {
  .container {
    background: var(--white);
    opacity: 0;
  }
}
