.container {
  display: inline-block;
  animation-duration: 1.7s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: scanner;
  background: var(--gray200);
  background: linear-gradient(
    to right,
    var(--gray300) 2%,
    var(--gray400) 18%,
    var(--gray300) 33%
  );
  background-size: 1300px;
  font-size: inherit;
  padding: 0.5em;
  min-width: 100px;
}

@keyframes scanner {
  0% {
    background-position: -650px 0;
  }
  100% {
    background-position: 650px 0;
  }
}
