.container {
  display: flex;
  align-items: stretch;
}

.input {
  padding: 0 10px;
  height: 36px;
  border: none;
  outline: none;
  flex: 1 0;
  &:focus {
    border: none;
    outline: none;
  }
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  background-color: var(--gray300);
  text-align: center;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.icon {
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.amexIcon {
  height: 32px;
  width: auto;
}

.iconPlaceholder {
  font-size: 23px;
  color: var(--gray500);
  display: flex;
  align-items: center;
  justify-content: center;
}

.readOnlyValue {
  pointer-events: none;
  border: none;
  outline: none;
  padding: 0;
}
