.captchaContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 2rem;
  height: 78px;
}

.buttons {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
}

@media screen and (--max-width-sm) {
  .buttons {
    flex-direction: column-reverse;
  }
}
