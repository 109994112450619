.container {
  height: auto;
}

.header {
  position: relative;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
}
.content {
  position: relative;
  max-height: 0;
  transition: all 300ms ease-in-out;
  overflow: hidden;
}

.isOpen {
  height: auto;
  max-height: 5000px;
}

.pointer {
  cursor: pointer;
}
