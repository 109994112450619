.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing1000);
  & .helper {
    margin-top: var(--spacing0500);
    color: var(--ink500);
  }
  & .warningIcon {
    color: var(--blockingRed);
  }
}
