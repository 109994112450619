.content {
  display: flex;
}

.paymentList,
.extractList,
.extractListItemContent {
  padding: 0;
}

.paymentListItem,
.extractListItem,
.extractListItemContent {
  list-style: none;
}

.paymentList {
  flex: 1;
  margin: 0;
}

.paymentListTitle {
  margin-left: 0.2em;
}

.extractsContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  margin-top: 1rem;
}

.extractedFiles {
  margin-right: 1rem;
}

.extractedFiles,
.accruals {
  margin-bottom: 1rem;
}

.extractListItem {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 2em;
}

.extractListItemDate {
  padding: 1em;
  margin-right: 1em;
  width: 80px;
  text-align: center;
  background-color: var(--gray300);
}

.extractYear {
  padding-bottom: 0.3em;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: var(--white);
}

.extractMonth {
  padding-top: 0.3em;
}

.extractListItemContent {
  margin-left: 1em;
  padding-left: 0.5em;
}

.extractItem {
  margin-bottom: 0.5em;
}

.extractItemDate,
.extractItemFile {
  margin: 0;
  padding: 0;
}

.downloadLinkContainer {
  display: inline;
  padding-left: var(--spacing0500);
}

.extractedHeader {
  color: var(--alertOrange);
}

.accrualsHeader {
  color: var(--alertOrange);
}

@media screen and (--max-width-sm) {
  .extractsContainer,
  .extractListItem {
    flex-direction: column;
  }

  .extractListItemDate {
    width: calc(100% - 2em);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    margin: 0;
  }

  .extractMonth {
    padding-top: 0;
  }

  .extractYear {
    padding-bottom: 0;
    padding-right: 0.5em;
    border-bottom: unset;
  }

  .extractListItemContent {
    margin-top: 1em;
  }
}
