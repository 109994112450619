.container {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: var(--spacing2000);
  background: var(--white);
  overflow: hidden;

  & .content {
    display: flex;
    width: 100%;
    height: 100%;
    gap: calc(2 * var(--spacing2500));

    & .left {
      width: 44%;
      text-align: center;
    }

    & .right {
      width: 56%;
      display: flex;
      flex-direction: column;

      & .title {
        margin-bottom: var(--spacing1000);
      }

      & .description {
        margin-bottom: var(--spacing1500);
      }

      & .element {
        display: flex;
        align-items: center;
        margin-bottom: var(--spacing1000);
        white-space: nowrap;
        overflow: hidden;
        & .label {
          overflow: hidden;
          text-overflow: ellipsis;
          margin-left: var(--spacing0250);
        }
        & .sup {
          flex-shrink: 0;
          color: var(--ink500);
          margin-left: var(--spacing0250);
          margin-right: var(--spacing1000);
        }
      }

      & .info {
        margin-bottom: var(--spacing1500);
        color: var(--ink500);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    & .btnsWrapper {
      display: flex;
      align-items: center;
      gap: var(--spacing0750);
    }
  }
}

.floatingBookBtn {
  position: fixed;
  bottom: var(--spacing0750);
  right: var(--spacing0750);
  z-index: 1;
}

@media screen and (--max-width-md) {
  .container {
    & .content {
      flex-direction: column;
      gap: var(--spacing2500);

      & .left {
        width: unset;

        & .bgImg {
          max-height: 320px;
        }
      }

      & .right {
        width: unset;

        & .btnsWrapper {
          justify-content: center;
        }
      }
    }
  }
}

@media screen and (--max-width-sm) {
  .container {
    padding: var(--spacing2000) var(--spacing1000);

    & .content {
      gap: var(--spacing1500);

      & .left {
        & .bgImg {
          max-height: 280px;
        }
      }

      & .right {
        & .description {
          margin-bottom: var(--spacing1000);
        }

        & .element {
          margin-bottom: var(--spacing0750);
        }

        & .info {
          margin-bottom: var(--spacing1000);
          white-space: normal;
        }
      }
    }
  }
}
