.button {
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--spacing0750);
  gap: var(--spacing0750);
  border-style: solid;
  border-color: var(--gray300);
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-width: 1px;
  border-bottom-width: 0;
  background-color: var(--white);
  transition: background-color 0.25s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: var(--gray200);
  }

  &.isActive {
    & .toggleIcon {
      transform: rotate(0);
    }
    & .title,
    & .label,
    & .icon {
      color: var(--brightBlue600);
    }
  }

  &:last-of-type {
    border-bottom-width: 1px;
  }
}

.toggleIcon {
  font-size: var(--iconXS);
  color: var(--ink500);
  transform: rotate(-90deg);
  transition: transform 0.25s ease-out;
}

.icon {
  margin-left: 10px;
  font-size: var(--iconS);
  color: var(--primaryText);
}

.title,
.label {
  text-align: left;
}

.title {
  flex-grow: 1;
}

.label {
  font-style: italic;
}

.content {
  height: 0;
  overflow-y: hidden;
  background-color: var(--gray200);
  border-left: 1px solid var(--gray300);
  border-right: 1px solid var(--gray300);
  transition: height 0.25s ease-out;
}
