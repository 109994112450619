.applyToWrapper {
  display: flex;
  align-items: center;
  gap: var(--spacing0500);
  & .applyToLabel {
    margin-bottom: var(--spacing1000);
  }
  & .applyToField {
    width: 220px;
  }
  &.applyToEveryone {
    & .applyToLabel,
    & .applyToField {
      margin-bottom: 0;
    }
  }
}

.userGroupsField {
  margin-bottom: 0;
}
