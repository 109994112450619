.container {
  display: flex;
  flex-direction: column;
  background: var(--gray200);
  flex: 1 0;
  padding: var(--spacing1000);
  & .infoSectionWrapper {
    padding-bottom: var(--spacing1000);
    border-bottom: 1px solid var(--gray500);
  }
  & .valuesSectionWrapper {
    padding-top: var(--spacing1000);
    flex: 1 0;
  }
}
