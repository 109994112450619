.pageContent {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.filled {
  background-color: var(--white);
}

.deliverToWrapper {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing1000);
}
