.container {
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  padding: 1em 1.5em 0.5em;
}

.list {
  padding-left: 0;
}

.iconValid {
  color: var(--progressGreen);
}

.iconWarning {
  color: var(--ink500);
}

.iconInvalid {
  color: var(--blockingRed);
}

.error {
  background-color: var(--blockingRedBg);
  border-color: var(--blockingRed);
}

.valid {
  background-color: var(--progressGreenBg);
  border-color: var(--progressGreen);
}

.criteriaTitle {
  font-weight: 700;
}
