.content {
  &:not(:last-child) {
    margin-bottom: var(--spacing1500);
  }
  & .label,
  & .value {
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  & .label {
    margin-bottom: var(--spacing0250);
  }
}
