.container {
  display: flex;
}

.leftCol {
  width: calc(100% - 300px);
}

.charts {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;

  & > div {
    display: flex;
    flex-direction: column;
    margin-right: 1rem;
    margin-bottom: 1rem;

    & main {
      flex-grow: 1;
    }
  }
}

.rightCol {
  vertical-align: top;
  width: 300px;
}

.isEmptyChart {
  padding: unset;
  min-height: unset;
}
@media screen and (--max-width-lg) {
  .leftCol {
    width: calc(100% - 250px);
  }

  .rightCol {
    width: 250px;
  }

  .charts {
    grid-template-columns: 50% 50%;
  }
}

@media screen and (--max-width-md) {
  .charts {
    grid-template-columns: 100%;
  }
}

@media screen and (--max-width-sm) {
  .container {
    flex-direction: column-reverse;
    margin-bottom: 120px;
  }

  .leftCol {
    width: 100%;
  }

  .charts {
    & > div {
      margin-right: 0;
    }
  }

  .rightCol {
    width: 100%;
    margin-bottom: 2rem;
  }
}
