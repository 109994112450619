.tabsContainer {
  height: 100%;
}

.tabsContent {
  align-items: center;
  justify-content: center;
}

@media screen and (--max-width-md) {
  .tabsContent {
    min-height: 400px;
  }
}
