@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logo {
  height: 64px;
  margin-bottom: var(--spacing1500);
}

.spinner {
  width: 32px;
  height: 32px;
  border: 6px solid transparent;
  border-left: 6px solid var(--primaryDark);
  border-radius: 50%;
  animation: spin 1s infinite linear;
}
