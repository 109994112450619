.card {
  height: 100%;
  border: none;
}

.header {
  border-bottom: none;
  padding: 0 var(--spacing1000);
  height: 50px;
  min-height: 50px;
  max-height: 50px;
}
.content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 var(--spacing1000) var(--spacing1000);
  &.emptyContent {
    overflow: hidden;
  }
}

.title {
  display: flex;
  align-items: center;
  gap: var(--spacing0250);
}

@media screen and (--max-width-sm) {
  .header {
    flex-direction: row;
    align-items: center;
  }
}
