.container {
  display: flex;
  flex-direction: row;
  padding: 2px;
}

.content {
  flex: 1;
  padding-left: 0.5em !important;
}

.iconClassName {
  width: 70px;
}

.iconClassName span {
  font-size: 2rem;
  font-weight: bold;
}
