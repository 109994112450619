.content {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.filtersWrapper {
  margin-bottom: 1rem;
}

@media screen and (--max-width-sm) {
  .actions {
    margin: -1rem -1rem 0;
  }
}
