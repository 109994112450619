.ref {
  display: inline-flex;
  max-width: 100%;
  max-height: 100%;
  position: relative;
}
.popperWrapper {
  position: fixed;
  display: inline-block;
  z-index: 4;
}
.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: transparent;
  z-index: 4;
}
.popperWrapper {
  &.right,
  &.right-start,
  &.right-end {
    padding-left: var(--spacing0500);
  }

  &.left,
  &.left-start,
  &.left-end {
    padding-right: var(--spacing0500);
  }

  &.top,
  &.top-start,
  &.right-end {
    padding-bottom: var(--spacing0500);
  }

  &.bottom,
  &.bottom-start,
  &.bottom-end {
    padding-top: var(--spacing0500);
  }
}
.popper {
  position: relative;
  background-color: var(--white);
  border-radius: 0.25rem;
  box-shadow: 0 0 5px var(--black020);

  & .arrow {
    width: 10px;
    height: 10px;
    background-color: inherit;
    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-color: inherit;
    }
    &.hideArrow {
      display: none;
    }
  }

  &.right .arrow {
    left: 0px;
    &::before {
      transform: translateX(-50%) rotate(45deg);
      box-shadow: -2px 2px 3px var(--black020);
    }
  }

  &.right-start .arrow {
    left: 0px;
    top: 0px;
    &::before {
      transform: translateX(-50%) rotate(45deg);
      box-shadow: -2px 2px 3px var(--black020);
    }
  }

  &.right-end .arrow {
    left: 0px;
    bottom: 0px;
    &::before {
      transform: translateX(-50%) rotate(45deg);
      box-shadow: -2px 2px 3px var(--black020);
    }
  }

  &.left .arrow {
    right: 0px;
    &::before {
      transform: translateX(50%) rotate(45deg);
      box-shadow: 2px -2px 3px var(--black020);
    }
  }

  &.left-start .arrow {
    right: 0px;
    top: 0px;
    &::before {
      transform: translateX(50%) rotate(45deg);
      box-shadow: 2px -2px 3px var(--black020);
    }
  }

  &.left-end .arrow {
    right: 0px;
    bottom: 0px;
    &::before {
      transform: translateX(50%) rotate(45deg);
      box-shadow: 2px -2px 3px var(--black020);
    }
  }

  &.top .arrow {
    bottom: 0px;
    &::before {
      transform: translateY(50%) rotate(45deg);
      box-shadow: 2px 2px 3px var(--black020);
    }
  }

  &.top-start .arrow {
    bottom: 0px;
    left: 0px;
    &::before {
      transform: translateY(50%) rotate(45deg);
      box-shadow: 2px 2px 3px var(--black020);
    }
  }

  &.top-end .arrow {
    bottom: 0px;
    right: 0px;
    &::before {
      transform: translateY(50%) rotate(45deg);
      box-shadow: 2px 2px 3px var(--black020);
    }
  }

  &.bottom .arrow {
    top: 0px;
    &::before {
      transform: translateY(-50%) rotate(45deg);
      box-shadow: -2px -2px 3px var(--black020);
    }
  }

  &.bottom-start .arrow {
    top: 0px;
    left: 0px;
    &::before {
      transform: translateY(-50%) rotate(45deg);
      box-shadow: -2px -2px 3px var(--black020);
    }
  }

  &.bottom-end .arrow {
    top: 0px;
    right: 0px;
    &::before {
      transform: translateY(-50%) rotate(45deg);
      box-shadow: -2px -2px 3px var(--black020);
    }
  }
}
