.container {
  display: flex;
  align-items: center;
  gap: var(--spacing0750);
  line-height: 1.25;

  @media screen and (--max-width-sm) {
    width: 100%;
    justify-content: space-between;
  }
}

.address {
  font-weight: bold;
}

.linkButton {
  color: var(--primaryText);
  text-decoration: underline;
}
