.outerContainer {
  display: flex;
  flex-direction: column;
}

.labelsOnTopContainer {
  display: flex;
}

.container {
  display: flex;
  margin-bottom: 1rem;
}

.labelContainer {
  display: flex;
  align-items: center;
  width: 33%;
  min-width: 7.5rem;
  margin-right: 0.5rem;
}

.radioGroup {
  width: 70%;
}

.radioButtonLabels {
  width: 80%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.radioButtonLabel:not(:first-child) {
  margin-left: 0.5rem;
}

.label > div {
  padding: 0;
}

.label {
  margin-right: 1rem;
}

.label span {
  width: 7rem;
}

.iconContainer {
  font-size: var(--iconL);
  margin-right: 1rem;
  display: flex;
}

.errorWarning {
  margin-left: auto;
  margin-right: 1rem;
}

.hideWarning {
  visibility: hidden;
}

.labelContainer {
  height: 45px;
}

@media screen and (--max-width-md) {
  .iconContainer {
    display: none;
  }
  .errorWarning {
    margin-right: 0;
  }
}
