.container {
  & .header {
    display: flex;
    align-items: center;
    margin-bottom: var(--spacing1000);
    gap: var(--spacing1000);
    & .title {
      flex: 1 0;
      display: flex;
      align-items: center;
      gap: var(--spacing1000);
    }
  }
  & .actions {
    display: flex;
    flex-direction: row;
    gap: var(--spacing0750);
  }
  & .automationEdit {
    padding-top: var(--spacing1000);
  }
}
