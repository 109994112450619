.container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.value,
.label {
  text-align: right;
  padding: 4px;
}

.row,
.total {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 350px;
  padding: 0.5em;
}

.label {
  flex: 1;
}

.value {
  width: 140px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: var(--spacing0500);
}

.input {
  background: none;
  padding: 5px 0;
  width: 50px;
  height: auto;
  font-weight: normal;
  text-align: right;
  margin-right: 4px;
}

.total {
  border-top: 1px solid var(--gray400);
}

.total .value,
.total .label {
  font-weight: bold;
}
