.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: hidden;
  gap: var(--spacing1000);
}

.tableContainer {
  flex: 2 1;
  align-self: stretch;
  overflow: hidden;
  background: var(--gray200);
}

.tableHead {
  background: var(--gray700);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tableHeader {
  flex: 1;
  background: var(--gray700);
  will-change: transform;
  text-align: center;
  padding: 0.5em 0;
  color: var(--white);
  position: sticky;
  top: 0;
  box-sizing: border-box;
}

.tableRow {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  box-sizing: border-box;
  overflow-y: hidden;
}

.tableRow:nth-of-type(odd) {
  background: var(--gray300);
}

.tableRow:nth-of-type(even) {
  background: var(--gray400);
}

.tableCell {
  text-align: center;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: auto;
}

.tooltipText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  padding: 0 1em;
}

.reportChildrenContainer {
  flex: 1 1;
  overflow: auto;
  padding: var(--spacing0500);
  color: var(--ink600);
}
