.itemContent {
  display: flex;
  flex-direction: column;
  gap: var(--spacing1000);
  border-bottom: 1px solid var(--gray300);
  padding-bottom: var(--spacing1500);
  &:last-child {
    border-bottom: none;
    padding-bottom: unset;
  }
}

.header,
.section {
  display: flex;
  border-bottom: 1px solid var(--gray300);
}

.header {
  position: sticky;
  top: 0;
  background-color: var(--gray200);
  z-index: 1;
}

.section {
  &:last-child {
    border-bottom: none;
  }
}

.policyStatusColumn {
  width: 120px;
  display: flex;
  flex-shrink: 0;
}

.leftColumn {
  width: 180px;
  display: flex;
  flex-shrink: 0;
}

.middleColumn {
  flex-grow: 1;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: var(--spacing0500);
  gap: var(--spacing0500);
  border-left: 1px solid var(--gray300);
  border-right: 1px solid var(--gray300);
}

.taxColumn {
  width: 340px;
  padding: var(--spacing0500);
  flex-shrink: 0;
  border-right: 1px solid var(--gray300);
}

.taxColumnHeadWrapper {
  display: flex;
  gap: var(--spacing1000);
  align-items: center;
  justify-content: space-between;
}

.taxLinesWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing0500);
}

.rightColumn {
  display: flex;
  flex-shrink: 0;
  width: 340px;
  flex-direction: column;
  padding: var(--spacing0500) var(--spacing1000);
  gap: var(--spacing0500);
  &.short {
    width: 150px;
  }
}

.policyStatusHead {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: var(--spacing0250) var(--spacing0500);
}

.recipientsHead {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: var(--spacing0250) var(--spacing0500);
}

.distributionHead {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.policyStatusList {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: var(--spacing1000);
}

.policyStatus {
  display: flex;
  height: 52px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.recipientList {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: var(--spacing1000);
  overflow: hidden;
}

.recipient {
  padding: var(--spacing0250) var(--spacing0500);
  white-space: nowrap;
  width: calc(100% - 2 * var(--spacing0500));
  overflow: hidden;
  & > * {
    overflow: hidden;
    text-overflow: ellipsis;
    &:first-child {
      margin-bottom: var(--spacing0250);
    }
  }
}

.segmentHead {
  display: flex;
  align-items: center;
  gap: var(--spacing1000);
  & > * {
    width: 200px;
    flex-shrink: 0;
  }
}
.segmentList {
  display: flex;
  align-items: center;
  gap: var(--spacing1000);
  & > * {
    width: 200px;
    flex-shrink: 0;
  }
}

.itemInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 50px;
}

.actionList {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: var(--spacing0500);
}

.invalidDistributionError {
  color: var(--blockingRed);
}
