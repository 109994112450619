.cookieContainer {
  position: fixed;
  bottom: var(--spacing2500);
  right: var(--spacing2500);
  z-index: 4;
}

@media screen and (--max-width-md) {
  .cookieContainer {
    bottom: var(--spacing2000);
    right: var(--spacing2000);
  }
}

@media screen and (--max-width-sm) {
  .cookieContainer {
    bottom: var(--spacing1000);
    right: var(--spacing1000);
  }
}
