.container {
  display: flex;
  flex-wrap: wrap;
  & .section {
    width: 400px;
    max-width: 100%;
    padding: var(--spacing1000);
    box-sizing: border-box;

    & > *:not(:last-child) {
      margin-bottom: var(--spacing1000);
    }
  }
}
