.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing2000);
}

.question {
  line-height: 1.5;
}
