.container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1em;
}

.details {
  flex: 1;
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--gray300);
  width: 48px;
  height: 48px;
  margin-right: var(--spacing0500);
}

.icon {
  font-size: 1.5rem;
}
