.container {
  position: relative;
  display: flex;
  align-items: center;
  gap: var(--spacing1000);
  width: 100%;
  padding: calc(var(--spacing0750) - 2px) calc(var(--spacing1000) - 2px);
  outline: none;
  border: 2px solid transparent;
  transition: all 250ms ease-out;

  &.compact {
    flex-direction: column;
    gap: var(--spacing0250);

    & .counter {
      position: absolute;
      right: var(--spacing0500);
      top: var(--spacing0250);
    }
  }

  &:hover {
    background-color: var(--brightBlue100);
    border-color: var(--brightBlue100);

    &.dark {
      background-color: var(--gray800);
      border-color: var(--gray800);
    }
  }

  &:active {
    background-color: var(--brightBlue200);
    border-color: var(--brightBlue200);

    &.dark {
      background-color: var(--gray700);
      border-color: var(--gray700);
      color: var(--white);
    }
  }

  &:focus:not(:hover):not(:active):not(.selected):not(.dark) {
    border-color: var(--primaryBg);
  }

  &:focus:not(:hover):not(:active):not(.isSelected).dark {
    color: var(--white);
    border-color: var(--white);
  }

  &.active {
    &.dark {
      color: var(--white);
    }

    &::before {
      content: '';
      position: absolute;
      left: -2px;
      top: -2px;
      bottom: -2px;
      width: 4px;
      background-color: var(--primaryBg);
    }
  }

  &.selected {
    background-color: var(--primaryBg);
    border-color: var(--primaryBg);
    color: var(--textOnPrimaryBg);
  }

  @media screen and (--max-width-md) {
    padding: calc(var(--spacing1000) - 2px);
    border: 2px solid transparent;
  }
}

.icon {
  font-size: var(--iconS);
}

.label {
  flex-grow: 1;
  text-align: left;
}
