.amount {
  font-weight: bold;
}

.previewMessageHeading {
  font-weight: bold;
}

.noTxLinkedContainer {
  display: flex;
  flex-direction: column;
  font-size: 1em;
}

.noTxLinkedHeading {
  font-weight: 600;
  color: var(--ink600);
  font-size: 1.2em;
  margin: 0;
}

.noTxLinkedMessage {
  font-weight: lighter;
  margin-bottom: 0;
}

.budgetSummary {
  display: flex;
  flex-direction: column;

  padding: 1.5em 0em 1em;
  font-size: 0.8rem;
}

.modalContainerSizeReduced {
  min-width: 400px;
}

.modalContainerSizeExpanded {
  min-width: 70vw;
}

.budgetCloseConfirmationMessage {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
}

.budgetProgressBarBackground {
  background-color: var(--gray300);
}

.confirmationMessage {
  font-size: 1.5em;
  margin-bottom: 3em;
  font-weight: lighter;
  color: var(--ink500);
}

.confirmationMessageHeading {
  font-size: 1.2em;
  font-weight: 600;
  color: var(--ink600);
  margin-bottom: 0.2em;
}

.confirmationMessageIcon {
  color: var(--progressGreen);
  font-size: var(--iconL);
  margin-right: 0.45em;
  position: relative;
  top: 0.1em;
}

.amountPercentages {
  font-size: 1.3em;
}

.overSpend {
  composes: amountPercentages;
  color: var(--blockingRed);
}

.notOverSpend {
  composes: amountPercentages;
  color: var(--progressGreen);
}

.remaining {
  composes: amountPercentages;
  color: var(--ink500);
}

.spendInfo {
  margin: 0 auto;
  width: 244px;
}
