.container {
  display: flex;
  flex-direction: column;
  padding: var(--spacing1000);
  gap: var(--spacing1000);
}

.stickyAtBottom {
  position: sticky;
  bottom: 0;
  margin: 0 calc(-1 * var(--spacing1000)) calc(-1 * var(--spacing1000));
  padding: var(--spacing1000);
}
