.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: var(--spacing0750);
  gap: var(--spacing0750);
  box-sizing: border-box;
  & .card {
    flex: 1;
    background-color: var(--gray100);
    padding: var(--spacing0500) var(--spacing0750);
    display: flex;
    & .content {
      display: flex;
      flex-direction: column;
      gap: var(--spacing0500);
      & .titleWrapper {
        display: flex;
        align-items: center;
        & .icon {
          display: block;
          font-size: 1 var(--spacing0500);
          color: var(--primaryText);
          margin: auto var(--spacing0500) auto 0;
        }
        & .title {
          color: var(--ink600);
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      & .counters p {
        margin: var(--spacing0250) 0;
      }
      & .counters p:last-of-type,
      .counters p:first-of-type {
        margin: 0;
      }
    }

    & .links {
      display: flex;
      flex-direction: column;
      align-items: end;
      flex-grow: 1;
      justify-content: space-between;
      min-width: 10%;

      & .view {
      }
      & .learnMore {
      }
    }
  }
}
